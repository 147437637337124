import axios from "axios";
import React, { Component } from "react";
import Select from "react-select";
import Resizer from "react-image-file-resizer";
import CountriesList from "./CountriesList";

class SocietyForm extends Component {
  state = {
    id: null,
    name: "",
    short_name: "",
    social_reason: "",
    nif: "",
    trade_registry: "",
    tel: "",
    tel1: "",
    tel2: "",
    email: "",
    mailbox: "",
    countryCode: "",
    countryData: "",
    region: "",
    city: "",
    devise: "",
    municipality: "",
    neighborhood: "",
    avenue: "",
    other_adress: "",
    image: [],
    tax_center: "",
    activity_sector: "",
    legal_status: "",
    liable_vat: "",
    dataImage: "",
    status: "Actif",
    error: "",
    btn: false,

    country: "",
    countries: [],
    regions: [],
    cities: [],
  };

  async datacountry() {
    try {
      var country = localStorage.getItem("country")
        ? JSON.parse(localStorage.getItem("country"))
        : {};
      this.setState({
        country: country.country,
        countryData: country.country,
        countryCode: country.countryCode,
        region: country.regionName,
        city: country.city,
        devise: country.currency,
      });

      this.datacountries();
      this.dataregions(
        country.countryCode ? country.countryCode?.toLowerCase() : null
      );
      this.datacities(country.regionName);
    } catch (error) {}
  }

  async datacountries(){
    var countries = CountriesList
    if(countries.length > 0){
        this.setState({
            countries : countries
        })
    }
}

  async dataregions(cod = null) {
    var code = cod !== null ? cod : this.state.countryCode;
    var regions = await axios.get("countries/" + code);

    if (regions.status === 200) {
      this.setState({
        regions: regions.data,
      });
      this.datacities(this.state.region);
    }
  }

  async datacities(regio = null) {
    if (this.state.countryCode) {
      var region = regio !== null ? regio : this.state.region;

      var cities = await axios.get(
        "countries/" + this.state.countryCode + "/" + region
      );
      if (cities.status === 200) {
        this.setState({
          cities: cities.data,
        });
      }
    }
  }

  async datacountryIp() {
    try {
      var ip = await axios("https://httpbin.org/ip");
      if (ip.status === 200) {
        var country = await axios.get("country/" + ip.data.origin);
        if (country.status === 200) {
          localStorage.setItem("country", JSON.stringify(country.data));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleInputFile = (e) => {
    this.setState({
      image: [],
      dataImage: e.target.files[0],
    });
    for (var i = 0; i < e.target.files.length; i++) {
      this.setState({
        loading: true,
      });
      var img = "";
      try {
        img = Resizer.imageFileResizer(
          e.target.files[i],
          1500,
          1500,
          "JPEG",
          100,
          0,
          (uri) => {
            this.state.image.push(uri);
            this.setState({ image: this.state.image });
          },
          "base64",
          1000,
          1000
        );
      } catch (err) {
        console.log(err);
      }
    }
    this.setState({
      loading: false,
      loadingForm: false,
      btn: false,
    });
  };

  storeSociety = async () => {
    this.setState({
      btn: !this.state.btn,
    });
    var user = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : false;

    let formData = new FormData();
    formData.append("file", this.state.dataImage);
    formData.append("name", this.state.name);
    formData.append("short_name", this.state.short_name);
    formData.append("social_reason", this.state.social_reason);
    formData.append("municipality", this.state.municipality);
    formData.append("neighborhood", this.state.neighborhood);
    formData.append("avenue", this.state.avenue);
    formData.append("other_adress", this.state.other_adress);
    formData.append("tax_center", this.state.tax_center);
    formData.append("activity_sector", this.state.activity_sector);
    formData.append("legal_status", this.state.legal_status);
    formData.append("liable_vat", this.state.liable_vat);
    formData.append("status", "Actif");

    if (user.id) formData.append("userId", user.id);

    if (this.state.nif) formData.append("nif", this.state.nif);

    if (this.state.trade_registry)
      formData.append("trade_registry", this.state.trade_registry);

    if (this.state.tel) formData.append("tel", this.state.tel);
    if (this.state.tel1) formData.append("tel1", this.state.tel1);
    if (this.state.tel2) formData.append("tel2", this.state.tel2);

    if (this.state.email) formData.append("email", this.state.email);

    if (this.state.mailbox) formData.append("mailbox", this.state.mailbox);

    if (this.state.country) {
      formData.append("country", this.state.countryData);
      formData.append(
        "countryCode",
        this.state.countryCode?.toLocaleLowerCase()
      );
    }

    if (this.state.region) formData.append("region", this.state.region);

    if (this.state.city) formData.append("city", this.state.city);

    var request =
      this.props.formData.id && !this.props.create
        ? "societies/" + this.props.formData.id
        : "societies";

    const response = await fetch(
      process.env.REACT_APP_APICHDUTYTRUCK + "/" + request,
      {
        method: this.props.formData.id && !this.props.create ? "PUT" : "POST",
        headers: {
          "x-access-token": user.refresh_token ? user.refresh_token : "",
        },
        body: formData,
      }
    );

    if (response.status === 200) {
      if (!this.props.formData.id) {
        var resp = await response.json();
        localStorage.setItem("society", JSON.stringify(resp.society));
        localStorage.setItem("permissions", JSON.stringify(resp.permissions));
      }
      this.setState({
        error: "saved success",
        btn: !this.state.btn,
      });
      window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/articles`);
    } else {
      var error = await response.json();
      this.setState({
        error: error.msg ? error.msg : "error when loading",
        btn: !this.state.btn,
      });
    }
  };

  edit = () => {
    this.form();
    var country = localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country"))
      : {};
    // if (!country.country) this.datacountryIp();

    if (!this.props.formData) this.datacountry();
    this.datacountries();
  };

  countries = () => {
    var country = localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country"))
      : {};
    this.setState({
      country: country.country,
      countryData: country.country,
      countryCode: country.countryCode,
      region: country.regionName,
      city: country.city,
      municipality: country.district,
    });
    this.datacountries();
  };

  form = () => {
    var country = localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country"))
      : {};
    this.setState({
      id: this.props.formData ? this.props.formData.id : null,
      name: this.props.formData ? this.props.formData.name : "",
      short_name: this.props.formData ? this.props.formData.short_name : "",
      social_reason: this.props.formData
        ? this.props.formData.social_reason
        : "",
      nif: this.props.formData ? this.props.formData.nif : "",
      trade_registry: this.props.formData
        ? this.props.formData.trade_registry
        : "",
      tel: this.props.formData ? this.props.formData.tel : "",
      tel1: this.props.formData ? this.props.formData.tel1 : "",
      tel2: this.props.formData ? this.props.formData.tel2 : "",
      email: this.props.formData ? this.props.formData.email : "",
      mailbox: this.props.formData ? this.props.formData.mailbox : "",
      country: this.props.formData
        ? this.props.formData.country !== "" &&
          this.props.formData.country !== null
          ? this.props.formData.country
          : country.country
        : country.country,
      countryData: this.props.formData
        ? this.props.formData.country !== "" &&
          this.props.formData.country !== null
          ? this.props.formData.country
          : country.country
        : country.country,
      countryCode: this.props.formData
        ? this.props.formData.countryCode !== "" &&
          this.props.formData.country !== null
          ? this.props.formData.countryCode
          : country.countryCode
        : country.countryCode,
      region: this.props.formData
        ? this.props.formData.region !== "" &&
          this.props.formData.region !== null
          ? this.props.formData.region
          : country.regionName
        : country.regionName,
      city: this.props.formData
        ? this.props.formData.city !== "" && this.props.formData.city !== null
          ? this.props.formData.city
          : country.city
        : country.city,
      municipality: this.props.formData
        ? this.props.formData.municipality !== "" &&
          this.props.formData.municipality !== null
          ? this.props.formData.municipality
          : country.district
        : country.district,
      neighborhood: this.props.formData ? this.props.formData.neighborhood : "",
      avenue: this.props.formData ? this.props.formData.avenue : "",
      other_adress: this.props.formData ? this.props.formData.other_adress : "",
      image: [],
      tax_center: this.props.formData ? this.props.formData.tax_center : "",
      activity_sector: this.props.formData
        ? this.props.formData.activity_sector
        : "",
      legal_status: this.props.formData ? this.props.formData.legal_status : "",
      liable_vat: this.props.formData ? this.props.formData.liable_vat : "1",
      error: "",
    });
  };

  handleCheck(val) {
    var permissions = JSON.parse(localStorage.getItem("permissions"))
      ? JSON.parse(localStorage.getItem("permissions"))
      : [];
    return permissions.some((item) => val === item.name);
  }

  render() {
    if (!this.state.name && this.props.formData.id) this.edit();
    if (!this.state.name && !this.state.country) this.countries();

    var optionCountries = [],
      optionRegions = [],
      optionCities = [];

    if (this.state.countries.length > 0) {
      this.state.countries
        .filter((data) => process.env.REACT_APP_COUNTRIES.split(",").includes(data.code))
        .map((country) => {
          optionCountries.push({
            value: country.code,
            label: country.name,
          });
        });
    }

    if (this.state.regions.length > 0) {
      this.state.regions.map((region) => {
        optionRegions.push({
          value: region.region,
          label: region.region,
        });
      });
    }

    if (this.state.cities.length > 0) {
      this.state.cities.map((city) => {
        optionCities.push({
          value: city.city,
          label: city.city,
        });
      });
    }

    return (
      <div className="col-12">
        <div
          className="card  shadow bg-white"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="card-header">
            <h4 className="card-titl">Information de votre agence</h4>
            <p className="text-muted mb-0">
              Plus de detail de votre agence,{" "}
              <small>Tabulation pour plus des zones de saisie</small>
            </p>
            <p>
              {this.state.error && (
                <div className="alert alert-danger text-center text-danger py-1 px-2 text-small">
                  {this.state.error}
                </div>
              )}{" "}
            </p>
          </div>
          {/*end card-heade*/}
          <div className="card-body bg-white">
            <form action="" method="post" id="custom-step">
              <nav>
                <div className="nav nav-tabs" id="nav-tab">
                  <a
                    className="nav-link active  bg-white"
                    id="step1-tab"
                    data-bs-toggle="tab"
                    href="#step1"
                  >
                    Indefification
                  </a>
                  <a
                    className="nav-link"
                    id="step2-tab"
                    data-bs-toggle="tab"
                    href="#step2"
                  >
                    Contacts
                  </a>
                  <a
                    className="nav-link"
                    id="step3-tab"
                    data-bs-toggle="tab"
                    href="#step3"
                  >
                    Adresse
                  </a>
                  <a
                    className="nav-link"
                    id="step4-tab"
                    data-bs-toggle="tab"
                    href="#step4"
                  >
                    Confirmation
                  </a>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane active  bg-white" id="step1">
                  <h4 className="card-titl mt-3 mb-1">
                    Identification de l'agence
                  </h4>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row mb-2">
                        <label
                          htmlFor="name"
                          className="col-form-label text-start"
                        >
                          Appelation <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            value={this.state.name}
                            onChange={this.handleInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/*end form-group*/}
                    </div>
                    {/*end col*/}
                    <div className="col-md-6">
                      <div className="form-group row mb-2">
                        <label
                          htmlFor="short_name"
                          className="col-form-label text-start"
                        >
                          Abréviation
                        </label>
                        <div className="">
                          <input
                            id="short_name"
                            name="short_name"
                            type="text"
                            value={this.state.short_name}
                            onChange={this.handleInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/*end form-group*/}
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row mb-2">
                        <label
                          htmlFor="social_reason"
                          className="col-form-label text-start"
                        >
                          Reseau social
                        </label>
                        <div className="">
                          <input
                            id="social_reason"
                            name="social_reason"
                            type="text"
                            value={this.state.social_reason}
                            onChange={this.handleInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/*end form-group*/}
                    </div>
                    {/*end col*/}
                    <div className="col-md-6">
                      <div className="form-group row mb-2">
                        <label
                          htmlFor="nif"
                          className="col-form-label text-start"
                        >
                          NIF
                        </label>
                        <div className="">
                          <input
                            id="nif"
                            name="nif"
                            type="text"
                            value={this.state.nif}
                            onChange={this.handleInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/*end form-group*/}
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row mb-2">
                        <label
                          htmlFor="trade_Registry"
                          className="col-form-label text-start"
                        >
                          Registre de commerce
                        </label>
                        <div className="">
                          <input
                            type="text"
                            id="trade_Registry"
                            name="trade_registry"
                            value={this.state.trade_registry}
                            onChange={this.handleInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/*end form-group*/}
                    </div>
                    {/*end col*/}

                    <div className="col-md-6">
                      <div className="form-group row mb-2">
                        <label
                          htmlFor="activity_sector"
                          className="col-form-label text-start"
                        >
                          Secteur d'activité
                        </label>
                        <div className="">
                          <input
                            type="text"
                            id="activity_sector"
                            name="activity_sector"
                            value={this.state.activity_sector}
                            onChange={this.handleInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/*end form-group*/}
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}

                  <div className="">
                    <nav>
                      <div className="nav nav-tabs float-end" id="nav-tab">
                        {/* <a className="nav-link active" id="step1-tab" data-bs-toggle="tab" href="#step1">Indefification</a> */}
                        <a
                          className="btn btn-primary"
                          id="step2-tab"
                          data-bs-toggle="tab"
                          href="#step2"
                        >
                          suivant
                        </a>
                        {/* <a className="nav-link" id="step3-tab" data-bs-toggle="tab" href="#step3">Adresse</a> */}
                        {/* <a className="nav-link" id="step4-tab" data-bs-toggle="tab" href="#step4">Confirmation</a> */}
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="tab-pane" id="step2">
                  <div className=" bg-white">
                    <h4 className="card-titl mt-3 mb-1">Contact</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="tel"
                            className="col-form-label text-start"
                          >
                            Téléphone <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              id="tel"
                              name="tel"
                              type="number"
                              value={this.state.tel}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="tel1"
                            className="col-form-label text-start"
                          >
                            Téléphone1 <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              id="tel1"
                              name="tel1"
                              type="number"
                              value={this.state.tel1}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="tel2"
                            className="col-form-label text-start"
                          >
                            Téléphone2 <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              id="tel2"
                              name="tel2"
                              type="number"
                              value={this.state.tel2}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="email"
                            className="col-form-label text-start"
                          >
                            Email
                          </label>
                          <div className="">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              value={this.state.email}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="mailbox"
                            className="col-form-label text-start"
                          >
                            Boite postale
                          </label>
                          <div className="">
                            <input
                              id="mailbox"
                              name="mailbox"
                              type="text"
                              value={this.state.mailbox}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                      <div className="col-md-4 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                          <label htmlFor="nom-id-icon">Logo par default</label>
                          <div className="position-relative mt-1">
                            <input
                              type="file"
                              accept="image/*"
                              className="form-control form-control-sm"
                              onChange={this.handleInputFile}
                            />
                            {this.state.image.map((img, index) => {
                              return (
                                <img
                                  src={img}
                                  alt={img}
                                  key={index}
                                  className="rounded-3 img-fluid"
                                  width={"100"}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end row*/}

                    <div>
                      <nav>
                        <div className="nav nav-tabs float-end" id="nav-tab">
                          <a
                            className="btn btn-secondary mx-2"
                            id="step1-tab"
                            data-bs-toggle="tab"
                            href="#step1"
                          >
                            précédent
                          </a>
                          {/* <a className="btn btn-primary" id="step2-tab" data-bs-toggle="tab" href="#step2">Contacts</a> */}
                          <a
                            className="btn btn-primary"
                            id="step3-tab"
                            data-bs-toggle="tab"
                            href="#step3"
                          >
                            suivant
                          </a>
                          {/* <a className="nav-link" id="step4-tab" data-bs-toggle="tab" href="#step4">Confirmation</a> */}
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="step3">
                  <div className=" bg-white">
                    <h4 className="card-titl mt-3 mb-1">Adresse</h4>
                    <div className="row">
                      <div className="col-md-3  mt-2 mt-2">
                        <div className="form-group has-icon-left">
                          <label
                            htmlFor="country"
                            className="col-form-label text-start"
                          >
                            Pays <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative mt-1">
                            <Select
                              className="form-control form-control-sm p-0"
                              value={optionCountries.filter(
                                (option) =>
                                  this.state.countryCode &&
                                  option.value?.toLowerCase() ==
                                    this.state.countryCode?.toLowerCase()
                              )}
                              defaultValue={this.state.country}
                              placeholder={this.state.country}
                              onChange={(select) => {
                                this.setState({
                                  country: select.value,
                                  countryCode: select.value,
                                  countryData: select.label,
                                  regions: [],
                                  cities: [],
                                  region: "",
                                  city: "",
                                });

                                if (select.value) {
                                  this.dataregions(select.value);
                                }
                              }}
                              options={optionCountries}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3  mt-2 mt-2">
                        <div className="form-group has-icon-left">
                          <label
                            htmlFor="country"
                            className="col-form-label text-start"
                          >
                            Region <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative mt-1">
                            <Select
                              className="form-control form-control-sm p-0"
                              value={optionRegions.filter(
                                (option) => option.value == this.state.region
                              )}
                              defaultValue={this.state.region}
                              placeholder={this.state.region}
                              onChange={(select) => {
                                this.setState({
                                  region: select.value,
                                  cities: [],
                                  city: "",
                                });

                                if (select.value) {
                                  this.datacities(select.value);
                                }
                              }}
                              options={optionRegions}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3  mt-2 mt-2">
                        <div className="form-group has-icon-left">
                          <label
                            htmlFor="country"
                            className="col-form-label text-start"
                          >
                            Ville <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative mt-1">
                            <Select
                              className="form-control form-control-sm p-0"
                              value={optionCities.filter(
                                (option) => option.value == this.state.city
                              )}
                              defaultValue={this.state.city}
                              placeholder={this.state.city}
                              onChange={(select) => {
                                this.setState({
                                  city: select.value,
                                });
                              }}
                              options={optionCities}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3  mt-2 mt-2">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="municipality"
                            className="col-form-label text-start"
                          >
                            Commune
                          </label>
                          <div className="">
                            <input
                              id="municipality"
                              name="municipality"
                              type="text"
                              value={this.state.municipality}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="neighborhood"
                            className="col-form-label text-start"
                          >
                            Quartier
                          </label>
                          <div className="">
                            <input
                              id="neighborhood"
                              name="neighborhood"
                              type="text"
                              value={this.state.neighborhood}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}

                      <div className="col-md-3">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="avenue"
                            className="col-form-label text-start"
                          >
                            Avenue
                          </label>
                          <div className="">
                            <input
                              id="avenue"
                              name="avenue"
                              type="text"
                              value={this.state.avenue}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="other_adress"
                            className="col-form-label text-start"
                          >
                            N° et autre adresse
                          </label>
                          <div className="">
                            <input
                              id="other_adress"
                              name="other_adress"
                              type="text"
                              value={this.state.other_adress}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}
                    <div>
                      <nav>
                        <div className="nav nav-tabs float-end" id="nav-tab">
                          {/* <a className="btn btn-secondary mx-2" id="step1-tab" data-bs-toggle="tab" href="#step1">Indefification</a> */}
                          <a
                            className="btn btn-secondary mx-2"
                            id="step2-tab"
                            data-bs-toggle="tab"
                            href="#step2"
                          >
                            précédent
                          </a>
                          {/* <a className="btn btn-primary" id="step3-tab" data-bs-toggle="tab" href="#step3">Adresse</a> */}
                          <a
                            className="btn btn-primary"
                            id="step4-tab"
                            data-bs-toggle="tab"
                            href="#step4"
                          >
                            suivant
                          </a>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="step4">
                  <div className=" bg-white">
                    <h4 className="card-titl mt-3">Confirmation</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="tax_center"
                            className="col-form-label text-start"
                          >
                            Centre fiscal
                          </label>
                          <div className="">
                            <input
                              id="tax_center"
                              name="tax_center"
                              type="text"
                              value={this.state.tax_center}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="legal_status"
                            className="col-form-label text-start"
                          >
                            Forme juridique
                          </label>
                          <div className="">
                            <input
                              id="legal_status"
                              name="legal_status"
                              type="text"
                              value={this.state.legal_status}
                              onChange={this.handleInput}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row mb-2">
                          <label
                            htmlFor="tax_center"
                            className="col-form-label text-start"
                          >
                            Assijetti à la TVA
                          </label>
                          <div className="">
                            <select
                              id="liable_vat"
                              name="liable_vat"
                              value={this.state.liable_vat}
                              onChange={this.handleInput}
                              className="form-select"
                            >
                              <option value="1">Oui</option>
                              <option value="0">Non</option>
                            </select>
                          </div>
                        </div>
                        {/*end form-group*/}
                      </div>
                      {/*end col*/}
                      <div className="col-md-6">
                        <label
                          htmlFor="tax_center"
                          className="col-form-label text-start"
                        >
                          Accord
                        </label>

                        <div className="form-check form-switch form-switch-primary">
                          <input
                            className="form-check-input"
                            required={true}
                            type="checkbox"
                            checked={true}
                            id="customSwitchSuccess"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchSuccess"
                          >
                            J'accepte les termes et conditions CH DUTY TRUCK.
                          </label>
                        </div>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}

                    <div>
                      <div className="text-center text-danger text-small small">
                        {this.state.error}
                      </div>
                      <nav>
                        <div className="nav nav-tabs float-end" id="nav-tab">
                          {/* <a className="btn btn-secondary mx-2" id="step1-tab" data-bs-toggle="tab" href="#step1">Indefification</a> */}
                          {/* <a className="btn btn-secondary mx-2" id="step2-tab" data-bs-toggle="tab" href="#step2">Contacts</a> */}
                          <a
                            className="btn btn-secondary mx-2"
                            id="step3-tab"
                            data-bs-toggle="tab"
                            href="#step3"
                          >
                            précédent
                          </a>
                          {/* {
                                                        this.handleCheck("configurer societe") == true && ( */}
                          <button
                            type="button"
                            onClick={() => this.storeSociety()}
                            id="step4Finish"
                            disabled={this.state.btn}
                            className="btn btn-primary float-end"
                          >
                            {this.props.create
                              ? "Ajouter nouveau"
                              : "Enregister"}
                          </button>
                          {/* )
                                                    }                                     */}
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/*end form*/}
          </div>
          {/*end card-body*/}
        </div>
        {/*end card*/}
      </div>
    );
  }
}
export default SocietyForm;
