import React, {Component} from "react";
import ImageGallery from "react-image-gallery";

class   ItemInfoStart extends Component{
 
    render(){
       
        let data = [];
        let list = 
        this.props.pub.images.map( (img) => {
            data.push({
                original : img.image,
            })
            return <li className="list-inline-item me-0"><i className="ti ti-star text-orangered font-16"></i></li>
        })
        return(
            <div className="">
                <div className="rounded rounded-4">
                    <div className="card-body">
                        <div className="row border-end-0 border-bottom-0">
                        
                            <div className={ (this.props.count % 2 == 0 || this.props.count == 0) ? this.props.col_5 + " align-self-center overflow-hidden" : this.props.col_5 + " align-self-center overflow-hidden order-md-2"}>
                                <div style={{ width : 307 +"px", height : 250 + "px" }}>
                                    { data.length > 0 ? <ImageGallery items={data} autoPlay={(this.props.count % 2 == 0 || this.props.count == 0) ? true : false} showNav={ false} /> : null}
                                </div>    
                            </div>{/*end col*/}
                            <div className={ this.props.col_7 + " align-self-center overflow-hidden text-muted"}>
                                <div className="">
                                    <h1 className={ (this.props.count % 2 == 0 || this.props.count == 0) ? " page-title h5 font-monospace fw-bold m-2 text-center" : " page-title h5 font-monospace fw-bold m-2 text-center "}><span className="text-orangered">{ this.props.col_7 == "" ? this.props.count + 1 : "" }.</span> { this.props.pub.title }</h1>
                                    <p className="font-monospace font-13 text-dark">{ this.props.pub.description}</p>
                                    <div className="text-end">
                                        <ul className="list-inline m-4">
                                            { list}
                                            <li className="list-inline-item"><i className="ti ti-star-half text-orangered font-16"></i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>{/*end col*/}                                            
                        </div>{/*end row*/}
                    </div>{/*end card-body*/}
                </div>
                <br />
            </div>
        )

    }
}
export default ItemInfoStart;
