import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Article from "./pages/Article";
 
import Dashboard from './pages/Dashboard';
import Society from "./pages/Society";
import Login from "./partials/Login";
import Profile from "./pages/Profile";
import Register from "./partials/Register";
import Staff from "./pages/Staff";
import Chat from "./pages/Chat";
import Notfound from "./partials/Notfound";
import Profil from "./pages/Profil";
import Category from "./pages/Category";
import ArticleDetail from "./pages/ArticleDetail";
import Myquotationstatus from "./pages/Myquotationstatus";
import Payment from "./pages/Payment";
import Quotation from "./pages/quotation";
import PaymentVerify from "./pages/PaymentVerify";
import PaymentVerifyList from "./pages/PaymentVerifyList";
import Repport from "./pages/Repport";
import Categoryarticle from "./pages/Categoryarticle";
import Search from "./pages/Search";
import Mark from "./pages/Mark";
import Appartement from "./pages/Appartement";
import Pub from "./pages/Pub";
import Customer from "./pages/Customer";
import StartArticle from "./pages/StartArticle";
import InfoStart from "./pages/InfoStart";
import Modele from "./pages/Modele";
import About from "./pages/About";
 
function RouterPage(){

    return(
        <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route  path="/myquotations/:status/:id" component={ Payment } />
            <Route  path="/myquotations/:status" component={ Myquotationstatus } />
            <Route  path="/quotations/article/:id" component={ PaymentVerifyList } />
            <Route  path="/quotations/:status/:id" component={ PaymentVerify } />
            <Route  path="/quotations/:status" component={ Quotation } />
            <Route  path="/societies" component={ Society } />
            <Route  path="/items/category/:id" component={ Categoryarticle } />
            <Route  path="/search/:category/:region/:city/:adress" component={ Search } />
            <Route  path="/search/:category/:region/:city" component={ Search } />
            <Route  path="/search/:category/:region" component={ Search } />
            <Route  path="/searchs/:category/:search" component={ Search } />
            <Route  path="/searchs/:category" component={ Search } />
            <Route  path="/cities/:cit" component={ Search } />
            <Route  path="/appartements/:appartments" component={ Appartement } />
            <Route  path="/items/recommandations" component={ Appartement } />
            <Route  path="/items/:id" component={ ArticleDetail } />
            <Route  path="/articles" component={ Article } />
            <Route  path="/infostart" component={ InfoStart } />
            <Route  path="/start" component={ StartArticle } />
            <Route  path="/categories" component={ Category } />
            <Route  path="/marks" component={ Mark } />
            <Route  path="/models" component={ Modele } />
            <Route  path="/profile" component={ Profil } />
            <Route  path="/profiles" component={ Profile } />
            <Route  path="/repports" component={ Repport } />
            <Route  path="/staffs" component={ Staff } />
            <Route  path="/customers" component={ Customer } />
            <Route  path="/pubs" component={ Pub } />
            <Route  path="/chats/:id" component={ Chat } />
            <Route  path="/chats" component={ Chat } />
            <Route  path="/login" component={ Login } />
            <Route  path="/register" component={ Register } />
            <Route  path="/about" component={ About } />
            <Route component={Notfound} />
        </Switch>
    )
}

export default RouterPage;