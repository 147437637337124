import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Resizer from "react-image-file-resizer";
import Select from 'react-select';
import ReactLoading from "react-loading";
import CountriesList from "./CountriesList";
import CategoryForm from "./CategoryForm";
import ModeleForm from "./ModeleForm";
import MarkForm from "./MarkForm";
import { toast } from "react-toastify";

class   ArticleForm extends Component{

    state = {
        id : null,
        ref_user : null,
        societyId : null,
        markId : null,
        mark : "",
        modeleId : null,
        userprofileId : null,
        categoryId : null,
        ref_user_update : null,
        category : "",
        image : [],
        categories : [],
        country : "",
        countryData : "",
        countryCode : "",
        region : "",
        city : "",
        adress : "",
        kilometre : "",
        quantity : "",
        price : "",
        description : "",
        boite_vitesse : "",
        couleur_interieur : null,
        couleur_exterieur : null,
        isValid : null,
        specification : null,
        disponible : null,
        taille_moteur : null,
        default : "",
        dataImage : [],

        error : "",
        title : "",
        loading : false,
        loadingForm : false,
        countries : [],
        regions : [],
        marks : [],
        models : [],
        cities : [],
        images : [],
        btn : false,
        isCategory : false,
        isMark : false,
        isModele : false,
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleInputFile = (e) => {

        var imgTab = [];

        this.setState({
            image : [],
            dataImage : e.target.files[0],
            btn : false
        })
        for(var i = 0 ; i < e.target.files.length; i++)
        {
            imgTab.push(e.target.files[i]);

             
            this.setState({
                loading : true,
                dataImage : imgTab
            })
            var img = "";
            try {
            img =  Resizer.imageFileResizer(
                  e.target.files[i],
                  1500,
                  1500,
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                        this.state.image.push(uri);
                    this.setState({ image: this.state.image });
                  },
                  "base64",
                  1000,
                  1000
                );
            } catch (err) {
                console.log(err);
            }
        }
        this.setState({
            loading : false,
            loadingForm : false
        })
    }

    async datacountry(){
        try{
            var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
            this.setState({
                country : country.countryCode,
                countryData : country.country,
                countryCode : country.countryCode,
                region : country.regionName,
                city : country.city,
                devise : country.currency,
            })

            this.dataregions(country.countryCode?.toLowerCase());
            this.datacities(country.regionName);

        }catch(error){
            console.log(error);
        }
    }

 

    async images(id = null){
        if(id){
            var images = await axios.get("images/" + id);
            if(images.status === 200){
                this.setState({
                    images : images.data
                })
            }
        }
    }
    
    deleteImage = (id) =>{
        axios.delete("images/" + id).then((response) => {
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.images(this.state.id);
        }).catch((error)=>{})
    }

    async datacountries(){
        var countries = CountriesList
        if(countries.length > 0){
            this.setState({
                countries : countries
            })
        }
    }

    
    async dataregions(cod = null){
        var code = cod !== null ? cod : this.state.countryCode;
        var regions = await axios.get("countries/" +  code);
    
        if(regions.status === 200){
            this.setState({
                regions : regions.data
            })
        }
    }
    
    async datacities(regio = null){
        var code = this.state.countryCode;
        var region = regio !== null ? regio : this.state.region;
        var cities = await axios.get("countries/" + code +"/"+ region);
        if(cities.status === 200){
            this.setState({
                cities : cities.data
            })
        }
    }

    formData_ = () => {
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        
        let formData = new FormData()
        
        if(this.state.dataImage && this.state.dataImage.length > 0){
            for(var i = 0 ; i < this.state.dataImage.length; i++)
            {
                if(i < 5)
                    formData.append("files", this.state.dataImage[i]);
            }
        }

        formData.append('kilometre', this.state.kilometre)
        formData.append('quantity', this.state.quantity > 0 ? this.state.quantity : 1)
        formData.append('price', this.state.price > 0 ? this.state.price : 0)
        formData.append('currency', "$")// this.state.devise)
        formData.append('default', this.state.default )
        formData.append('adress', this.state.adress)
        formData.append('description', this.state.description)
        formData.append('boite_vitesse', this.state.boite_vitesse)
       
        if(this.state.disponible > 0)
            formData.append('disponible', this.state.disponible)
        

        formData.append('taille_moteur', this.state.taille_moteur)
        formData.append('couleur_interieur', this.state.couleur_interieur)
        formData.append('couleur_exterieur', this.state.couleur_exterieur)

        if(this.state.isValid > 0)
            formData.append('isValid', this.state.isValid)

        formData.append('specification', this.state.specification)

        if(this.state.modeleId)
            formData.append('modeleId', this.state.modeleId)

        if(this.state.markId)
            formData.append('markId', this.state.markId)

        if(this.state.countryCode)
            formData.append('countryCode', this.state.countryCode)

        if(this.state.region)
            formData.append('region', this.state.region)

        if(this.state.city)
            formData.append('city', this.state.city)

        if(this.state.categoryId)
            formData.append('categoryId', this.state.categoryId)

      
        if(society.userprofile && society.userprofile.id)
            formData.append('userprofileId', this.state.userprofileId ? this.state.userprofileId :  society.userprofile.id)

            return formData
    }

    storeArtcleDiplicate = async () => {
        this.setState({
            btn : !this.state.btn
        })
        let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        let society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        let society_id = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : false : false : false;
        let formData = this.formData_()
        
        const response = await fetch(process.env.REACT_APP_APICHDUTYTRUCK +'/articles', {
            method: "POST",
            headers : { "x-access-token" : user.refresh_token ? user.refresh_token : "" },
            body: formData 
        })
        
        if (response.status === 200)
        {
            this.setState({
                error : "saved success",
                btn : !this.state.btn
            })
            var article = await response.json();
            toast(article?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(article.data?.id);
        }
        else{
            var error = await response.json();
            this.setState({
                error : error.msg ? error.msg :  "error when loading",
                btn : !this.state.btn
            })
            toast(error.msg ? error.msg :  "error when loading", { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }
    }

    storeArtcle = async () => {
        this.setState({
            btn : !this.state.btn
        })
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        var society_id = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : false : false : false;
        let formData = this.formData_()
        
        var request = this.state.id ? 'articles/' + this.state.id  : 'articles' ;
        
        const response = await fetch(process.env.REACT_APP_APICHDUTYTRUCK + '/' + request, {
            method: this.state.id ? "PUT" : "POST",
            headers : { "x-access-token" : user.refresh_token ? user.refresh_token : "" },
            body: formData 
        })
        
        if (response.status === 200)
        {
            this.setState({
                error : "saved success",
                btn : !this.state.btn,
                dataImage : [],
            })
            var article = await response.json();
            toast(article?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(article.data?.id);
        }
        else{
            var error = await response.json();
            
            this.setState({
                error : error.msg ? error.msg :  "error when loading",
                btn : !this.state.btn
            })
            toast(error.msg ? error.msg :  "error when loading", { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }
        
    }


    async categories(){
        var categories = await axios.get('categoriesparents/-1');
        if(categories.status == 200)
        {
            this.setState({ categories : categories.data});
        }
    }

    async marks(){
        var marks = await axios.get('marks');
        if(marks.status == 200)
        {
            this.setState({ marks : marks.data});
        }
    }

    edit=()=>  {
        this.form();
        this.categories();
        if(!this.props.formData)
            this.datacountry();
        this.datacountries();
        this.marks();
    }

    delete = (article) =>{
        this.setState({
            btn : !this.state.btn
        })
        axios.delete("articles/" + article).then((response) => {
            this.setState({
                btn : !this.state.btn
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal();
        }).catch((error)=>{
            toast(error.response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    form=()=>{
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
         
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            ref_user: this.props.formData ? this.props.formData.ref_user : "",
            ref_user_update: this.props.formData ? this.props.formData.ref_user_update : "",
            categoryId: this.props.formData ? this.props.formData.category ? this.props.formData.category.id : this.props.formData.categoryId : "",
            markId: this.props.formData ? this.props.formData.mark ? this.props.formData.mark.id : this.props.formData.markId : "",
            modeleId: this.props.formData ? this.props.formData.modele ? this.props.formData.modele.id : this.props.formData.modeleId : "",
            userprofileId: this.props.formData ? this.props.formData.userprofile ? this.props.formData.userprofile.id : "" : "",
            societyId: this.props.formData ? this.props.formData.society ? this.props.formData.society.id : "" : "",
            kilometre: this.props.formData ? this.props.formData.kilometre : "",
            couleur_interieur: this.props.formData ? this.props.formData.couleur_interieur : "",
            quantity: this.props.formData ? this.props.formData.quantity : 1,
            price: this.props.formData ? this.props.formData.price : "",
            boite_vitesse: this.props.formData ? this.props.formData.boite_vitesse : null,
            isValid: this.props.formData ? this.props.formData.isValid : null,
            specification: this.props.formData ? this.props.formData.specification : null,
            taille_moteur: this.props.formData ? this.props.formData.taille_moteur : null,
            disponible: this.props.formData ? this.props.formData.disponible : null,
            couleur_exterieur: this.props.formData ? this.props.formData.couleur_exterieur : null,
            devise: this.props.formData ? this.props.formData.prices?.currency : country.currency,
            adress: this.props.formData ? this.props.formData.adress : "",
            countryData : this.props.formData ? this.props.formData.country : country.country,
            country : this.props.formData ? this.props.formData.countryCode : country.countryCode,
            countryCode : this.props.formData ? this.props.formData.countryCode : country.countryCode,
            region : this.props.formData ? this.props.formData.region : country.regionName,
            city : this.props.formData ? this.props.formData.city : country.city,
            description: this.props.formData ? this.props.formData.description : "",
            isValid: this.props.formData ? this.props.formData.isValid : 0,
            title : this.props.formData ? "Modifier l'article" : "Ajouter l'article",
            image : [],
            error : "",
            images : []
        })
        if(this.props.formData && this.props.formData.id)
            this.images(this.props.formData.id);
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    showModalCategory = (category) => {
        this.setState({
            isCategory : !this.state.isCategory,
        })
        if(category){
            this.setState({ categoryId : category})
            this.categories()
        }
    }

    showModalMark = (mark) => {
        this.setState({
            isMark : !this.state.isMark,
        })
        if(mark){
            this.setState({ markId : mark})
            this.marks()
        }
    }

    showModalModele = (modele) => {
        this.setState({
            isModele : !this.state.isModele,
        })
        if(modele){
            this.setState({ modeleId : modele})
            this.marks()
        }
    }


    render(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        var society_id = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : false : false : false;

        var optionCategories = [{ value : null, label : 'Nouvelle categorie'}], optionMarks = [{ value : null, label : 'Nouvelle marque'}], optionModels = [{ value : null, label : 'Nouveau model'}],   optionCountries = [], optionRegions = [], optionCities = [];
        this.state.categories.map(category => {
            optionCategories.push({
                value : category.id, 
                label : category.name,
                parentId : category.parentId, 
            })
        })

        this.state.marks.map(mark => {
            optionMarks.push({
                value : mark.id, 
                label : mark.name,
                models : mark.modeles, 
            })
        })

        this.state.models?.map(model => {
            optionModels.push({
                value : model.id, 
                label : model.name
            })
        })
        

        if(this.state.countries.length > 0)
        {
            this.state.countries.map(country => {
                optionCountries.push({
                    value : country.code, 
                    label : country.name,
                })
            })
        }

        if(this.state.regions.length > 0)
        {
            this.state.regions.map(region => {
                optionRegions.push({
                    value : region.region, 
                    label : region.region,
                })
            })
        }
        if(this.state.cities.length > 0)
        {
            this.state.cities.map(city => {
                optionCities.push({
                    value : city.city, 
                    label : city.city,
                })
            })
        }
 

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title} ({ this.state.countryData})</Modal.Title>
                </Modal.Header>
                    {/* <form encType="multipart/form-data"> */}
                <Modal.Body className="row">
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        
                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Catégorie<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <Select
                                        className="form-control form-control-sm p-0"
                                        value = {
                                            optionCategories.filter(option => 
                                            option.value == this.state.categoryId)
                                        }
                                        defaultValue={this.state.categoryId}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    categoryId : select.value,
                                                    category : select.label,
                                                    isCategory : select.value == null ? true : false
                                                });
                                            }
                                        }
                                        options={optionCategories}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Marque<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <Select
                                        className="form-control form-control-sm p-0"
                                        value = {
                                            optionMarks.filter(option => 
                                            option.value == this.state.markId)
                                        }
                                        defaultValue={this.state.markId}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    markId : select.value,
                                                    mark : select.label,
                                                    models : select.models,
                                                    isMark : select.value == null ? true : false
                                                });
                                            }
                                        }
                                        options={optionMarks}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Model { this.state.mark }</label>
                                <div className="position-relative mt-1">
                                    <Select
                                        className="form-control form-control-sm p-0"
                                        value = {
                                            optionModels.filter(option => 
                                            option.value == this.state.modeleId)
                                        }
                                        defaultValue={this.state.modeleId}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    modeleId : select.value,
                                                    isModele : select.value == null ? true : false
                                                });
                                            }
                                        }
                                        options={optionModels}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className={  "col-md-4 mt-2"}>
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Kilometre</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="kilometre"  value={this.state.kilometre} id="nom-id-icon" />
                                </div>
                            </div>
                        </div> 

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Prix<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="price"  value={this.state.price} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Boite vitesse</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="boite_vitesse"  value={this.state.boite_vitesse} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Taille moteur</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="taille_moteur"  value={this.state.taille_moteur} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Couleur interieur</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="couleur_interieur"  value={this.state.couleur_interieur} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">couleur exterieur</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="couleur_exterieur"  value={this.state.couleur_exterieur} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mt-2 d-none">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Valide(1 ou 0)</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="isValid"  value={this.state.isValid} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mt-2 d-none">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Disponible(1 ou 0)</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="disponible"  value={this.state.disponible} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>
  
                        <div className={ "col-md-4 mt-2"}>
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Dans quel pays </label>
                                <div className="position-relative mt-1">
                                    <Select
                                        className="form-control form-control-sm p-0"
                                        value = {
                                            optionCountries.filter(option => 
                                            option.value?.toLowerCase() == this.state.country?.toLowerCase())
                                        }
                                        defaultValue={this.state.country}
                                        placeholder={this.state.countryData}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    country : select.value,
                                                    countryCode : select.value,
                                                    countryData : select.label,
                                                    regions : [],
                                                    cities : [],
                                                    region : "",
                                                    city : "",
                                                 });

                                                if(select.value){
                                                    this.dataregions(select.value);
                                                }
                                            }
                                        }
                                        options={optionCountries}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-4 mt-2"}>
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Region </label>
                                <div className="position-relative mt-1">
                                    
                                    <Select
                                        className="form-control form-control-sm p-0"
                                        value = {
                                            optionRegions.filter(option => 
                                            option.value == this.state.region)
                                        }
                                        defaultValue={this.state.region}
                                        placeholder={this.state.region}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    region : select.value,
                                                    cities : [],
                                                    city : "",
                                                 });

                                                 if(select.value){
                                                    this.datacities(select.value);
                                                }
                                            }
                                        }
                                        options={optionRegions}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-4 mt-2"}>
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Ville </label>
                                <div className="position-relative mt-1">
                                    <Select
                                        className="form-control form-control-sm p-0"
                                        value = {
                                            optionCities.filter(option => 
                                            option.value == this.state.city)
                                        }
                                        defaultValue={this.state.city}
                                        placeholder={this.state.city}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    city : select.value,
                                                 });
                                            }
                                        }
                                        options={optionCities}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={  "col-md-12 mt-2"}>
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Autre adresse</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="adress"  value={this.state.adress} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>
                        

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Description</label>
                                <div className="position-relative mt-1">
                                    <textarea className="form-control form-control-sm" name={"description"} onChange={this.handleInput} value={ this.state.description}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Specification</label>
                                <div className="position-relative mt-1">
                                    <textarea className="form-control form-control-sm" name={"specification"} onChange={this.handleInput} value={ this.state.specification}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2 d-none">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Texte par defaut</label>
                                <div className="position-relative mt-1">
                                    <textarea className="form-control form-control-sm" name={"default"} onChange={this.handleInput} value={ this.state.default}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Image par default</label>
                                <div className="position-relative mt-1">
                                <input type="file" accept="image/*" className="form-control form-control-sm" multiple onChange={this.handleInputFile} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                        {
                            this.state.images.map((img, index) => {
                                return(<>
                                    <img src={img.image} alt={ img.image} key={index} className="rounded-3 img-fluid me-1" width={"70"} />
                                    <span type="button" onClick={ () => this.deleteImage(img.id)} className="ti ti-trash-x font-16 text-danger border border-1 border-danger p-1 rounded rounded-circle"></span>
                                </>
                                )
                            })
                        }
                            {
                                this.state.image.map((img, index) => {
                                    return(
                                        <img src={img} alt={img} key={index} className="rounded-3 img-fluid me-1" width={"70"} />
                                    )
                                })
                            }
                        </div>
                </Modal.Body>
                <Modal.Footer>
                <div className="text-center text-danger text-small small">{ this.state.error }</div>
                {
                    ( (this.handleCheck("supprimer article") === true && this.props.formData && user.id == this.props.formData.user.id || this.handleCheck("admin") === true ) && this.state.id) &&(
                        <button onClick={() => this.delete(this.state.id)} disabled={ this.state.btn } type="button" className="btn my-1 btn-outline-danger"><i className="ti ti-check"></i> Supprimer</button>
                    )
                }
                {
                    ( (this.handleCheck("dipliquer article") === true && this.props.formData && user.id == this.props.formData.user.id || this.handleCheck("admin") === true ) && this.state.id) &&(
                        <button onClick={() => this.storeArtcleDiplicate()} disabled={ this.state.btn } type="button" className="btn my-1 btn-outline-warning mx-2"><i className="ti ti-check"></i> Dipliqué </button>
                    )
                }    <button onClick={ () => this.props.showModal()}  type="button" className="btn my-1 btn-outline-secondary mx-2"><i className="ti ti-corner-down-left"></i> Fermer</button>
                {
                    ( (this.handleCheck("ajouter article") === true || this.handleCheck("modifier article") === true || this.handleCheck("admin") === true )) &&(
                    <button onClick={() => this.storeArtcle()} disabled={ this.state.btn } type="button" className="btn my-1 btn-outline-primary"> Sauvegarder { !this.state.btn ? <i className="ti ti-check"></i> : <ReactLoading type="spokes" color="blue" className="float-end" height={15} width={15} />}</button>
                )}
                </Modal.Footer>
                    {/* </form> */}
            </Modal>


            <CategoryForm
                isModal={this.state.isCategory}
                formData={null}
                showModal={this.showModalCategory}
            />

            <MarkForm
                isModal={this.state.isMark}
                formData={null}
                showModal={this.showModalMark}
            />

            <ModeleForm
                isModal={this.state.isModele}
                formData={null}
                showModal={this.showModalModele}
            />

        </div>
        )
    }
}
export default ArticleForm;
