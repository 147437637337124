import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";

class   CustomerForm extends Component{

    state = {
        id : null,
        profileId : null,
        name : "",
        email : "",
        tel : "",
        password : "",
        adress : "",
        avatar : "",
        error : "",

        title : "",
        profiles : [],
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    storeUser = (e) =>{
        e.preventDefault();

        this.setState({
            btn : !this.state.btn
        })

        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
        
        var data = {
                profileId : 1,
                name : this.state.name,
                email : this.state.email ? this.state.email : null,
                tel : this.state.tel ? this.state.tel : null,
                type : "Physique",
                password : "123",
                country : country.country,
                countryData : country.country,
                countryCode : country.countryCode?.toLowerCase(),
                region : country.regionName,
                city : country.city,
                adress : this.state.adress,
        }

        var request = this.state.id ? axios.put('users/'+this.state.id, data) : axios.post('users', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn
            })
            this.props.showModal()
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg,
                btn : !this.state.btn
            })
        })
    }

    edit=()=>  {
        this.form()
    }

    form=()=>{

        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            name: this.props.formData ? this.props.formData.name : "",
            email: this.props.formData ? this.props.formData.email : "",
            tel: this.props.formData ? this.props.formData.tel : "",
            type: this.props.formData ? this.props.formData.type : "",
            adress: this.props.formData ? this.props.formData.adress : "",
            title : this.props.formData ? "Modifier l'utilisateur" : "Ajouter l'utilisateur",
            error : "",
        })
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title}</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.storeUser}>
                <Modal.Body className="row">
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Nom</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Email</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="email"  value={this.state.email} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Téléphone</label>
                            <div className="position-relative mt-1">
                                <input type="number" className="form-control"  name="tel"  placeholder="" onChange={this.handleInput} value={this.state.tel} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Adresse</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="adress"  value={this.state.adress} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={ () => this.props.showModal() } type="button" className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                    <button type="submit" className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="ti ti-check"></i> Sauvegarder</button>
                </Modal.Footer>
                </form>
            </Modal>


        </div>
        )
    }
}
export default CustomerForm;
