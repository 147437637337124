import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import LogoutGoogle from "./LogoutGoogle";
import Lang from "./Lang";


class Navbar extends Component{
    
    state = {
        categories : [],
        cat : process.env.REACT_APP_CATEGORY_ID,
        search : ""
    }

    componentDidMount(){
        this.categories();
    }

    logout = (e) => {
        axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
        window.localStorage.clear();
        window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/`)
    }
    
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async categories(){
        var categories = await axios.get("categories");
        if(categories.status === 200)
        {
            this.setState({
                categories : categories.data
            })
        }
    }
 
    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }
    
    render(){
    const { t } = this.props;
      var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
      var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
      var society_name = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.name : null : null : null;
      var society_profile = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.name : null : null;

      return(
          <div className="topbar">            
          {/* Navbar */}
          <nav className="navbar-custom" style={{  backgroundColor : "#FCFCFC" }} id="navbar-custom">    
              <div className="text-center bg-orangered">
                <a href="tel:008615587926040" className="mt-2 text-white text-center text-orangered-hover"><i className="text-white text-orangered-hover ti ti-phone"></i> 0086 155 8792 6040</a>
                <a href="mailto:chdutytrucksupplies@gmail.com" className="mt-2 text-white text-center text-orangered-hover"> <i className="text-white text-orangered-hover ti ti-mail"></i>chdutytrucksupplies@gmail.com</a>
              </div>
              <ul className="list-unstyled topbar-nav float-end mb-0">
                {
                    (society_name !== null ) && (
                        <li className="dropdown notification-list">
                            <a className="nav-link dropdown-toggle arrow-none nav-icon m-0 me-1" data-bs-toggle="dropdown" href="#" role="button"
                                title="Activity"
                                aria-haspopup="false" aria-expanded="false">
                                <i className="text-dark text-orangered-hover ti ti-activity"></i>
                                <span className="alert-badge"></span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-lg pt-0">
                    

                                <h6 className="dropdown-item-text font-15 m-0 py-3 border-bottom d-flex justify-content-between align-items-center">
                                   <Link to={ (this.handleCheck("configurer societe") == true) ? "/societies/" : "#"} className="text-decoration-none text-orangered">{ society_name}</Link> <Link to={(this.handleCheck("configurer societe") == true) ? "/societies/" : "#"} className="text-decoration-none"><span className="badge bg-soft-primary badge-pill p-"> <i className="text-dark text-orangered-hover ti ti-settings"></i> </span></Link>
                                </h6> 
                                <div className="notification-menu" data-simplebar>
                                    
                                    {
                                        (this.handleCheck("afficher article") == true) && (
                                            <Link to="/articles" className="dropdown-item py-3">
                                                <div className="media">
                                                    <div className="avatar-md bg-soft-primary">
                                                        <i className="text-dark text-orangered-hover ti ti-briefcase"></i>
                                                    </div>
                                                    <div className="media-body align-self-center ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark text-orangered-hover">{ t("navbar.mes_articles")}</h6>
                                                        <small className="text-muted mb-0">{ t("navbar.articles")} { society_name}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }

                                    {
                                        (this.handleCheck("afficher reservation") == true) && (
                                            <Link to="/quotations/loading" className="dropdown-item py-3 d-none">
                                                <div className="media">
                                                    <div className="avatar-md bg-soft-primary">
                                                        <i className="text-dark text-orangered-hover ti ti-chart-arcs"></i>
                                                    </div>
                                                    <div className="media-body align-self-center ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark text-orangered-hover">{ t("navbar.reservations")}</h6>
                                                        <small className="text-muted mb-0">{ t("navbar.reservations_description")}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }

                                    {
                                        (this.handleCheck("ajouter categorie") == true) && (
                                            <Link to="/categories" className="dropdown-item py-3">
                                                <div className="media">
                                                    <div className="avatar-md bg-soft-primary">
                                                        <i className="text-dark text-orangered-hover ti ti-folder"></i>
                                                    </div>
                                                    <div className="media-body align-self-center ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark text-orangered-hover">{ t("navbar.category")}</h6>
                                                        <small className="text-muted mb-0">{ t("navbar.category_description")}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }

                                    {
                                        (this.handleCheck("ajouter marque") == true) && (
                                            <Link to="/marks" className="dropdown-item py-3">
                                                <div className="media">
                                                    <div className="avatar-md bg-soft-primary">
                                                        <i className="text-dark text-orangered-hover ti ti-drone"></i>
                                                    </div>
                                                    <div className="media-body align-self-center ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark text-orangered-hover">{ t("navbar.mark")}</h6>
                                                        <small className="text-muted mb-0">{ t("navbar.mark_description")}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }

                                    {
                                        (this.handleCheck("ajouter modele") == true) && (
                                            <Link to="/models" className="dropdown-item py-3">
                                                <div className="media">
                                                    <div className="avatar-md bg-soft-primary">
                                                        <i className="text-dark text-orangered-hover ti ti-chart-arcs"></i>
                                                    </div>
                                                    <div className="media-body align-self-center ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark text-orangered-hover">{ t("navbar.modele")}</h6>
                                                        <small className="text-muted mb-0">{ t("navbar.modele_description")}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }




                                    {
                                        (this.handleCheck("afficher rapport") == true) && (
                                            <Link to="/repports" className="dropdown-item py-3 d-none">
                                                <div className="media">
                                                    <div className="avatar-md bg-soft-primary">
                                                        <i className="text-dark text-orangered-hover ti ti-drone"></i>
                                                    </div>
                                                    <div className="media-body align-self-center ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark text-orangered-hover">{ t("navbar.rapports")}</h6>
                                                        <small className="text-muted mb-0">{ t("navbar.rapports_description")}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }

                                    {
                                        (this.handleCheck("afficher client") == true) && (
                                            <Link to="/customers" className="dropdown-item py-3">
                                                <div className="media">
                                                    <div className="avatar-md bg-soft-primary">
                                                        <i className="text-dark text-orangered-hover ti ti-user-circle"></i>
                                                    </div>
                                                    <div className="media-body align-self-center ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark text-orangered-hover">{ t("navbar.customers")}</h6>
                                                        <small className="text-muted mb-0">{ t("navbar.customers_description")}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }

                                    {
                                        (this.handleCheck("afficher personnel") == true) && (
                                            <Link to="/staffs" className="dropdown-item py-3">
                                                <div className="media">
                                                    <div className="avatar-md bg-soft-primary">
                                                        <i className="text-dark text-orangered-hover ti ti-users"></i>
                                                    </div>
                                                    <div className="media-body align-self-center ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark text-orangered-hover">{ t("navbar.staff")}</h6>
                                                        <small className="text-muted mb-0">{ t("navbar.staff")} {society_name}.</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    }
                                    
                                </div>
                                
                            </div>
                        </li>
                    )
                }

                
                
                  {
                    !user.id && (
                    <li className="dropdown notification-list m-0">
                        <a className="nav-link dropdown-toggle arrow-none nav-icon m-0 me-1"  href="/login" role="button"
                            aria-haspopup="false" aria-expanded="false">
                            <i className="text-dark text-orangered-hover ti ti-user"></i>
                        </a>
                    </li>
                  )}

                  {
                    user.id && (
                    <li className="dropdown">
                        <a className="nav-link dropdown-toggle nav-user p-0 m-0" data-bs-toggle="dropdown" href="#" role="button"
                            aria-haspopup="false" aria-expanded="false">
                            <div className="d-flex align-items-center">
                                <img src={ user.avatar } alt={ "CH DUTY TRUCK | " + user.name } title={ user.name} className="rounded-circle me-1 thumb-sm" />
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                            <Link className="dropdown-item" to="#"> { society_profile ? <span className="text-orangered"><i className="text-dark text-orangered ti ti-box font-16 me-1 align-text-bottom"></i> { society_profile } </span> : ""}</Link>
                            <div className="dropdown-divider mb-0"></div>
                            <Link className="dropdown-item" to="/profile"><i className="text-dark text-orangered-hover ti ti-user font-16 me-1 align-text-bottom"></i> { t("navbar.profil")}</Link>
                            <div className="dropdown-divider mb-0"></div>
                            <Link className="dropdown-item p-0" to="#" onClick={() => this.logout() }><LogoutGoogle /></Link>
                        </div>
                    </li>
                    )}

                    <Lang />
                    
              </ul>{/*end topbar-nav*/}

              <ul className="list-unstyled topbar-nav mb-0">                        
                  
                    <li className="ms-0 ps-0 me-4 overflow-hidden">
                        <a href="/" className={ user.id ? "font-14 fw-bolder text-orangered " : "font-18 fw-bolder text-orangered "} >
                        <img src="/logo192.png" height={ 40+"px"} alt="logo CH DUTY TRUCK" />
                        CH DUTY TRUCK
                        {/* <span className={ user.id && ("hide-phone_") }> EQUIPEMENT</span> */}
                      </a>
                    </li>
                 
                  <li className="hide-phone">
                      <div className="input-group w-100 m-0 border border-2 btn-outline-primary rounded rounded-4">
                            <button className=" btn btn-outline-secondary bg-white p-0 border border " type="button">
                                <select className="form-control border-0 text-mutued" name="cat" onChange={this.handleInput}>
                                    <option className="text-orangered">{ t("formulaire.trouver")}...</option>
                                    {
                                        this.state.categories.map((category) => {
                                            return( 
                                                <option value={category.id} key={category.id}>{ category.name }</option>
                                            )
                                        })
                                    }
                                </select>    
                            </button>
                            <input type="text" name="search" className="form-control form-control-dark border " onChange={this.handleInput} placeholder="Type text..." />
                            <a className="btn bg-white" href={"/searchs/" + this.state.cat + "/" + this.state.search }><i className="text-dark text-orangered ti ti-search"></i></a>
                        </div>
                  </li>                       
              </ul>
          </nav>
          
      </div>
        )
    }
}
export default withTranslation()(Navbar);