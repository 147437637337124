import React from "react";
import { Helmet } from 'react-helmet-async';

export default function Head(MetaData){
     const { title, image, url } = MetaData
    return (
    <Helmet>
            <meta name="description" content= { title }  />
            <title>{ title } | Speed Sell truck</title>
            <link rel="icon" type="image/png" sizes="32x32" href={ image  } />
            <link rel="apple-touch-icon" sizes="180x180" href={ image  } />
            <link rel="icon" type="image/png" sizes="32x32" href={ image  } />
            <link rel="icon" type="image/png" sizes="16x16" href={ image  } />
            <link rel="shortcut icon" type="image/x-icon" href={ image  } />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="author" content="Abibi Aspen" />
            <meta name="msvalidate.01" content="B15CA79F615F8737297B46F1C6324C45" />
            <link rel="canonical" href={ process.env.REACT_APP_CHDUTYTRUCK + url } />
            <meta name="msapplication-TileImage" content={ image  } />
            <meta name="description" content={ title +", SHANDONG C.H DUTY TRUCK AND EQUIPMENT GENERAL SUPPLIES "} />
            <meta name="keywords" content="chdutytruck" />
            <link rel="url" href={ process.env.REACT_APP_CHDUTYTRUCK + url } />
            <meta property="og:title" content="CH DUTY TRUCK" />
            <meta property="og:url" content={ process.env.REACT_APP_CHDUTYTRUCK + url } />
            <meta property="og:site_name" content={ title} />
            <meta property="og:image" content={  image   } />
            <meta property="og:description" content={ title +", SHANDONG C.H DUTY TRUCK AND EQUIPMENT GENERAL SUPPLIES "} />
        </Helmet>
    );
}