import axios from "axios";
import React, {Component} from "react";
import Register from "./Register";
import LoginGoogle from "./LoginGoogle";
import ReactLoading from "react-loading";
import Head from "./Head";

class Login extends Component{

    state = {
        username : "",
        password : "",
        error : "",
        registe : false,
        click : false,
        checked : true,
    }
    componentDidMount(){
        var description = localStorage.getItem("description");
        window.localStorage.clear();
        localStorage.setItem("description",  description);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            click : false
        });
    }

    createRegister = () =>{
        this.setState({ registe : !this.state.registe})
    }

    getLogin  = () => {

        this.setState({
            click : true,
            error : ""
        })
         axios.post("login", {
            username: this.state.username,
            password: this.state.password
        }).then((login) => {
            localStorage.setItem("user", JSON.stringify(login.data.data));
            localStorage.setItem("society", JSON.stringify(login.data.society ? login.data.society : {}) );
            localStorage.setItem("permissions", JSON.stringify(login.data.permissions ? login.data.permissions : []) );
            window.location.replace(process.env.REACT_APP_CHDUTYTRUCK);

        }).catch((error) => {
            var description = localStorage.getItem("description");
            window.localStorage.clear();
            localStorage.setItem("description",  description);
            this.setState({
                error: error?.response?.data.msg,
            });
        });
        
    }

 
    
    render(){
        if(this.state.registe) {
            return(
                 <Register />
            )
        }

        if(!this.state.registe) {
            return(
                <div id="body" className="auth-page" style={{backgroundImage: "url('img/background.png')", backgroundSize: "cover", backgroundPosition: "center center"}}>
            {/* Log In page */}
                <Head title ={ "CH DUTY TRUCK | Login "   } image={"/logo192.png"}  url ={ "/login"} />
                <div className="container-md">
                    <div className="row vh-100 d-flex justify-content-center p-0">
                        <div className="col-12 align-self-center p-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4 mx-auto">
                                        <div className="card shadow mb-4 rounded rounded-3">
                                            <div className="card-body p-0 rounded rounded-3">
                                                <div className="mt-3 d-flex justify-content-center">
                                                                
                                                    <img src="/logo192.png" height="50" alt="logo CH DUTY TRUCK" className="auth-logo" />
                                                    {/* <h4 className="ms-0 ps-0 fw-semibold text-orangered font-22">CH DUTY TRUCK</h4>   */}
                                                    {/* <p className="text-muted  mb-0">Connectez vous pour continuer.</p>   */}
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">                                    
                                                <form className="my-4">
                                                    {this.state.error && (<div className="alert alert-danger text-center text-danger py-1 px-2 text-small">{this.state.error}</div>)}            
                                                    <div className="form-group mb-2">
                                                        <label className="form-label" htmlFor="username">Téléphone ou email</label>
                                                        <input type="text" className="form-control" id="username" name="username" onChange={this.handleInput}  placeholder="" />                               
                                                    </div>{/*end form-group*/} 
                        
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="userpassword">Mot de passe</label>                                            
                                                        <input type="password" className="form-control" name="password"  onChange={this.handleInput}   id="userpassword" placeholder="" />                            
                                                    </div>{/*end form-group*/} 
                        
                                                    <div className="form-group row mt-3">
                                                        <div className="col">
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input  bg-orangered" checked={this.state.checked} onClick={ () => this.setState({ checked : !this.state.checked })} type="checkbox" id="customSwitchSuccess" />
                                                                <label className="form-check-label" htmlFor="customSwitchSuccess">Se souvenir de moi</label>
                                                            </div>
                                                        </div>{/*end col*/} 
                                                        {/* <div className="col-sm-6 text-end">
                                                            <a href="/resetpassword" className="text-muted font-13"><i className="dripicons-lock"></i> Mot de passe oublier?</a>                                    
                                                        </div> */}
                                                    </div> {/*end form-group*/} 

                                                    <div className="form-group mb-0 row">
                                                        <div className="col-12">
                                                            <div className="d-grid mt-3">
                                                            
                                                                <button className="btn btn-primary" type="button" onClick={ () => this.getLogin() }>Connexion { !this.state.click ? <i className="ti ti-lock font-20 ms-1"></i> : <ReactLoading type="spokes"  className="float-end text-orangered" height={15} width={15} />}</button>
                                                            
                                                            </div>
                                                        </div>{/*end col*/} 
                                                    </div>
                                                    
                                                </form>{/*end form*/}
                                               
                                                <hr className="hr-dashed mt-4" />
                                                
                                            </div>{/*end card-body*/}
                                        </div>{/*end card*/}
                                    </div>{/*end col*/}
                                </div>{/*end row*/}
                            </div>{/*end card-body*/}
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                </div>{/*end container*/}  
            </div>
            )
        }
    }
}
export default Login