import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   ProfileForm extends Component{

    state = {
        id : null,
        name : "",
        societyId : null,
        error : "",
        profilepermissions : [],
        isModal : false,
        title : "",
        permissionId : [],
        permissions : [],
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async permissions () {
        this.setState({
            permissionId : []
        })
        var permissions = await axios.get('permissions');
        if(permissions.status == 200){
            this.setState({
                permissions : permissions.data
            });
            var permissionId = [];
            permissions.data.map((permission) => {
                var count = this.state.profilepermissions.filter(data => data.permissionId == permission.id).length;
                if(count > 0)
                permissionId.push(permission.id);
            })

            this.setState({
                permissionId : permissionId
            })
        }
    };

    storeProfile = () =>{
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
       society = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
      
        var permissionId = [];

        this.setState({
            btn : !this.state.btn
        })

        for (let x in this.state.permissionId) {
                permissionId.push({ permissionId : this.state.permissionId[x]})
          }

        var data = {
                name : this.state.name,
                societyId : this.state.societyId ?  this.state.societyId : society,
                permissionId : permissionId,
        }

        if(data.societyId != null)
        {
            var request = this.state.id ? axios.put('profiles/'+this.state.id, data) : axios.post('profiles', data);
            request.then((response) =>{
                this.setState({
                    btn : !this.state.btn
                })
                this.props.showModal()
                toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            }).catch((error) => {
                this.setState({
                    error : error.response.data.msg,
                    btn : !this.state.btn
                })
                toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            })
        }else{
            window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/login`);
        }
    }

    edit=()=>  {
        this.form();
        this.permissions()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            name: this.props.formData ? this.props.formData.name : "",
            societyId: this.props.formData ? this.props.formData.societyId : null,
            title : this.props.formData ? "Modifier profil des utilisateurs" : "Ajouter profil des utilisateurs",
            profilepermissions : this.props.formData ? this.props.formData.profilepermissions : [],
            error : "",
        })
    }

    handleInputPermission = (e) => {
        var permissionId = this.state.permissionId;
        var permissions = this.state.permissions;
        this.setState({
            permissionId : [],
            permissions : []
        })
        var exist = permissionId.filter(data => data == e.target.name).length;
        if(exist > 0)
            permissionId = permissionId.filter(data => data != e.target.name);
        else
            permissionId.push(e.target.name);

        this.setState({
            permissionId : permissionId,
            permissions : permissions
        })
        
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    delete = (profile) =>{
        axios.delete("profiles/" + profile).then((response) => {
            this.getProfiles()
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size=""
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className="col-12 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Profile</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                            </div>
                        </div>

                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon ">Accès du profil { this.state.name} </label><br /><br />
                            {
                                this.state.permissions.map((permission) => {
                                    return(
                                        <label className="px-1 rounded rounded-3 border border-1 mt-2 ms-2 mx-2">
                                            <input type="checkbox" className="round round-3" onChange={this.handleInputPermission} name={ permission.id }  checked={this.state.permissionId.filter(data => data == permission.id).length}  id={permission.name} />
                                            <label htmlFor={permission.name}>{ permission.name }</label>
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {
                    (this.handleCheck("supprimer profil") == true && this.state.id) && (
                        <a href="#" onClick={() => this.delete(this.state.id)} className="btn btn-sm btn-outline-danger"><i className="ti ti-trash text-danger font-15"></i> Supprimer</a>
                    )}
                    <button onClick={ () => this.props.showModal() }  type="button" className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                    <button onClick={() => this.storeProfile()} disabled={ this.state.btn } className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> Sauvegarder</button>
                </Modal.Footer>
            </Modal>


        </div>
        )
    }
}
export default ProfileForm;
