import axios from "axios";
import React, {Component} from "react";
import StaffForm from "../components/StaffForm";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import Profile from "./Profile";
import { toast } from "react-toastify";

class Staff extends Component{

    state = {
        isModal : false,
        formData : null,
        staffs : [],
        loading : false
    }

    componentDidMount(){
        this.getStaffs();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getStaffs(){
        this.setState({
            loading : false
        })

        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
      var society = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
      
        var staffs = await axios.get('staffs/' + society);
        if(staffs.status === 200)
        {
            this.setState({staffs : staffs.data, loading : true})
        }
    }

    showModal = (user = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : user,
        })
        this.getStaffs()
    }

    delete = (user) =>{
        axios.delete("users/" + user).then((response) => {
            this.getStaffs()
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        var staffs =
        this.state.staffs.map((user, count) => {
                return(

                    <div className="col-md-3 overflow-hidden" key={user.id}>
                    <div className="card shadow bg-white mb-2">
                        <div className="card-body pb-0">
                            <div className="ribbon1 rib1-danger">
                                <span className="text-white text-center rib1-danger">
                                 {  user.userprofile ? user.userprofile.profile ? user.userprofile.profile.name : "staff" : "staff" }
                                </span>                                        
                            </div>
                            <Link to={'#'}  className="text-decoration-none">
                                <img src={ user.avatar } className="img-thumbnail"  style={{ width : 307 +"px", height : 220 + "px" }} />
                            </Link>
                            <div className="ms-2 mt-2">
                                <div>{  user.name  }</div>
                                <div><a href={"tel:"+  user.tel  }  target="_blank" className="text-dark text-decoration-none me-2"> <i className="ti ti-phone"></i> {  user.tel  }</a> <br />
                                 <a href={"mailto:"+ user.email }  target="_blank" className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-mail"></i> { user.email}</a> </div>
                                <div><a href="#" className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-maps"></i> { user.adress}</a> </div>
                            </div>
                                
                            <div className="float-end">
                            {(this.handleCheck("modifier personnel") == true) && (
                            <a href="#" onClick={ () => this.showModal(user)}><i className="ti ti-edit text-primary font-16 me-3" ></i></a>
                            )}

                            {(this.handleCheck("supprimer personnel") == true) && (
                                <a href="#" onClick={() => this.delete(user.id)}><i className="ti ti-trash text-danger font-16"></i></a>
                            )}
                            </div>   
                        </div>
                    </div>
                </div>
                

                )
        })
        return(
          <div>
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                          <h5 className="page-title">Personnel </h5>
                          </div>
                              {
                                  this.handleCheck("ajouter personnel") == true && (
                                      <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                          <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> Nouveau utilisateur</button>
                                      </div>
                                  )
                              }
                      </div>
                      {
                          !this.state.loading && (<Loading /> )
                      }
                </div>
                <div className="col-12">
                    <div className="border bg-white p-1 m-1"><Profile /></div>
                    <div className="card-body row">
                            
                        { staffs }
                                
                    </div>
                </div>

              </div>

            <StaffForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Staff;
