import React, {Component} from "react";
import { Link } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import Loadingsm from "../components/Loadingsm";
import Select from 'react-select';
import CountriesList from "../components/CountriesList";

class Profil extends Component{

    state = {
        id :  null,
        loading : false,
        loadingForm : false,
        btn : false,
        dataImage : "",
        image : [],
        password : "",
        confirmed : "",
        name : "",
        email : "",
        tel : "",
        adress : "",
        country : "",
        countryData : "",
        countryCode : "",
        region : "",
        city : "",
        error : "",
        errorImg : "",
        valid : "",
        user : {},
        countries : [],
        regions : [],
        cities : []
    }

    componentDidMount(){
        this.user();
        this.datacountries();
        this.refreshToken()
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async user(){
        
        var user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : false;
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
        
        if(user){
            var district = country.district ? country.district : "";
            this.setState({ 
                id : user.id,
                name : user.name,
                avatar : user.avatar,
                tel : user.tel,
                email : user.email,
                adress : user.adress,
                countryData : user.country,
                country : user.countryCode,
                countryCode : user.countryCode,
                region : user.region,
                city : user.city,
            })
        }
    }

    handleInputFile = (e) => {

        this.setState({
            image : [],
            btn : false,
            dataImage : e.target.files[0],
        })

        for(var i = 0 ; i < e.target.files.length; i++)
        {
            this.setState({
                loading : true
            })
            var img = "";
            try {
            img =  Resizer.imageFileResizer(
                  e.target.files[i],
                  1500,
                  1500,
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                        this.state.image.push(uri);
                    this.setState({ image: this.state.image });
                  },
                  "base64",
                  1000,
                  1000
                );
            } catch (err) {
                console.log(err);
            }
        }
        this.setState({
            errorImg : "",
            loading : false,
            loadingForm : false,
            dataImage : e.target.files[0],
        })
    }

    async datacountries(){
        var countries = CountriesList
        if(countries.length > 0){
            this.setState({
                countries : countries
            })
        }
    }

    
    async dataregions(cod = null){
        var code = cod !== null ? cod : this.state.countryCode;
        var regions = await axios.get("countries/" +  code);
    
        if(regions.status === 200){
            this.setState({
                regions : regions.data
            })
        }
    }
    
    async datacities(regio = null){
        var code = this.state.countryCode;
        var region = regio !== null ? regio : this.state.region;
        var cities = await axios.get("countries/" + code +"/"+ region);
        if(cities.status === 200){
            this.setState({
                cities : cities.data
            })
        }
    }

    async refreshToken () {
        try{
            var token = await axios.get('token');
            console.log(token)
            if(token.status == 200)
            {
                var user = (token.data.data);
                localStorage.setItem("user", JSON.stringify(user));
                this.setState({
                    avatar : user.avatar
                });
            }
            else{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                window.localStorage.clear();
                window.location.replace(`${process.env.REACT_APP_CHDUTYTRUCK}/login`)
            }
        }catch(error) {
            console.log(error)
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
            window.location.replace(`${process.env.REACT_APP_CHDUTYTRUCK}/login`)
        }
    }

    storeImage = async (e) => {
        e.preventDefault()
        this.setState({
            errorImg : "",
            loading : true,
            btn : !this.state.btn
        })

        let formData = new FormData()
        formData.append('file', this.state.dataImage)
        formData.append('user', this.state.id)

        const response = await fetch(process.env.REACT_APP_APICHDUTYTRUCK + '/avatars', {
            method: "POST",
            body: formData 
        })
        
        if (response.status === 200)
        {
            this.setState({
                loading : false,
                errorImg : "saved image",
                btn : !this.state.btn
            })
            this.refreshToken();
        }
        else{
            this.setState({
                loading : false,
                errorImg : "error",
                btn : !this.state.btn
            })
        }
    }

    storeProfile = () =>{

        if(this.state.password.length > 0 && this.state.password == this.state.confirmed)
        {
            this.setState({
                error:  "",
                btn : !this.state.btn
            });

            var data = {
                    profileId :   this.state.user.userprofile ? this.state.user.userprofile.profile ? this.state.user.userprofile.profile.id : null : null,
                    name : this.state.name,
                    email : this.state.email ? this.state.email : null,
                    tel : this.state.tel ? this.state.tel : null,
                    type : this.state.user ? this.state.user.type : null,
                    password : this.state.password ? this.state.password : null,
                    adress : this.state.adress,
                    country : this.state.countryData,
                    countryCode : this.state.countryCode,
                    region : this.state.region,
                    city : this.state.city,
                }

            var request = this.state.id ? axios.put('users/'+this.state.id, data) : axios.post('users', data);
            request.then((response) =>{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                axios.post("login", {
                    username: this.state.tel > 0 ? this.state.tel : this.state.email,
                    password: this.state.password
                }).then((login) => {
                    localStorage.setItem("user", JSON.stringify(login.data.data));
                    this.setState({ 
                        id : login.data.data.id,
                        name : login.data.data.name,
                        avatar : login.data.data.avatar,
                        tel : login.data.data.tel,
                        email : login.data.data.email,
                        adress : login.data.data.adress,
                        error : "success",
                        btn : !this.state.btn
                    })
                }).catch((error) => {
                    window.localStorage.clear();
                    this.setState({
                        error: error.response.data.msg,
                        btn : !this.state.btn
                    });
                });
            }).catch((error) => {
                this.setState({
                    error : error.response.data.msg,
                    btn : !this.state.btn
                })
            })
        }
        else{
            this.setState({
                error:  "Mot de passes differents"
            });
        }
    }
    

    hundelInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        let society = JSON.parse(localStorage.getItem('society')) ? JSON.parse(localStorage.getItem('society')) : {};
        let societ = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
      
        let optionCountries = [], optionRegions = [], optionCities = [];
        
        if(this.state.countries.length > 0)
        {
            this.state.countries.filter( data => process.env.REACT_APP_COUNTRIES.split(",").includes(data.code)).map(country => {
                optionCountries.push({
                    value : country.code, 
                    label : country.name,
                })
            })
        }

        if(this.state.regions.length > 0)
        {
            this.state.regions.map(region => {
                optionRegions.push({
                    value : region.region, 
                    label : region.region,
                })
            })
        }
        if(this.state.cities.length > 0)
        {
            this.state.cities.map(city => {
                optionCities.push({
                    value : city.city, 
                    label : city.city,
                })
            })
        }

        return(
            <div className="row">
                <h4 className="h4 font-monospace">Profil</h4>
                <div className="col-md-7 col">
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body d-flex">
                            <div className="image mr-3">
                                <Link to={'#'}  className="text-decoration-none">
                                    <img src={ this.state.avatar } className="rounded-circle img-fluid me-2" width={(  this.state.avatar) && ("100px")} />
                                </Link>
                            </div>
                            <div>
                                <div>{  this.state.name  }</div>
                                <div><a href={"tel:"+  this.state.tel  } rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <i className="ti ti-phone"></i> {  this.state.tel  }</a> </div>
                                <div><a href={"mailto:"+ this.state.email } rel="noopener noreferrer" target="_blank" className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-mail"></i> { this.state.email}</a> </div>
                                <div><a href="#" rel="noopener noreferrer"   className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-maps"></i> { this.state.adress}</a> </div>
                                <div className="text-center m-1">
                                    <span  className="badge bg-success small rounded-3 mx-1" type="button">{  this.state.user.userprofile ? this.state.user.userprofile.profile ? this.state.user.userprofile.profile.name : "" : "" }</span>
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-5">
                    <div className="card shadow overflow-hidden mb-3">
                        <div className="card-body">
                        <form onSubmit={this.storeImage}>
        
                            <h6 className="h6 font-monospace">Photo de profil</h6>
                            <div className="">
                                <label>Photo</label>
                                {
                                    this.state.image.map((img, index) => {
                                        return(
                                            <img src={img} alt="" key={index} className="rounded-circle img-fluid" width={"100"} />
                                        )
                                    })
                                }

                                <input type={"file"} className="form-control form-control-sm" name="file" onChange={this.handleInputFile} />
                            </div>
                                {
                                    this.state.loading && (
                                        <Loadingsm />
                                    )
                                }
                            <div className=" pt-2 text-center">
                                <span className="text-danger"> { this.state.errorImg }</span>
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3"  disabled={this.state.btn}  type='submit' >Modifier la photo</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card shadow overflow-hidden mb-3">
                        <div className="card-body row">
                            <h6 className="h6 font-monospace">Profil</h6>
                            <span className="text-primary text-small">{this.state.valid}</span>
                            <div className="col-md-4">
                                <label>Nom</label>
                                <input type={"text"} className="form-control form-control-sm" required={true} name="name" value={ this.state.name} onChange={this.hundelInput} />
                            </div>
                            <div className="col-md-4">
                                <label>Téléphone</label>
                                <input type={"number"} className="form-control form-control-sm" required={true} name="tel" value={ this.state.tel} onChange={this.hundelInput} />
                            </div>
                            <div className="col-md-4">
                                <label>Email </label>
                                <input type={"text"} className="form-control form-control-sm" required={true} name="email" value={ this.state.email} onChange={this.hundelInput} />
                            </div>

                            <div className={"col-md-4"}>
                                <div className="form-group has-icon-left">
                                    <label htmlFor="nom-id-icon">Votre pays </label>
                                    <div className="position-relative mt-1">
                                        <Select
                                            className="form-control form-control-sm p-0"
                                            value = {
                                                optionCountries.filter(option => 
                                                option.value?.toLowerCase() == this.state.country?.toLowerCase())
                                            }
                                            defaultValue={this.state.country}
                                            placeholder={this.state.countryData}
                                            onChange={
                                                (select) => {
                                                    this.setState({
                                                        country : select.value,
                                                        countryCode : select.value,
                                                        countryData : select.label,
                                                        regions : [],
                                                        cities : [],
                                                        region : "",
                                                        city : "",
                                                    });

                                                    if(select.value){
                                                        this.dataregions(select.value);
                                                    }
                                                }
                                            }
                                            options={optionCountries}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-4"}>
                                <div className="form-group has-icon-left">
                                    <label htmlFor="nom-id-icon">Region </label>
                                    <div className="position-relative mt-1">
                                        
                                        <Select
                                            className="form-control form-control-sm p-0"
                                            value = {
                                                optionRegions.filter(option => 
                                                option.value == this.state.region)
                                            }
                                            defaultValue={this.state.region}
                                            placeholder={this.state.region}
                                            onChange={
                                                (select) => {
                                                    this.setState({
                                                        region : select.value,
                                                        cities : [],
                                                        city : "",
                                                    });

                                                    if(select.value){
                                                        this.datacities(select.value);
                                                    }
                                                }
                                            }
                                            options={optionRegions}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-4"}>
                                <div className="form-group has-icon-left">
                                    <label htmlFor="nom-id-icon">Ville </label>
                                    <div className="position-relative mt-1">
                                        <Select
                                            className="form-control form-control-sm p-0"
                                            value = {
                                                optionCities.filter(option => 
                                                option.value == this.state.city)
                                            }
                                            defaultValue={this.state.city}
                                            placeholder={this.state.city}
                                            onChange={
                                                (select) => {
                                                    this.setState({
                                                        city : select.value,
                                                    });
                                                }
                                            }
                                            options={optionCities}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label>Adresse</label>
                                <input type={"text"} className="form-control form-control-sm" required={true} name="adress" value={ this.state.adress} onChange={this.hundelInput} />
                            </div>

                            <div className="col-md-4">
                                <label>Nouveau mot de passe</label>
                                <input type={"password"} className="form-control form-control-sm" required={true} name="password" onChange={this.hundelInput} />
                           </div>

                            <div className="col-md-4">
                                <label>Confirmer le mot de passe</label>
                                <input type={"password"} className="form-control form-control-sm" required={true} name="confirmed" onChange={this.hundelInput} />
                            </div>
                            
                            <div className=" pt-2 text-center">
                                <span className="text-danger text-small">{this.state.error}</span>
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" disabled={this.state.btn}  onClick={() => this.storeProfile()}>Modifier le profil</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 d-none">
                    <div className="card shadow overflow-hidden mb-3">
                        <div className="card-body row">
                            <h6 className="h6 font-monospace text-center">Poster votre téléphone sur <b className="text-orangered">CH DUTY TRUCK</b></h6>
                            <div className="m-2">
                                <div className="p-3 font-monospace text-center">
                                {
                                    societ ? <span>Suivez les devis effectués sur  <Link to={ (this.handleCheck("afficher article") == true) ? "/articles/" : "#" } className="text-primary text-decoration-none"> les articles et divers </Link> de votre agence ou voir les informations de votre <Link to={(this.handleCheck("configurer societe") == true) ? "/societies/" + societ : "#" } className="text-primary text-decoration-none"> agence </Link></span> : <span>Créer un <Link to={(this.handleCheck("ajouter societe") == true) ? "/societies/create" : "#" } className="text-primary text-decoration-none"> super compte </Link> pour vos articles et divers</span>
                                }
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Profil;