import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import { withTranslation } from 'react-i18next';

class DetailArticle extends Component{

 
    render(){
        const { t } = this.props
        var now = new Date(this.props.detailArticle.createdAt);
        var today = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()

        var data = [ ];
        this.props.detailArticle.id && (
        this.props.detailArticle.images.map( (img, count) => {
                data.push({
                    original : img.image,
                    thumbnail : img.image,
                })
            })
        )
 

        return(
            <div className="row">
                <div className="col-md-9 ">
                    <div className="card shadow bg-white mb-2">
                        <div className="card-body">
                            <div className="row">
                                
                                <div className="col-12 align-self-center overflow-hidden p-0">
                                    { data.length > 0 ? <ImageGallery items={data} autoPlay={true} /> : <img src={ null} className="img-thumbnail" alt={ "CH DUTY TRUCK"} style={{ width : 307 +"px", height : 204 + "px" }} />}
                                </div>{/*end col*/}
                                <div className="col-12 row align-self-center overflow-hidden">
                                    <div className="col-md-2"></div>deta
                                    <div className="col-md-10">
                                        <span className={ this.props.detailArticle.isValid == 1 ? "badge badge-soft-primary font-13 fw-semibold mb-2" : "badge badge-soft-danger font-13 fw-semibold mb-2"}>{ this.props.detailArticle.isValid == 1 ? "actif" : "non actif"}</span>
                                        <h5 className="font-18 mb-0">{ this.props.detailArticle ? this.props.detailArticle.category ? this.props.detailArticle.category.name : "" : ""}</h5>
                                        <p className="mb-2">{ this.props.detailArticle ? this.props.detailArticle.libelle : ""}</p> 
                                        <p className="mb-2">{ this.props.detailArticle ? this.props.detailArticle.country  : ""} { this.props.detailArticle ? this.props.detailArticle.region   : ""} { this.props.detailArticle ? this.props.detailArticle.city : ""} { this.props.detailArticle ? this.props.detailArticle.adress  : ""}</p> 
                                        <ul className="list-inline mb-2">
                                            <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-16"></i></li>
                                            <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-16"></i></li>
                                            <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-16"></i></li>
                                            <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-16"></i></li>
                                            <li className="list-inline-item"><i className="ti ti-star-half text-warning font-16"></i></li>
                                            
                                            <li className="font-16 "><i className="ti ti-check text-danger  fw-bolder"></i> période <span className="text-primary">{ this.props.detailArticle.periode} { this.props.detailArticle.unite }</span></li>
                                            { this.props.detailArticle.mark ? (<li className="font-13 "><i className="ti ti-check text-danger  fw-bolder"></i> { t("articleDetail.marque")}<span className="text-primary">  { this.props.detailArticle.mark.name }</span></li>) : null }
                                            { this.props.detailArticle. vitesse ? (<li className="font-13 "><i className="ti ti-check text-danger  fw-bolder"></i> { t("articleDetail.boite_vitesse")}<span className="text-primary"> { this.props.detailArticle. vitesse }</span></li>) : null}
                                            { this.props.detailArticle.color1 ? (<li className="font-13 "><i className="ti ti-check text-danger  fw-bolder"></i> { t("articleDetail.couleur_exterieure")}<span className="text-primary">  { this.props.detailArticle.color1 }</span></li>) : null}
                                            { this.props.detailArticle.color2 ? (<li className="font-13 "><i className="ti ti-check text-danger  fw-bolder"></i> { t("articleDetail.couleur_interieure")}<span className="text-primary">  { this.props.detailArticle.color2 }</span></li>) : null}
                                            { this.props.detailArticle.carburant ? (<li className="font-13 "><i className="ti ti-check text-danger  fw-bolder"></i> { t("articleDetail.carburant")}<span className="text-primary">  { this.props.detailArticle.carburant }</span></li>) : null}
                                            { this.props.detailArticle.room  &&(<li className="font-13 "><i className="ti ti-check text-danger  fw-bolder"></i> {t("articleDetail.chambre")}<span className="text-primary"> { this.props.detailArticle.room  }</span></li>)}
                                            { this.props.detailArticle.leaving_room  &&(<li className="font-13 "><i className="ti ti-check text-danger  fw-bolder"></i> { t("articleDetail.salon") }<span className="text-primary"> {  this.props.detailArticle.leaving_room   }</span></li>)}
                                            { this.props.detailArticle.bathroom  &&(<li className="font-13 "><i className="ti ti-check text-danger  fw-bolder"></i> { t("articleDetail.salle_de_bain") }<span className="text-primary"> {  this.props.detailArticle.bathroom  }</span></li>)}
                                        </ul>
                                        <h6 className="font-18 fw-bold">{ this.props.detailArticle.price }{ this.props.detailArticle.devise  }<span className="font-14 text-muted fw-semibold"><del>{ this.props.detailArticle.price }{  this.props.detailArticle.devise  }</del></span><span className="text-danger font-14 fw-semibold ms-2">0.1% Off</span></h6>
                                                                                    
                                    </div>
                                </div>{/*end col*/}                                            
                            </div>{/*end row*/}
                        </div>{/*end card-body*/}
                    </div>{/*end card*/}

                    {
                        this.props.detailArticle.description && (
                            <div className="card shadow mb-3">
                                <div className="card-body">
                                    <h5 className="page-title h5 m-2 font-monospace fw-bold">{ t("articleDetail.description")} </h5> 
                                    <p className="text-muted font-monospace">{ this.props.detailArticle.description}</p>
                                </div>
                            </div>
                        )
                    }

                    
                </div>{/*end col*/}

                <div className="col-md-3 overflow-hidden"> 
                    <div className="card shadow bg-white mb-2">
                        <div className="card-body">
                            <div className="text-center bg-light p-1 rounded rounded-3 align-item-center">
                                <h4 className="header-title mb-0"><span className="font-11"> pour <span className="text-muted">{ this.props.detailArticle.periode} { this.props.detailArticle.unite }</span></span></h4>  
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-18"></i></li>
                                    <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-18"></i></li>
                                    <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-18"></i></li>
                                    <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-18"></i></li>
                                    <li className="list-inline-item"><i className="ti ti-star-half text-warning font-18"></i></li>
                                </ul> 

                                <h6 className="font-13  mt-1 mb-1">{ t("articleDetail.addresse")} { this.props.detailArticle ? this.props.detailArticle.category ? this.props.detailArticle.category.name : "" : ""}</h6> 
                                <ul className="list-unstyled border-0">
                                    <li className="mb-2"><i className="ti ti-flag text-success me-1"></i>{ this.props.detailArticle.country}</li>
                                    <li className="mb-2"><i className="ti ti-check text-primary me-1"></i>{ this.props.detailArticle.region}</li>
                                    <li className="mb-2"><i className="ti ti-check text-warning me-1"></i>{ this.props.detailArticle.city}</li>
                                </ul>                                   
                            </div> 
                            
                            <div className="bg-light p-1 text-center mt-3 rounded rounded-3 align-item-center">
                                <h6 className="font-13  mt-1 mb-1">{ t("articleDetail.responsable")}</h6>                                                                                                       
                                <ul className="list-unstyled border-0">
                                    <li className="mb-2 text-muted font-14"><i className="text-success me-1"></i> <img src={ this.props.detailArticle.society ? this.props.detailArticle.society.logo ? this.props.detailArticle.society.logo : process.env.REACT_APP_CHDUTYTRUCK +"/img/agency.jpg" : process.env.REACT_APP_CHDUTYTRUCK +"/img/agency.jpg" } className="thumb-sm rounded rounded-3" alt="CH DUTY TRUCK" />{ this.props.detailArticle.society ? this.props.detailArticle.society.name : "" }</li>
                                    <li className="mb-2 text-muted"><i className="ti ti-flag text-warning me-1"></i>{ this.props.detailArticle.society ? this.props.detailArticle.society.city : ""}</li>
                                    <li className="mb-2 text-muted"><i className="ti ti-mail text-primary me-1"></i><a href={ this.props.detailArticle.society ? "mailto:" +this.props.detailArticle.society.email : "#" } className="text-decoration-none text-orangered-hover">{ this.props.detailArticle.society ? this.props.detailArticle.society.email : "" } </a></li>
                                    <li className="mb-2 text-muted"><i className="ti ti-phone text-primary me-1"></i><a href={ this.props.detailArticle.society ? "tel:" + this.props.detailArticle.society.tel : "#" } className="text-decoration-none text-orangered-hover">{ this.props.detailArticle.society ? this.props.detailArticle.society.tel : "" } </a></li>
                                </ul>                                                      
                            </div> 

                            <div className="bg-light p-1 mt-3 rounded rounded-3 text-center align-item-center">                                                                                   
                                <p className="text-muted mb-0"><i className="ti ti-calendar text-primary me-1"></i>{ today }</p>                                                                                                 
                            </div>  
                        </div>
                    </div>                                                                   
                </div>
            </div>
        )
    }
}

export default withTranslation()(DetailArticle);
