import axios from "axios";
import React, {Component} from "react";
import ModeleForm from "../components/ModeleForm";
import Loading from "../components/Loading";
import { toast } from "react-toastify";

class   Modele extends Component{

    state = {
        isModal : false,
        formData : null,
        modeles : [],
        loading : false,
    }

    componentDidMount(){
        this.getModeles();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getModeles(){
        this.setState({
            loading : false
        })
        var modeles = await axios.get('modeles');
        if(modeles.status === 200)
        {
            this.setState({modeles : modeles.data, loading : true})
        }
    }

    showModal = (modele = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : modele,
        })
        this.getModeles()
    }

    delete = (modele) =>{
        axios.delete("modeles/" + modele).then((response) => {
            this.getModeles()
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var count =1, modeles =
        this.state.modeles.map((modele) => {
            return(
                <tr key={modele.id}>
                    <td>{count++}</td>
                    <td>{ modele.mark?.name }</td>
                    <td>{ modele.name }</td>
                    <td>{ modele.user != null ? <span><img src={modele.user.avatar} className="rounded-circle thumb-sm" />{ modele.user.name}</span> : "" }</td>
                    <td className="text-end">
                        {
                            (this.handleCheck("modifier modele") == true  ) && (
                        <a href="#" onClick={ () => this.showModal(modele)}><i className="ti ti-edit text-primary font-22 me-3" ></i></a>
                        )}
                        {
                        (this.handleCheck("supprimer modele") == true  ) && (
                            <a href="#" onClick={() => this.delete(modele.id)}><i className="ti ti-trash text-danger font-22"></i></a>
                        )}
                    </td>
                </tr>
            )
        })
        return(
          <div>
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                          <h4 className="page-title">Models</h4>
                          </div>
                              {
                                  this.handleCheck("ajouter modele") == true && (
                                      <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                          <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> Nouveau model</button>
                                      </div>
                                  )
                              }
                      </div>
                      {
                          !this.state.loading && (<Loading /> )
                      }
                </div>
                <div className="col-12">
                    <div className="card shadow bg-white mb-2">
                        <div className="card-header">
                            <h4 className="card-titl">Liste des models</h4>
                            <p className="text-muted mb-0">
                                <small>Effectuer plus des opérations sur les models</small>
                            </p>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>marque</th>
                                            <th>model</th>
                                            <th>Actionnaire</th>
                                            <th className="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { modeles }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

              </div>

              <ModeleForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Modele;
