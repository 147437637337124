import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import ArtdetquotForm from "../components/ArtdetquotForm";
import DevisForm from "../components/DevisForm";
import Loading from "../components/Loading";

class   Quotation extends Component{

    state = {
        isModal : false,
        isModalArtdetaiquot : false,
        formData : null,
        quotations : [],
        loading : false,
        start : null,
        end : null,
        status : null,
    }

    componentDidMount(){
        this.getQuotations();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getQuotations(){
        this.setState({
            loading : false, 
            status : this.props.match.params.status
        })
        
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        var society_id = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
        try{
            var quotations = await axios.get('quotations/' + this.props.match.params.status+"/" + society_id);
            
            if(quotations.status === 200)
            { 
                this.setState({ quotations : quotations.data, loading : true })
            }
        }catch(error){
            if(error.response.status === 403)
                window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/login`);
        }
    }

    async getQuotationstatus(status = null){
        this.setState({
            quotations : [],
            loading : false,
            status : status,
        })
        
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        var society_id = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
        try{
            var quotations = await axios.get('quotations/' + status + "/" + society_id);
            if(quotations.status === 200)
            { 
                this.setState({ quotations : quotations.data, loading : true })
            }
        }catch(error){
            if(error.response.status === 403)
                window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/login`);
        }
    }

    showModal = (quotation = null) => {
        
        this.setState({
            isModal : !this.state.isModal,
            formData : quotation,
        })
        this.getQuotationstatus(this.state.status)
    }

    showModalArtdetaquot = (quotation = null) => {
        this.setState({
            isModalArtdetaiquot : !this.state.isModalArtdetaiquot,
            formData : quotation,
        })
        this.getQuotationstatus(this.state.status)
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};

        var quotations =
        this.state.quotations.map((quotation) => {

            var nombre = quotation.detailquotation.price ;
            var arrondi = nombre*100;          
            arrondi = Math.round(arrondi); 
            arrondi = arrondi/100;

            var nombre1 = quotation.price ;
            var arrondi1 = nombre1*100;          
            arrondi1 = Math.round(arrondi1); 
            arrondi1 = arrondi/100;

            return(
                <div className="col-md-3 mt-2 overflow-hidden" key={quotation.id}>
                    <div className="card shadow bg-white mb-2">
                        <div className="card-body">
                            <div className={ quotation.status === "request" ? "ribbon1 rib1-warning" : quotation.status == "paid" ? "ribbon1 rib1-success" : "ribbon1 rib1-primary"}>
                                <span className={ quotation.status === "request" ? "text-white text-center rib1-warning" : quotation.status == "paid" ? "text-white text-center rib1-success" :  "text-white text-center rib1-primary" }>{ quotation.status}</span>                                        
                            </div>                                     
                            <Link to={ quotation.status ==="request" ? "#" : "/quotations/" + this.state.status + "/" + quotation.id }><img src={ quotation.status === "request" ?  quotation.detailquotation.category.image: quotation.detailquotation.article.images[0]?.image} className="img-thumbnail" alt="Responsive Image" style={{ width : 307 +"px", height : 204 + "px" }} /></Link>
                            <div className="  align-items-center ">
                                <div className="border-bottom p-2 m-1">
                                    <p className="fw-bolder font-13 text-muted text-truncate mb-0"><Link to={ quotation.status === "request" ? "#" : "/quotations/" + this.state.status + "/" + quotation.id } className="text-truncate text-orangered-hover">{ quotation.status === "request" ? quotation.detailquotation.category.name :  quotation.detailquotation.article.category ? quotation.detailquotation.article.category.name : ""} <small>{ quotation.detailquotation.libelle } ({ quotation.detailquotation.quantity })</small> </Link></p>
                                    <p className="text-muted text-truncate mb-0"><Link to={ quotation.status === "request" ? "#" : "/quotations/" + this.state.status + "/" + quotation.id } className="font-13 text-truncate text-orangered-hover"><small>{ quotation.detailquotation.periode } { quotation.detailquotation.unite }, { quotation.detailquotation.room > 1 ? quotation.detailquotation.room + " chambres" : quotation.detailquotation.room > 0 ? quotation.detailquotation.room + " chambre" : "" }</small></Link></p>
                                    <p className="text-muted text-truncate mb-0"><Link to={ quotation.status === "request" ? "#" : "/quotations/" + this.state.status + "/" + quotation.id } className="font-13 text-truncate font-monospace text-orangered-hover">{ quotation.detailquotation.region} { quotation.detailquotation.city} { quotation.detailquotation.adress}</Link></p>
                                    <p className="text-muted text-truncate mb-0"><Link to={ quotation.status === "request" ? "#" : "/quotations/" + this.state.status + "/" + quotation.id } className="font-13 text-truncate font-monospace text-orangered-hover">{ quotation.detailquotation.description ? quotation.detailquotation.description : <br /> }</Link></p>
                                </div>
                                <div className=" p-2 m-1">
                                    <div className="text-muted">
                                        <span className="fw-bold text-orangered">{ arrondi > 0 ? arrondi : "" }{  arrondi > 0 ? quotation.detailquotation.devise  : "" }</span>
                                         {/* <small>({ quotation.detailquotation.periode }{  quotation.detailquotation.unite })</small> */}
                                    </div>
                                </div>
                                <div className="float-end">
                                    {
                                        quotation.status === "request" ?<>
                                            <Link to={"#"} className="btn btn-outline-blue btn-sm mx-1 my-1" onClick={() => this.showModal(quotation)}>Détail</Link>
                                            <Link to={"#"} className="btn btn-outline-blue btn-sm mx-1 my-1" onClick={() => this.showModalArtdetaquot(quotation)} >Suggerer</Link>
                                        </>
                                        :
                                        <>
                                            <Link to={"/quotations/" + this.state.status + "/" + quotation.id} className="btn btn-outline-blue btn-sm mx-1 my-1">Suivant</Link>
                                            {/* <Link to={"#"} className="btn btn-outline-blue btn-sm mx-1 my-1" >Intervenir</Link> */}
                                        </>
                                    }
                                </div>    
                            </div>    
                        </div>
                    </div>
                </div>
            )
        })
        
        return(
          <div>
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                            <h5 className="page-title h5 fw-bold font-monospace">Les réservations effectués sur mes articles et divers</h5>
                            <div>
                                <button className="btn btn-outline-blue btn-sm m-1" onClick={ () => this.getQuotationstatus("loading") }>réservations en attentes</button>
                                <button className="btn btn-outline-blue btn-sm m-1" onClick={ () => this.getQuotationstatus("request") }>devis recherchés</button>
                                <button className="btn btn-outline-blue btn-sm m-1" onClick={ () => this.getQuotationstatus("paid") }>réservations payés</button>
                                <button className="btn btn-outline-blue btn-sm m-1" onClick={ () => this.getQuotationstatus("closed") }>réservations cloturés</button>
                            </div>
                          </div>
                      </div>
                </div>
                <div className="col-12">
                    <div className=" bg-white mb-2">
                        <div className=" row pt-2">
                            
                            {
                                !this.state.loading && (<Loading /> )
                            }
                            
                            { quotations }
                                
                        </div>
                    </div>
                </div>

              </div>

              <DevisForm
                    isModal={this.state.isModal}
                    formData={this.state.formData}
                    showModal={this.showModal}
                />

            {
                this.state.isModalArtdetaiquot && (
                    <ArtdetquotForm
                        isModal={this.state.isModalArtdetaiquot}
                        formData={this.state.formData}
                        showModal={this.showModalArtdetaquot}
                    />
                )
            }
      </div>
        )
    }
}
export default Quotation;
