import axios from "axios";
import React, {Component} from "react";
import CustomerForm from "../components/CustomerForm";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";

class Customer extends Component{

    state = {
        isModal : false,
        formData : null,
        customers : [],
        loading : false
    }

    componentDidMount(){
        this.getCustomers();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getCustomers(){
        this.setState({
            loading : false
        })
        
        var customers = await axios.get('users');
        if(customers.status === 200)
        {
            this.setState({customers : customers.data, loading : true})
        }
    }

    showModal = (customer = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : customer,
        })
        this.getCustomers()
    }

    delete = (customer) =>{
        axios.delete("users/" + customer).then((response) => {
            this.getCustomers()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        var customers =
        this.state.customers.map((user, count) => {
                return(

                    <div className="col-md-3 overflow-hidden" key={user.id}>
                    <div className="card shadow bg-white mb-2">
                        <div className="card-body pb-0">
                            <div className="ribbon1 rib1-danger">
                                <span className="text-white text-center rib1-danger">
                                 {  user.userprofile ? user.userprofile.profile ? user.userprofile.profile.name : "Client" : "Client" }
                                </span>                                        
                            </div>
                            <Link to={'#'}  className="text-decoration-none">
                                <img src={ user.avatar } className="img-thumbnail"  style={{ width : 307 +"px", height : 204 + "px" }} />
                            </Link>
                            <div className="ms-2 mt-2" style={{ fontSize : 11}}>
                                <div>{  user.name  }</div>
                                <div><a href={"tel:"+  user.tel  }  target="_blank" className="text-dark text-decoration-none me-2"> <i className="ti ti-phone"></i> {  user.tel  }</a> <br />
                                 <a href={"mailto:"+ user.email }  target="_blank" className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-mail"></i> { user.email}</a> </div>
                                <div><a href="#" className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-maps"></i> { user.country} { user.region} { user.city} { user.adress}</a> </div>
                                <div><a href="#" className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-maps"></i> { new Date(user.updatedAt).toLocaleDateString("en-GB") } {new Date(user.updatedAt).getHours()}:{ new Date(user.updatedAt).getMinutes()} </a> </div>
                            </div>
                                
                            <div className="float-end">
                            {(this.handleCheck("modifier client") == true) && (
                            <a href="#" onClick={ () => this.showModal(user)}><i className="ti ti-edit text-primary font-16 me-3" ></i></a>
                            )}

                            {(this.handleCheck("supprimer client") == true) && (
                                <a href="#" onClick={() => this.delete(user.id)}><i className="ti ti-trash text-danger font-16"></i></a>
                            )}
                            </div>   
                        </div>
                    </div>
                </div>
                

                )
        })
        return(
          <div>
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                          <h5 className="page-title">Clients </h5>
                          </div>
                              {
                                  this.handleCheck("ajouter client") == true && (
                                      <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                          <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> Nouveau client</button>
                                      </div>
                                  )
                              }
                      </div>
                      {
                          !this.state.loading && (<Loading /> )
                      }
                </div>
                <div className="col-12">
                    <div className="card-body row">
                            
                        { customers }
                                
                    </div>
                </div>

              </div>

            <CustomerForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Customer;
