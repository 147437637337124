import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import ArticleForm from "../components/ArticleForm";
import Loading from "../components/Loading";

class   Article extends Component{

    state = {
        isModal : false,
        formData : null,
        articles : [],
        loading : false,
        loadingbtn : false,
        last : false,
        page : 0,
        status : null,
    }

    componentDidMount(){
        this.getArticles();
    }

    async getArticles(status = "1"){
        this.setState({
            loading : false,
            status : status
        })
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        var society = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
      
        var articles = await axios.get('myarticles/' + society + "/"+status);

        if(articles.status === 200)
        { 
            this.setState({articles :articles.data, loading : true})
        }
    }

    showModal = (article = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : article,
        })
        this.getArticles(this.state.status);
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};

        var count = 1, articles =
        this.state.articles.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1 ).map((article) => {
            var nombre = article.price ;
            var arrondi = nombre*100;          
            arrondi = Math.round(arrondi); 
            arrondi = arrondi/100;
            return(
                <div className="col-md-3 overflow-hidden" key={article.id}>
                    <div className="card rounded rounded-3 shadow mt-1">
                        <div className="card-body p-0">
                            <div className={ article.isValid === "1" ? "ribbon1 rib1-primary" : "ribbon1 rib1-danger"}>
                                <span className={ article.isValid === "1" ? "text-white text-center rib1-primary" : "text-white text-center rib1-danger" }>{ article.view > 5 ? article.view+"views" : article.view+"views"}</span>                                        
                            </div> 
                            <Link to={"/items/"+article.id}>
                                <img src={ article.images.image} className="img-thumbnail" alt={ article.images.image} style={{ width : 307 +"px", height : 204 + "px" }} />
                            </Link>
                            <div className="p-2 pb-0">
                                <div className="border-bottom m-1">
                                    <div className="m-0 text-truncate">
                                        <p className="header-title text-muted text-truncate mb-0"><Link to={'/items/' + article.id } className="font-13 text-truncate text-orangered-hover">{ article.category ? article.category.name : ""}  </Link></p>
                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+article.id } className="font-13 text-truncate text-orangered-hover"> { article.mark?.name} { article.modele?.name}</Link></p>
                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+article.id } className="font-13 text-truncate text-orangered-hover font-monospace">{ article.city}</Link></p>
                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+article.id } className="font-13 text-truncate text-orangered-hover font-monospace">{ article.description ? article.description : <br /> }</Link></p>
                                    </div>
                                </div>
                                <div className="border-bottom m-1">
                                    <p className="text-muted">
                                        <span className="header-title me-2 text-orangered  mx-1">{ arrondi > 0 ? arrondi : "" }{  arrondi > 0 ? article.devise || "$"  : "" }</span>
                                        <span className="mx-1"><small> (<span className="small">{ article.quantity }</span>)</small></span> <span className="text-dark"><i className="ti ti-xbox-y text-orangered"></i>{ article.mark?.name }</span>  <span className="text-dark"><i className="ti ti-xbox-x text-orangered"></i>{ article.modele?.name }</span>
                                    </p>
                                </div>

                                <div>
                                <img src={ article.user?.avatar } alt={ article.user?.name } title={ article.user?.name } className="rounded-circle me-2 thumb-sm" />
                                    {
                                        (this.handleCheck("modifier article") === true && user.id == article.user.id || this.handleCheck("admin") === true ) && (
                                            <button className="btn btn-outline-blue btn-sm mx-1 my-1" onClick={ () => this.showModal(article)}> actualiser</button>
                                        )
                                    }
                                </div>
                            </div> 
                             
                        </div>
                    </div>
                </div>
            )
        })
        
        return(
          <div>
          
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                          <h4 className="page-title">Mes articles <span className={ this.state.status === "1" ? "text-primary" : "text-danger"}> { this.state.status == "1" ? "Actifs" : "Non actifs" }</span></h4>
                          </div>
                            <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                <button type="button" className="btn btn-sm btn-outline-primary mx-1 py-0 rounded rounded-3" onClick={() => this.getArticles("1")}><i className="ti ti-activity font-18"></i> actif</button>
                                <button type="button" className="btn btn-sm btn-outline-danger m-1 py-0 rounded rounded-3" onClick={() => this.getArticles("0")}><i className="ti ti-activity font-18"></i> non actif</button>
                                {
                                    this.handleCheck("ajouter article") == true && (
                                        <button type="button" className="btn btn-sm btn-outline-primary mx-1 py-0 rounded rounded-3" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> Nouvel article</button>
                                    )
                                }
                            </div>
                      </div>
                      {
                          !this.state.loading && (<Loading /> )
                      }
                </div>
                <div className="col-12">
                    <div className=" bg-white mb-2">
                         
                        <div className=" row">
                            
                            { articles }
                                
                        </div>
                    </div>
                </div>

              </div>

              <ArticleForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Article;
