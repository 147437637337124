import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import DevisForm from "../components/DevisForm";
import Loading from "../components/Loading";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

class   Myquotationstatus extends Component{

    state = {
        isModal : false,
        formData : null,
        quotations : [],
        loading : false,
        start : null,
        end : null,
        status : null,
    }

    componentDidMount(){
        this.getQuotationstatus();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getQuotationstatus(statu = null){
        var status = statu ? statu : this.props.match.params.status
        this.setState({
            loading : true,
            quotations : [],
            status : status
        })
        
        try{
            var quotations = await axios.get('myquotations/' + status);
            if(quotations.status === 200)
            {
                this.setState({ quotations : quotations.data, loading : false })
            }
        }catch(error){
            if(error.response && error.response.status === 403)
                window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/login`);
        }
    }


    showModal = (article = null) => {

        this.setState({
            isModal : !this.state.isModal,
            formData : article,
        })

        this.getQuotationstatus(this.state.status)
    }

    delete = (quotation) =>{
        axios.delete("quotations/" + quotation).then((response) => {
            this.getQuotationstatus(this.state.status)
        }).catch((error)=>{})
    }


    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};

        var quotations =
        this.state.quotations.map((quotation) => {
 
            var nombre = quotation.detailquotation.price ;
            var arrondi = nombre*100;          
            arrondi = Math.round(arrondi); 
            arrondi = arrondi/100;

            var nombre1 = quotation.price ;
            var arrondi1 = nombre1*100;          
            arrondi1 = Math.round(arrondi1); 
            arrondi1 = arrondi/100;

            return(
                <div className="col-md-3 mt-2 overflow-hidden" key={quotation.id}>
                    <div className="card shadow bg-white mb-2">
                        <div className="card-body">
                            <div className={ quotation.status === "request" ? "ribbon1 rib1-warning" : quotation.status == "paid" ? "ribbon1 rib1-success" : "ribbon1 rib1-primary"}>
                                <span className={ quotation.status === "request" ? "text-white text-center rib1-warning" : quotation.status == "paid" ? "text-white text-center rib1-success" :  "text-white text-center rib1-primary" }>{ quotation.status}</span>                                        
                            </div>   
                                                               
                            <Link to={ quotation.status === "request" ? "#" : "/myquotations/request/" + quotation.id }><img src={ quotation.status === "request" ?  quotation.detailquotation.category.image : quotation.detailquotation.article.images[0]?.image} className="img-thumbnail" alt="Responsive Image" style={{ width : 307 +"px", height : 204 + "px" }} /></Link>
                            <div className="  align-items-center ">
                                <div className="border-bottom ">
                                    <div className="text-muted">
                                        <div className="m-0 text-truncate p=0">
                                            <p className="fw-bolder font-13 text-muted text-truncate mb-0"><Link to={ quotation.status === "request" ? "#" : "/myquotations/" + this.state.status + "/" + quotation.id } className="text-truncate"><span className="header-title">{ quotation.detailquotation.quantity }</span>{ quotation.status === "request" ? quotation.detailquotation.category.name :  quotation.detailquotation.article.category ? quotation.detailquotation.article.category.name : ""} <small>{ quotation.detailquotation.libelle }</small> </Link></p>
                                            <p className="text-muted text-truncate mb-0"><Link to={ quotation.status === "request" ? "#" : "/myquotations/" + this.state.status + "/" + quotation.id } className="font-13 text-truncate">{ quotation.detailquotation.room > 1 ? quotation.detailquotation.room + " chambres" : quotation.detailquotation.room > 0 ? quotation.detailquotation.room + " chambre" : "" } </Link></p>
                                            <p className="text-muted text-truncate mb-0"><Link to={ quotation.status === "request" ? "#" : "/myquotations/" + this.state.status + "/" + quotation.id } className="font-13 text-truncate font-monospace">{ quotation.detailquotation.region} { quotation.detailquotation.city} { quotation.detailquotation.adress}</Link></p>
                                            <p className="text-muted text-truncate mb-0"><Link to={ quotation.status === "request" ? "#" : "/myquotations/" + this.state.status + "/" + quotation.id } className="font-13 text-truncate font-monospace">{ quotation.detailquotation.description ? quotation.detailquotation.description : <br /> }</Link></p>
                                        </div>
                                        <span className=" ms-2"><Link to={ quotation.status === "request" ? "#" : "/myquotations/" + this.state.status + "/" + quotation.id } className="text-truncate">{ quotation.detailquotation.article ? quotation.detailquotation.article.libelle : "" }</Link></span> 
                                    </div>
                                </div>
                                <div className="  ">
                                    <div className="text-muted">
                                        <span className="fw-bolder text-dark me-2">{ arrondi > 0 ? arrondi : "" }{  arrondi > 0 ? quotation.detailquotation.devise  : "" }</span>
                                         <small>({ quotation.detailquotation.periode }{  quotation.detailquotation.unite })</small>
                                     </div>
                                </div>
                                <div>
                                {
                                    quotation.paid === 1 && quotation.status == "paid"  ?(
                                        <div className="text-center text-primary h6 mx-3"><a target={"_blank"} href={  quotation.invoicelink } className="badge badge-soft-primary ms-2 font-13 fw-semibold mb-2 text-orangered"> <span className="ti ti-file font-18"></span> Voir la facture <span className="d-none">N°{ quotation.invoice }</span> </a></div>
                                    ):
                                    (
                                        <>
                                        {
                                            (quotation.status === "loading" || quotation.status === "request" ) && (
                                                <button className="btn btn-outline-danger btn-sm mx-1 my-1" onClick={ () => this.delete(quotation.id)}>annuler</button>
                                            )
                                        }
                                        {
                                            quotation.status === "request" ?<>
                                                <Link to={"#"} className="btn btn-outline-blue btn-sm mx-1 my-1 " onClick={() => this.showModal(quotation)}>Détail</Link>
                                            </>
                                            :
                                            <>
                                                <Link to={"/myquotations/" + this.state.status + "/" + quotation.id} className="btn btn-outline-blue btn-sm mx-1 my-1">Suivant</Link>
                                            </>
                                        }
                                        </>
                                    )  
                                }
                                    
                                </div>    
                            </div>    
                        </div>
                    </div>
                </div>
            )
        })
        
        return(
          <div>
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                            <h4 className="page-title">Mes réservations</h4>
                            <div>
                                <button className="btn btn-outline-blue btn-sm m-1" onClick={ () => this.getQuotationstatus("loading") }>réservations en attentes</button>
                                <button className="btn btn-outline-blue btn-sm m-1" onClick={ () => this.getQuotationstatus("paid") }>réservations payés</button>
                                <button className="btn btn-outline-blue btn-sm m-1" onClick={ () => this.getQuotationstatus("request") }>devis recherchés</button>
                                <button className="btn btn-outline-blue btn-sm m-1" onClick={ () => this.getQuotationstatus("close") }>réservations cloturés</button>
                                <button type="button" className="btn btn-outline-blue btn-sm m-1" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> Faire un devis</button>
                            </div>
                          </div>
                      </div>
                      
                </div>
                <div className="col-12">
                    <div className=" bg-white mb-2">
                        <div className=" row pt-2">

                            { quotations }

                        </div>
                    </div>
                </div>
                <div className="text-center">
                    {
                        this.state.loading && (<Loading /> )
                    }
                    </div>
              </div>

              <DevisForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Myquotationstatus;
