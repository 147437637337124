import axios from "axios";
import React, {Component} from "react";
import '../assets/css/chat.css'

class Chat extends Component{

    state = {
        id : "",
        user : {},
        userRecever : {},
        chat_id : "",
        recever_id : "",
        chat : "",
        sms : "",
        loading : true,
        file : "",
        boxes : [],
        chats : [],
        users : [],
        error : "",
        btn  : false
    }

    componentDidMount(){
        var user = JSON.parse(localStorage.getItem('user'))
        this.setState({
            user : user,
            userRecever : this.props.userRecever ? this.props.userRecever : {}
        })
        this.getUser()
        this.getBoxes()
        this.users();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    
    async getUser(){
        var userid = JSON.parse(localStorage.getItem('user'))
        var user = await axios.get('users/'+userid.id)
        if(user.status === 200)
        {
            this.setState({
                user : user.data
            })
        }
    }

    async getBoxes(){
        var boxes = await axios.get('chats')
        if(boxes.status === 200)
        {
            this.setState({
                boxes : boxes.data
            })
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async users () {
        var users = await axios.get('users');
        if(users.status === 200)
        {
            this.setState({
                users : users.data
            })
        }
    }

    handleInputSearch = (e) => {
        var val = e.target.value;
        if(val.length > 0)
        {
            var box = this.state.users.filter(user => user.name?.toLowerCase() === val?.toLowerCase()  || user.email && user.email?.toLowerCase() === val?.toLowerCase() || user.tel === val   );
            

            this.setState({
                boxes : box
            })
        }
        else{
            this.getBoxes();
        }
    }

    async getRecever(recever)
    {
        var userRecever = await axios.get('users/'+recever)
        if(userRecever.status === 200)
        {
            this.setState({
                userRecever : userRecever.data,
                recever_id : userRecever.data.id
            })
            this.getChats(recever)
        }
    }
    

    async getChats(recever)
    {
        var chats = await axios.get('chats/get/' + recever);
        if(chats.status == 200)
        {
            this.setState({
                chats : chats.data,
            })
        }
    }

    storeChat = (e) => {
        e.preventDefault();
        
        this.setState({
            btn : !this.state.btn
        })
        var user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        
        var data = { 
            recever_id : this.props.userRecever ? this.props.userRecever.id : this.state.recever_id,
            sender_id : user.id,
            chat : this.state.chat,
            last : 1,
        }
 

        var url = this.state.id ? axios.put('chats/' + this.state.id, data) : axios.post('chats', data);
        url.then((response) => {
            this.getBoxes()
            this.setState({
                chat : "",
                error : "",
                btn  : !this.state.btn
            })
            this.getChats(this.props.userRecever ? this.props.userRecever.id : this.state.recever_id);

        }).catch((error) => {
            this.setState({
                error : "une erreur s'est produite",
                btn : !this.state.btn
            })
        })
    }
 

    render(){
        var user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        var exist = [], font="";
        return (
            <div className="container p-0 mt-4">
                    <div className="card mt-3 shadow rounded rounded-3">
                        <div className="row g-0">
                            {/* {
                                this.props.display && ( */}
                                    <div className="col-12 col-lg-5 col-xl-3 border bg-white mb-3  pb-2 chat-messages">

                                        <div className="px-4 d-md-block">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <input type="text" search="search" onChange={this.handleInputSearch} className="form-control my-3" placeholder="Rechercher..." />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {
                                            this.state.boxes.filter(data => data.id !== this.state.userRecever.id  ).map((box) => {
                                                if(exist.filter(dat => dat === box.recever_id).length == 0  )
                                                {
                                                    if(box.read_at){
                                                        font = "font-13"
                                                    }
                                                    else{
                                                        font = "font-16"
                                                    }
                                                    exist.push(box.recever_id)
                                                    return(
                                                        <a href="#" key={box.id} className={" list-group-item list-group-item-action border-0"} onClick={ () => this.getRecever(box.recever_id ? box.recever_id : box.id)}>
                                                            <div className="d-flex align-items-start">
                                                                <img src={ box.user ? box.user.avatar : box.avatar} className="rounded-circle mr-1" alt="Fiona Green" width="50" height="50" />
                                                                <div className="flex-grow-1 ml-3">
                                                                    { box.name}
                                                                    <p className={ font }> { box.chat} </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                }
                                            })
                                        }
                                        <hr className="d-block d-lg-none mt-1 mb-0" />
                                    </div>
                                 {/* )
                            } */}
                            <div className={ "col-12 col-lg-7 col-xl-9" }>
                                <div className="py-2 px-4 border-bottom">
                                    <div className="d-flex align-items-center py-1">
                                        <div className="position-relative">
                                            <img src={ this.state.userRecever.avatar } className="rounded-circle mr-1" alt={ this.state.userRecever.name } width="40" height="40" />
                                        </div>
                                        <div className="flex-grow-1 pl-3">
                                            <strong className="font-monospace">{ this.state.userRecever.name}  </strong>
                                            <div className="text-muted small"><em>Message...</em></div>
                                        </div>
                                        <div>
                                           <i className="ti ti-mail font-22 text-primary "></i>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="position-relative">
                                    <div className="chat-messages p-4">

                                        {
                                            this.state.chats.map((chat) => {
                                                
                                                var time = new Date(chat.createdAt);
                                                if(user.id === chat.sender_id)
                                                {
                                                    return (
                                                        <div className="chat-message-right mb-4" key={chat.id}>
                                                            <div className="float-end font-10">{time.getHours() + ":" +time.getMinutes()}</div>
                                                            <div className="flex-shrink-1 rounded py-1 px-3 mr-3 border border-2 border-light  rounded rounded-3">
                                                                {chat.chat} 
                                                            </div>
                                                        </div>
                                                    )
                                                }else{
                                                    return(
                                                        <div className="chat-message-left pb-4" key={chat.id}>
                                                            <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3   rounded rounded-3">
                                                                {chat.chat} 
                                                            </div>
                                                            <div className="float-end font-10">{time.getHours() + ":" +time.getMinutes()}</div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                         

                                        

                                    </div>
                                </div>

                                {
                                    this.state.userRecever.id && (
                                        <form onSubmit={this.storeChat } method="POST">
                                            <div className="flex-grow-0 py-3 px-4 border-top">
                                                <div className="text-danger">{this.state.error}</div>
                                                 
                                                <div className="col-md-12">
                                                    <textarea className="form-control border border-1 border-secondary"  onChange={this.handleInput} name="chat" value={this.state.chat}  placeholder="Taper ici ..."/>
                                                    <div className="text-end mt-1">
                                                        <button className="btn btn-primary btn-sm py-1" disabled={this.state.btn } type="submit">Envoyer</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default Chat