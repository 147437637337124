import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

class   ArtdetquotForm extends Component{

    state = {
        id : "",
        customerId : null,
        articleId : null,

        cities : [],
        title : "",
        categories : [],
        articles : [],
        btn : false,
        error : ""
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

        if(e.target.name == "description")
            localStorage.setItem("description", e.target.value);
    }
    async categories () {
        var categories = await axios.get("categories");
        if(categories.status == 200){
            this.setState({
                categories : categories.data
            })
        }
    }

    async getArticles(status = "actif"){
        this.setState({
            loading : false,
            articles : [],
            status : status
        })
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        var society = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
      
        status = status == "actif" ? 1 : 0
        var articles = await axios.get('myarticles/' + society + "/"+status);
        if(articles.status === 200)
        { 
            this.setState({articles :articles.data, loading : true})
        }
    }

    storeArtdetquot = () =>{
        if(this.state.articleId)
        {
            axios.post("artdetquots", { articleId : this.state.articleId, detailquotationId : this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.id : null : null })
            this.props.showModal();
        }else{
            this.setState({ error : "Article is required"})
        }
    }

    delete = (id) =>{
        axios.delete("artdetquots/" + id)
        this.props.showModal();
    }

    edit=()=>  {
        this.getArticles()
        this.categories()
        this.form()
    }

    form=()=>{
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
        
        this.setState({
            detailquotation_id:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.id   : "" : "",
            customer:  this.props.formData ? this.props.formData.user  ? this.props.formData.user.name : "" : "",
            tel:  this.props.formData ? this.props.formData.user ? this.props.formData.user.tel: "" : "",
            email:  this.props.formData ? this.props.formData.user ? this.props.formData.user.email: "" : "",
            price:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.price : "" : "",
            devise:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.devise : country.currency : country.currency,
            city:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.city : country.city : country.city,
            parentId:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.category ? this.props.formData.detailquotation.category.parentId : "" : "" : "",
            room:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.room : 1 : 1,
            bathroom:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.bathroom : "" : "",
            leaving_room:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.leaving_room : "" : "",
            adress:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.adress : "" : "",
            markId:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.mark ? this.props.formData.detailquotation.mark.name : this.props.formData.detailquotation.markId : "" : "",
            color1:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.color1 : "" : "",
            color2:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.color2 : "" : "",
             vitesse:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation. vitesse : "" : "",
            carburant:  this.props.formData ? this.props.formData.detailquotation ? this.props.formData.detailquotation.carburant : "" : "",
            error : "",
        })
    }



    render(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>Suggestion du devis de recherche  <small>{ this.state.customer } <a href={"tel:"+this.state.tel }>{ this.state.tel }</a> <a href={"mailto:"+this.state.email }>{ this.state.email }</a></small>   </Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                      
                        <div className="col-md-4 col-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Devis de </label>
                                <div className="position-relative mt-1">
                                    <select id="type" name="categoryId"   value={this.state.categoryId} onChange={this.handleInput} className="form-select">
                                        <option value="">...</option>
                                        {
                                            this.state.categories.map((category) => {
                                                return(
                                                    <option key={category.id} value={category.id}>{category.name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </div>
                            </div>
                        </div> 

                        <div className="col-md-4 col-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Ville</label>
                                <div className="position-relative mt-1">
                                    <select id="city"  onChange={this.handleInput} name="city"  value={this.state.city} className="form-select">
                                        <option value={this.state.city} key={this.state.city+"1"}> {this.state.city } </option>
                                    </select>
                                </div>
                            </div>
                        </div> 
                       
                        
                        {
                        this.state.parentId == 1 && (
                            <>
                                <div className="col-md-4 col-6 mt-2 mt-2">
                                    <div className="form-group has-icon-left">
                                        <label htmlFor="nom-id-icon">Chambre</label>
                                        <div className="position-relative mt-1">
                                            <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="room"  value={this.state.room} id="nom-id-icon" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-6 mt-2 mt-2">
                                    <div className="form-group has-icon-left">
                                        <label htmlFor="nom-id-icon">Salon</label>
                                        <div className="position-relative mt-1">
                                            <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="leaving_room"  value={this.state.leaving_room} id="nom-id-icon" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-6 mt-2 mt-2">
                                    <div className="form-group has-icon-left">
                                        <label htmlFor="nom-id-icon">Salle de bain</label>
                                        <div className="position-relative mt-1">
                                            <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="bathroom"  value={this.state.bathroom} id="nom-id-icon" />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        this.state.parentId == 2 && (
                            <>
                            <div className="col-md-4 col-6 mt-2 mt-2">
                                <div className="form-group has-icon-left">
                                    <label htmlFor="nom-id-icon">Marque</label>
                                    <div className="position-relative mt-1">
                                        <select id="mark" name="markId"   className="form-select">
                                            <option value="">{ this.state.markId}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-6 mt-2 mt-2">
                                <div className="form-group has-icon-left">
                                    <label htmlFor="nom-id-icon">Boite vitesse</label>
                                    <div className="position-relative mt-1">
                                        <select id=" vitesse"  onChange={this.handleInput} name=" vitesse"  value={this.state. vitesse} className="form-select">
                                            <option value={""} key={""}> {"..." } </option>
                                            <option value={"main-gauche"} key={"main-gauche"}> {"main-gauche" } </option>
                                            <option value={"main-droite"} key={"main-droite"}> {"main-droite" } </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-6 mt-2 mt-2">
                                <div className="form-group has-icon-left">
                                    <label htmlFor="nom-id-icon">Carburant</label>
                                    <div className="position-relative mt-1">
                                        <select id="carburant"  onChange={this.handleInput} name="carburant"  value={this.state.carburant} className="form-select">
                                            <option value={""} key={""}> {"..." } </option>
                                            <option value={"essance"} key={"essance"}> {"essance" } </option>
                                            <option value={"mazout"} key={"mazout"}> {"mazout" } </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </>
                        )
                    }

                        <div className="col-md-4 col-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Prix ({this.state.devise})</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="price"  value={this.state.price} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 col-12 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Votre suggéstion <span className="text-danger">*</span> {this.state.error} </label>
                                <div className="position-relative mt-1">
                                    <select id="articleId"  onChange={(select) =>{
                                        this.setState({
                                            articleId : select.target.value,
                                            img : select.target.img
                                        })
                                    }} name="articleId"  value={this.state.articleId} className="form-select">
                                        <option value={""} key={"1"}> .... </option>
                                        {
                                            this.state.articles.filter(dat => dat.category.id == this.state.categoryId).map((article) => {
                                                return(<option value={ article.id} key={ article.id } img={ article.id}> { article.category.name } { article.libelle } { article.price } { article.devise } ({ article.room } { article.room > 1 ? "chambres" :  "chambre"} ) </option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <h6 className="h6 font-monospace">Récentes suggéstions</h6>
                            {
                                (this.props.formData && this.props.formData) && (
                                        this.props.formData.detailquotation.articledetailquotations.map((articledetailquotation) =>{
                                        var nombre = articledetailquotation.article.price ;
                                        var arrondi = nombre*100;          
                                        arrondi = Math.round(arrondi); 
                                        arrondi = arrondi/100;
                                        return(
                                            <div className="col-md-4 col-6 col-6 overflow-hidden">
                                                <div className="card rounded rounded-3 shadow mt-1">
                                                    <div className="card-body pb-0 mb-0">
                                                        <div className={ articledetailquotation.status === "request" ? "ribbon1 rib1-warning" : "ribbon1 rib1-primary"}>
                                                            <span className={ articledetailquotation.status === "request" ? "text-white text-center rib1-warning" : "text-white text-center rib1-primary" }> <small className="font-10">{articledetailquotation.status}</small> </span>                                        
                                                        </div> 
                                                        <Link to={"/quotations/article/"+articledetailquotation.article.id}>
                                                            <img src={ articledetailquotation.article.images[0]?.image} className="img-thumbnail" alt={ articledetailquotation.article.images[0]?.image} style={{ width : 112 +"px", height : 90 + "px" }} />
                                                        </Link>
                                                        <div className=" m-1">
                                                            <div className="m-0 text-truncate">
                                                                <p className="font-11 text-truncate mb-0"><Link to={'/items/' + articledetailquotation.article.id } className="font-11 text-truncate">{ articledetailquotation.article.category ? articledetailquotation.article.category.name : ""} <small>{ articledetailquotation.article.libelle } (<span className="small">{ articledetailquotation.article.quantity }</span>)</small> </Link></p>
                                                            </div>
                                                        </div>
                                                        <div className=" ">
                                                            <p className="text-muted">
                                                                <span className="font-12 me-2  mx-1">{ arrondi > 0 ? arrondi : "" }{  arrondi > 0 ? articledetailquotation.article.devise  : "" }</span>
                                                                <button className="btn btn-outline-danger btn-sm mx-1 my-1" onClick={ () => this.delete(articledetailquotation.id)}> Annuler</button>
                                                            </p>
 
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                )

                            }
                        </div>

                        

                </Modal.Body>
                <Modal.Footer>
                
                    <button onClick={() => this.props.showModal()}  type="button" className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                    {
                        (user.id && this.props.formData)  ?
                        <button onClick={() => this.storeArtdetquot()} disabled={ this.state.btn } className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> suggérer</button>
                        : <Link to={"/login"} className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> suggérer</Link>
                    }
                </Modal.Footer>
            </Modal>


        </div>
        )
    }
}
export default ArtdetquotForm;
