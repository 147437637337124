import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import { Zoom } from 'react-slideshow-image';
import ItemData from "./ItemData";
import { withTranslation } from 'react-i18next';

class   Item extends Component{

    state = {
        id : "",
        items : [],
        category : {},
        loading : false
    }

    async items (){
        var items = await axios.get("items/dashboard/" + this.props.category.id + "/" + this.props.category.limit);
        if(items.status === 200){
            this.setState({
                items : items.data, 
                loading : true
            })
        }
    }

    componentDidMount(){
        this.setState({
            category : this.props.category
        })
        this.items();
    }
 
 
    render(){
        const { t } = this.props
        var auto = [true, false];
        var myArray = ['rib1-warning', 'rib1-danger', 'btn-outline-primary', 'bg-orangered', 'bg-warning', 'bg-danger']; 
        var color = myArray[(Math.random() * myArray.length) | 0]

        var  items = 
        this.state.items.slice(0, this.state.category.limit > 0 ? this.state.category.limit : 2 ).map((item) => {
            var proprietes = {
                duration: 500 * item.images.length,
                transitionDuration: 100 * item.images.length,
                infinite: true,
                indicators: true,
                arrows: false,
                autoplay : auto[(Math.random() * auto.length) | 0]
            }
            var image = <div className="slide-container">
                    <Zoom scale={0.1} {...proprietes}>
                    {
                        item.images.map((each, index) => <Link to={'/items/'+item.id } >
                            { 
                            this.state.category?.width > 0 ? 
                            <img key={index} src={each.image} alt={"CH DUTY TRUCK | " + this.state.category.name} className="img-thumbnail" style={{  width : this.state.category?.width +"px", height : this.state.category?.height + "px" }} /> 
                            : 
                            <img key={index} src={each.image} className="img-thumbnail mb-4" alt={"CH DUTY TRUCK | " + this.state.category.name} style={{ width : "100%"}} />}</Link>)
                    }
                    </Zoom>
                </div>
            return(
                <ItemData 
                    image={item.images.length > 0 ? image : <Link  to={"/items/" + item.id}><img src={'/img/background.png'} className="img-thumbnail" alt={ "CH DUTY TRUCK"}  style={{ width : this.state.category?.width +"px", height : this.state.category?.height + "px" }} /><br /><br /><br /></Link> }
                    classecontent={this.state.category.classecontent}
                    color={color}
                    item={item}
                    
                />
               
            )
        })

        return(
        <div className={this.state.items.length > 0 ? "row mt-3 mb-3" : "d-none row"}>
 
            <div className="mb-4 rounded rounded-3 col-12">
                <div className={ this.state.category.classe}>{ this.state.category.title }</div>
            </div>
            {
                items
            }
            <div className={ this.state.loading ? "text-center" : "d-none"}>
                <Link className="btn btn-primary text-decoration-none btn-sm m-2 bg-orangered " to={"/items/category/" + this.state.category.id}><i className="ti ti-activity"></i> { t("dashboard.voir_plus")} {this.state.category.name }</Link>
            </div>
        </div>
        )
    }
}
export default withTranslation()(Item);
