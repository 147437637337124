import axios from "axios";
import React, {Component} from "react";
import Loading from "../components/Loading";
import DetailArticle from "../components/DetailArticle";
  

class   PaymentVerify extends Component{

    state = {
        quantity : 1,
        periode : 1,
        quotation :{},
        loading : false
    }

    componentDidMount(){
        this.getQuotation();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getQuotation(){
        var quotation = await axios.get('quotations/' + this.props.match.params.id);
        if(quotation.status === 200)
        {
            quotation.data.detailquotations = quotation.data.detailquotation;
            
            this.setState({
                loading : true,
                quotation : quotation.data,
                quantity : quotation.data.detailquotations.quantity,
                periode : quotation.data.detailquotations.periode,
            })
        }
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){

        var now = new Date(this.state.quotation.createdAt);
        var today = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()

        now = new Date(this.state.quotation.updatedAt);
        var paiddate = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()

        now = new Date(this.state.quotation.datestart);
        var datestart = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()

        if(!this.state.quotation.datestart)
            datestart = "--";
            
        now = new Date(this.state.quotation.dateend);
        var dateend = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()
        if(!this.state.quotation.dateend)
            dateend = "--";

        const data = [ ];
        this.state.quotation.detailquotations && (
        this.state.quotation.detailquotation.article.images.map( (img, count) => {
            data.push({
                original : img.image,
                thumbnail : img.image,
            })
        })
        )
        
        return(
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="float-start">
                                <h5 className="page-title h5 fw-bold font-monospace m-2">Detail  { this.state.quotation ? this.state.quotation.detailquotations ? this.state.quotation.detailquotation.article.category ? this.state.quotation.detailquotation.article.category.name : "" : "" : ""}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !this.state.loading && ( <Loading />)
                }

                {
                    this.state.quotation.detailquotations && (
                    <div>
                    
                        { this.state.quotation.detailquotations && ( <DetailArticle detailArticle={this.state.quotation.detailquotations.article} /> ) }
                        
                        <div className="my-2">
                            <h5 className="page-title h5 fw-bold font-monospace my-4">Devis  { this.state.quotation ? this.state.quotation.detailquotations ? this.state.quotation.detailquotation.article.category ? this.state.quotation.detailquotation.article.category.name : "" : "" : ""}</h5>
                        </div>

                        <div className="row">
                            <div className="col-md-4 ">
                                <div className="card shadow bg-white mb-2">
                                    <div className="card-body">
                                        <div className={ this.state.quotation.status === "request" ? "ribbon1 rib1-warning" : this.state.quotation.status === "closed" ? "ribbon1 rib1-danger" : "ribbon1 rib1-primary"}>
                                            <span className={ this.state.quotation.status === "request" ? "text-white text-center rib1-warning" : this.state.quotation.status === "closed" ?  "text-white text-center rib1-danger"  : "text-white text-center rib1-primary" }>{ this.state.quotation > 0  ? this.state.quotation : this.state.quotation.detailquotation.article.type } </span>
                                        </div>
                                        <div  className="text-center p-1 rounded rounded-3 align-item-center">
                                            <img src={this.state.quotation.user.avatar} className="rounded-circle me-2 thumb-sm" /><span className="font-12">{ this.state.quotation.user.name }</span> <a href={"tel:" + this.state.quotation.user.tel}  className="font-12 text-decoration-none">{ this.state.quotation.user.tel }</a><br />
                                            <span>Paid</span> <span className={ this.state.quotation.paid > 0 ? "badge badge-soft-primary ms-2 font-13 fw-semibold mb-2" : "badge badge-soft-warning ms-2 font-13 fw-semibold mb-2" }>{ this.state.quotation.paid > 0 ? "Yes" : "Not" }</span>
                                        </div><hr />
                                        <div className="text-center p-1 rounded rounded-3 align-item-center">
                                            <ul className="list-unstyled border-0">
                                                <li className="mb-2"><i className="ti ti-flag text-success me-1"></i>{ this.state.quotation.detailquotation.country}</li>
                                                <li className="mb-2"><i className="ti ti-check text-primary me-1"></i>{ this.state.quotation.detailquotation.region}</li>
                                                <li className="mb-2"><i className="ti ti-check text-warning me-1"></i>{ this.state.quotation.detailquotation.city}</li>
                                            </ul>  
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 ">
                                <div className="card shadow bg-white mb-2">
                                    <div className="card-body">
                                        <div className={ this.state.quotation.status === "request" ? "ribbon1 rib1-warning" : this.state.quotation.status === "closed" ? "ribbon1 rib1-danger" : "ribbon1 rib1-primary"}>
                                            <span className={ this.state.quotation.status === "request" ? "text-white text-center rib1-warning" : this.state.quotation.status === "closed" ?  "text-white text-center rib1-danger"  : "text-white text-center rib1-primary" }>{ this.state.quotation > 0  ? this.state.quotation : this.state.quotation.detailquotation.article.type } </span>
                                        </div>
                                        <div  className="text-center p-1 rounded rounded-3 align-item-center">
                                            <span className="header-title">{ this.state.quantity }</span>
                                            <span className="header-title ms-2">{ this.state.quotation ? this.state.quotation.detailquotation.article.libelle : "" }</span><br />
                                            
                                            <span className="header-title me-2 text-primary mt-2 mx-1">{  this.state.quotation.paid === 0 ?  this.state.quotation.detailquotation.article.price * this.state.quantity : this.state.quotation.detailquotation.price  } {  this.state.quotation.status === "request" ?  this.state.quotation.detailquotation.devise : this.state.quotation.detailquotation.article.devise }</span>
                                            <br /> <span className="badge badge-outline-secondary mt-2 mx-1">{ this.state.periode } { this.state.quotation.detailquotation.unite }</span> 
                                        </div><hr />
                                        <div className="text-center p-1 rounded rounded-3 align-item-center">
                                            <div className="text-mutued">
                                                <div className="row">
                                                    <div className="col-6">
                                                    Quantité
                                                        <div className="form-group">
                                                            <input id="quantity" type="number" className="form-control border border-1 form-control-sm" value={this.state.quantity} name="quantity" onChange={this.handleInput}  placeholder="Qté" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        Période
                                                        <div className="form-group">
                                                            <input id="periode" name="periode" type="number" className="form-control border border-1 form-control-sm" onChange={this.handleInput} value={ this.state.periode }  placeholder="Periode" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <textarea className="form-control mt-2 form-control-sm border border-1" name="description" value={this.state.description ? this.state.description : this.state.quotation.detailquotation.description}></textarea>
                                             </div> 
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 ">
                                <div className="card shadow bg-white mb-2">
                                    <div className="card-body">
                                        <div className="mx-auto">
                                            <div className={ this.state.quotation.status === "request" ? "ribbon1 rib1-warning" : this.state.quotation.status === "closed" ? "ribbon1 rib1-danger" : "ribbon1 rib1-warning"}>
                                                <span className={ this.state.quotation.status === "request" ? "text-white text-center rib1-warning" : this.state.quotation.status === "closed" ?  "text-white text-center rib1-danger"  : "text-white text-center rib1-warning" }>{ this.state.quotation.detailquotation.article.type } </span>
                                            </div>
                                            <div className="text-center"> Date paiement { this.state.quotation.invoice && (<span><span className="text-muted ti ti-calendar font-16"></span> { paiddate }</span> )}</div>
                                            { this.state.quotation.invoice && (<div className="text-center text-primary h6 mx-3"><a target={"_blank"} href={ this.state.quotation.status == "paid" ? this.state.quotation.invoicelink : "#"} className="badge badge-soft-primary ms-2 font-13 fw-semibold mb-2 text-primary"> <span className=" text-primary ti ti-file font-18"></span> Voir la facture <span className="d-none"> N°{ this.state.quotation.invoice }</span> </a></div> ) }<hr />
                                            
                                            <div className="text-center">
                                                Du { datestart } au  { dateend }
                                            </div><hr />

                                            <div className="text-center">
                                                Paid with <span className="border border-1 border-warning rounded rounded-3 px-2">{ this.state.quotation.payment_method }</span><br />
                                                <div className="font-monospace">{ this.state.quotation.card_number } { this.state.quotation.exp_month > 12 ? " / " + this.state.quotation.exp_month : ""  }</div>
                                                <span className={ this.state.quotation.status === "request" ? "badge badge-soft-warning ms-2 font-13 fw-semibold my-1" : this.state.quotation.status === "closed" ? "badge badge-soft-danger ms-2 font-13 fw-semibold my-1" : "badge badge-soft-primary ms-2 font-13 fw-semibold my-1"}>{ this.state.quotation.status}</span>
                                            </div>
                                            <div>
                                                {
                                                    (this.state.quotation.paid === 1 && this.state.quotation.status == "checking" ) && (
                                                        this.handleCheck("admin") == true && (
                                                            this.handleCheck("valider paiement") == true && (
                                                                <div className="text-center">
                                                                    <button className="btn btn-primary btn-sm m-1" onClick={ () => {
                                                                        axios.put("quotations/statut/" + this.state.quotation.id, {})
                                                                        window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + "/repports");
                                                                    }}>Valider paiement</button>
                                                                </div>
                                                            )
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }
            </div>
        )
    }
}
export default PaymentVerify;
