import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   ModeleForm extends Component{

    state = {
        id : "",
        ref_user : null,
        ref_user_update : null,
        name : "",
        markId : null,
        error : "",

        title : "",
        modeles : [],
        marks : [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    storeModele = async (e) => {
        e.preventDefault();

        let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        
        let data = {
            ref_user : this.state.ref_user ? this.state.ref_user :  user.id,
            ref_user_update : user.id,
            name : this.state.name,
            markId : this.state.markId
        }
        
        let request = this.state.id ? axios.put('modeles/'+this.state.id, data) : axios.post('modeles', data);
        request.then((response) =>{
            this.props.showModal(response.data?.id);
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg,
            })
            toast(error.response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }) 
    }

    async getmarks () {
        let marks = await axios.get("marks");
        if(marks.status == 200){
            this.setState({
                marks : marks.data
            })
        }
    }

    
    edit=()=>  {
        this.form()
        this.getmarks()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            ref_user: this.props.formData ? this.props.formData.ref_user : "",
            name: this.props.formData ? this.props.formData.name : "",
            markId: this.props.formData ? this.props.formData.mark?.id : null,
            title : this.props.formData ? "Modifier le model" : "Ajouter le model",
            error : "",
        })
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="small"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.storeModele} className="row">
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        
                        <div className="col-md-12 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Marque</label>
                                <div className="position-relative mt-1">
                                    <select id="type" name="markId"   value={this.state.markId} onChange={this.handleInput} className="form-select">
                                        <option value="">...</option>
                                        {
                                            this.state.marks.map((mark) => {
                                                return(
                                                    <option key={mark.id} value={mark.id}>{mark.name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Model</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col mt-2 mt-2">
                            <div className=" pt-2 text-end">
                                <button onClick={ () => this.props.showModal() }  type="button" className="btn btn-outline-secondary btn-sm me-3"><i className="ti ti-corner-down-left"></i> Fermer</button>
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" type='submit' >Sauvegarder</button>
                            </div>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>


        </div>
        )
    }
}
export default ModeleForm;
