import axios from "axios";
import React, {Component} from "react";
import Loading from "../components/Loading";
import SocietyForm from "../components/SocietyForm";

class Society extends Component{

    state = {
        isModal : false,
        isModalArchive : false,
        loading : false,
        formData : "",
        create : false,
        society : {},
    }

    componentDidMount(){
        this.getSocieties();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getSocieties(){
        this.setState({ society : {}});
        var society = JSON.parse(localStorage.getItem('society')) ? JSON.parse(localStorage.getItem('society')) : {};
        var societ = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
      if(societ){
            var society = await axios.get('societies/' + societ);
            if(society.status === 200)
            {
                this.setState({society : society.data, loading : true}) 
            }
        }
        else{
            this.setState({ loading : true})
        }
    }

    showModal = (society =  false) => {
        this.setState({
            create : society,
            isModal : !this.state.isModal,
            formData : {},
        })
        this.getSocieties()
    }

    delete = (society) =>{
        axios.delete("societies/" + society).then((response) => {
            this.getSocieties()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        
        return(
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="float-start">
                            </div>
                                {
                                    this.handleCheck("ajouter societe") == true && (
                                        <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                            <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(true)}><i className="ti ti-plus"></i> Nouveau agence</button>
                                        </div>
                                    )
                                }
                        </div>
                        { 
                            !this.state.loading && (<Loading /> )
                        }
                        { 
                            this.state.loading && (
                                <div>
                                    <div className={ this.state.create ? "d-none" : "page-title h4 font-weight"}>
                                        <img src={ this.state.society.logo}  className="rounded-circle img-fluid me-2 rounded rounded-4"  width={(  this.state.society.logo) && ("150px")} />
                                        <span className="h3">{ this.state.society.name }</span> <span className="page-title font-weight text-orangered">{ this.state.society.short_name }</span>
                                    </div>
                                    <div className={ this.state.create ? "page-title h4 font-weight text-orangered" : "d-none"}>Nouveau</div>
                                </div>
                            )
                        }
                    </div>
                    {
                        (this.state.loading == true || this.state.isModal) && (
                            <SocietyForm  formData={ this.state.society} create={this.state.create} showModal={this.showModal}  />
                        )
                    }
                    
                    
                </div>
                
            

        </div>
        )
    }
}
export default Society;