import React from "react";
import ReactLoading from "react-loading";

export default function LoadingD() {
return (
	<div  className="row mt-4 pt-4" >
		<div className="col-4  mt-4 pt-4"></div>
		<div className="col-8 mt-4 pt-4">
	 		<ReactLoading type="spokes" color="#ff6900" height={120} width={35} /> <span className="text-orangered"> Loading...</span>
		</div>
	</div>
);
}
