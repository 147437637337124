import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import DetailArticle from "../components/DetailArticle";
  
class   PaymentVerifyList extends Component{

    state = {
        quantity : 1,
        periode : 1,
        article :{},
        loading : false
    }

    componentDidMount(){
        this.getQuotation();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getQuotation(){
        
        var quotation = await axios.get('quotations/article/' + this.props.match.params.id);
        if(quotation.status === 200)
        {
            this.setState({
                loading : true,
                article : quotation.data,
            })
        }
    }


    render(){

        var now = new Date(this.state.article.createdAt);
         
        
        return(
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="float-start">
                                <h5 className="page-title h5 fw-bold font-monospace m-2">Detail des devis effectués sur { this.state.article ? this.state.article.category ? this.state.article.category.name : "" : ""}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !this.state.loading && ( <Loading />)
                }

                    <div>
                    
                        { this.state.article && ( <DetailArticle detailArticle={this.state.article} /> ) }
                        
                        <div className="card shadow mb-3 rounded rounded-3">
                            <div className="card-body">

                            
                                <div className="my-2">
                                    <h5 className="page-title h5 fw-bold font-monospace my-4">Devis { this.state.article ? this.state.article.category ? this.state.article.category.name : "" : ""}</h5>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>Montant</th>
                                                <th>Payé</th>
                                                <th>Période</th>
                                                <td>Date</td>
                                                <th>Client</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            (this.state.article.detailquotations ?? []).map((detailquotation) => {
                                                now = new Date(detailquotation.quotation.datestart);
                                                var datestart = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()+ ":" + now.getSeconds()

                                                if(!detailquotation.quotation.datestart)
                                                    datestart = "--";
                                                    
                                                now = new Date(detailquotation.quotation.dateend);
                                                var dateend = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()+ ":" + now.getSeconds()
                                                if(!detailquotation.quotation.dateend)
                                                    dateend = "--";
                                                return(
                                                    <tr key={detailquotation.id}>
                                                        <td className="text-truncate">{ detailquotation.price} { detailquotation.devise }</td>
                                                        <td><span className={ detailquotation.quotation.paid > 0 ? "badge badge-soft-primary ms-2 font-13 fw-semibold mb-2" : "badge badge-soft-warning ms-2 font-13 fw-semibold mb-2" }>{ detailquotation.quotation.paid > 0 ? "Yes" : "Not" }</span></td>
                                                        <td>{ detailquotation.periode} { detailquotation.unite }</td>
                                                        <td className="text-truncate"> { datestart } au { dateend }</td>
                                                        <td className="text-truncate"><img src={ detailquotation.quotation.user.avatar } alt={  detailquotation.quotation.user.name } className="rounded-circle thumb-xs me-1" />  { detailquotation.quotation.user.name}</td>
                                                        <td><Link to={"/quotations/" + detailquotation.quotation.status + "/" + detailquotation.quotation.id}> <span className={ detailquotation.quotation.status === "closed" ? "badge badge-soft-success font-13 fw-semibold mb-2" : "badge badge-soft-primary font-13 fw-semibold mb-2"}>{ detailquotation.quotation.status}</span></Link></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        
                    </div>
            </div>
        )
    }
}
export default PaymentVerifyList;
