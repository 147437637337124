import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

class   CategoryForm extends Component{

    state = {
        id : "",
        ref_user : null,
        ref_user_update : null,
        name : "",
        title : "",
        classe : "",
        height : "",
        width : "",
        classecontent : "",
        limit : "",
        dataImage : "",
        categoryId : null,
        error : "",

        titlepage : "",
        categories : [],
        btn : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleInputFile = (e) => {

        this.setState({
            dataImage : e.target.files[0],
        })
    }


    storeCategory = async (e) => {
        e.preventDefault();
        this.setState({
            btn : !this.state.btn
        })

        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};

        let formData = new FormData()
        formData.append('files', this.state.dataImage)
        formData.append('ref_user', this.state.ref_user ? this.state.ref_user :  user.id)
        formData.append('ref_user_update', user.id)
        formData.append('name', this.state.name)
        formData.append('title', this.state.title)
        formData.append('classe', this.state.classe)
        if(this.state.height > 0)
        formData.append('height', this.state.height)
        if(this.state.width > 0)
        formData.append('width', this.state.width)
        formData.append('classecontent', this.state.classecontent)
        if(this.state.limit > 0)
        formData.append('limit', this.state.limit)


        if(this.state.categoryId)
            formData.append('categoryId', this.state.categoryId ? this.state.categoryId : null)

        
        var request = this.state.id ? 'categories/' + this.state.id  : 'categories' ;
        
        const response = await fetch(process.env.REACT_APP_APICHDUTYTRUCK + '/' + request, {
            method: this.state.id ? "PUT" : "POST",
            headers : { "x-access-token" : user.refresh_token ? user.refresh_token : "" },
            body: formData 
        })
        
        if (response.status === 200)
        {
            this.setState({
                error : "saved success",
                btn : !this.state.btn
            })
            let val = await response.json()
            console.log(val);
            toast(val?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(val.data?.id);
        }
        else{
            var error = await response.json();
            this.setState({
                error : error.msg ? error.msg :  "error when loading",
                btn : !this.state.btn
            })
            toast(error.msg ? error.msg :  "error when loading", { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }
        
    }

    async categories () {
        var categories = await axios.get("categoriesparents/1");
        if(categories.status == 200){
            this.setState({
                categories : categories.data
            })
        }
    }
    
    edit=()=>  {
        this.form()
        this.categories()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            ref_user: this.props.formData ? this.props.formData.ref_user : "",
            name: this.props.formData ? this.props.formData.name : "",
            title: this.props.formData ? this.props.formData.title : "",
            classe: this.props.formData ? this.props.formData.classe : "",
            classecontent: this.props.formData ? this.props.formData.classecontent : "",
            limit: this.props.formData ? this.props.formData.limit : "",
            height: this.props.formData ? this.props.formData.height : "",
            width: this.props.formData ? this.props.formData.width : "",
            categoryId: this.props.formData ? this.props.formData.category?.id : null,
            titlepage : this.props.formData ? "Modifier la categorie" : "Ajouter la categorie",
            error : "",
        })
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.titlepage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.storeCategory} className="row">
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Groupe</label>
                                <div className="position-relative mt-1">
                                    <select id="type" name="categoryId"   value={this.state.categoryId} onChange={this.handleInput} className="form-select">
                                        <option value="">...</option>
                                        {
                                            this.state.categories.filter(data => !data.categoryId).map((category) => {
                                                return(
                                                    <option key={category.id} value={category.id}>{category.name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Catégorie</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Titre</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="title"  value={this.state.title} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Classe</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="classe"  value={this.state.classe} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Classe content</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="classecontent"  value={this.state.classecontent} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Limit</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="limit"  value={this.state.limit} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Height</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="height"  value={this.state.height} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Width</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="width"  value={this.state.width} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="">
                                <label>Image</label>
                                <input type={"file"} className="form-control form-control-sm" name="file" onChange={this.handleInputFile} />
                            </div>
                            
                            <div className=" pt-2 text-center">
                                <span className="text-danger"> { this.state.errorImg }</span><br />
                                <button onClick={ () => this.props.showModal()} className="btn btn-outline-secondary btn-sm me-3" type="button"><i className="ti ti-corner-down-left"></i> Fermer</button>
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" disabled={ this.state.btn }  type='submit' >Sauvegarder{ !this.state.btn ? <i className="ti ti-check"></i> : <ReactLoading type="spokes" color="red" className="float-end" height={15} width={15} />}</button>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>


        </div>
        )
    }
}
export default CategoryForm;
