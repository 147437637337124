import React, {Component} from "react";
import axios from "axios";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class Search extends Component{

    state = {
    }

    componentDidMount(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    render(){ 
        const { t } = this.props;


        return(
            <div>
                <div className="row mt-4 pt-4">
                
                <div className="page-title-box">
                    <div className="h5 fw-bold font-monospace text-orangered">{ t("about.title")} </div>
                    
                </div>

                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className={ "card shadow "}>
                            <div className="card-body">
                                <div className="h5 fw-bold">{ t("about.selection")}</div>
                               
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className={ "card_"}>
                            <div className="card-body">
                                <div className="font-monospace text-orangered fw-bolder p-2">{ t("about.selection1")}</div>
                               
                            </div>
                        </div>
                    </div>

                    <div className={ "col-md-5 "}>
                        <div className="card">
                            <div className="card-body row">
                                <div>{ t("about.selection2")}</div>
                               
                            </div>
                        </div>
                    </div>

                    <div className={ "col-md-10 "}>
                        <div className="card_">
                            <div className="card-body row">
                                <div>{ t("about.selection3")}</div>
                               
                            </div>
                        </div>
                    </div>

                    <div className={ "col-md-12 "}>
                        <div className="card_">
                            <div className="card-body row">
                                <div className="font-monospace text-dark fw-bolder">{ t("about.selection4")}</div>
                               
                            </div>
                        </div>
                    </div>

                    <div className={ "col-md-4  "}>
                        <div className="card_ shadow">
                            <div className="card-body row">
                                <div>{ t("about.selection5")}</div>
                               
                            </div>
                        </div>
                    </div>

                    <div className={ "col-md-7 "}>
                        <div className="card_">
                            <div className="card-body row">
                                <div>{ t("about.selection6")}</div>
                               
                            </div>
                        </div>
                    </div>

                    <div className={ "col-md-7 "}>
                        <div className="card_">
                            <div className="card-body row">
                                <div >{ t("about.selection7")}</div>
                               
                            </div>
                        </div>
                    </div>

                    <div className={ "col-md-4 "}>
                        <div className="card_">
                            <div className="card-body row">
                                <div  className=" text-dark fw-bolder">{ t("about.selection7")}</div>
                               
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            </div>
        )
    }
}
export default withTranslation()(Search);