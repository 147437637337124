import React, {Component} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DevisForm from "../components/DevisForm";
import { withTranslation } from 'react-i18next';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import {MDBCarousel, MDBCarouselInner,MDBCarouselItem,MDBCarouselElement, MDBCarouselCaption,} from 'mdb-react-ui-kit';
import Search from "../components/Search";
import Pub from "./Pub";
import Item from "../components/Item";
import InfoStart from "./InfoStart";

class Dashboard extends Component{

    state = {
        isModal : false,
        search : false,
        cat : "camion",
        btn : true,
        country : " chine",
        region : "",
        city : "",
        adress : "",
        permissions : [],
        categories : [],
        regions : [],
        cities : []
    }

    componentDidMount(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
      
        if(user.id){
            this.permission();
        }
        this.categories();
        this.datacountry();
        
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async datacountryIp(){
        try{
            var ip = await axios("https://httpbin.org/ip");
            if(ip.status === 200)
            {
                var country = await axios.get('country/' + ip.data.origin);
                if(country.status === 200)
                {
                    localStorage.setItem("country", JSON.stringify(country.data) );
                }
            }
        }catch(error){
            console.log(error);
        }
    }

    async datacountry(){
        try{
            var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
            this.setState({
                country : country.country, //"Burundi"
                countryCode : country.countryCode,
                region : country.regionName,
                city : country.city,
            });
            
            this.dataregions(country.countryCode ? country.countryCode?.toLowerCase() : null);
            this.datacities(country.regionName);
    
             
        }catch(error){
            console.log(error);
        }
    }

    async dataregions(cod = null){
        var code = cod != null ? cod : this.state.countryCode;
        var regions = await axios.get("countries/" +  code);
    
        if(regions.status === 200){
            this.setState({
                regions : regions.data ? regions.data : []
            })
            this.datacities(this.state.region);
        }
    }
    
    async datacities(regio = null){
        if(this.state.countryCode){
            var region = regio != null ? regio : this.state.region;
            var cities = await axios.get("countries/" + this.state.countryCode +"/"+ region);
            if(cities.status === 200){
                this.setState({
                    cities : cities.data
                })
            }
        }
    }

    handleInputRegion = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if(e.target.value){
            this.datacities(e.target.value);
        }
    }


    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async categories(){
        var categories = await axios.get("categoriesdashboards");
        if(categories.status === 200){
            this.setState({
                categories : categories.data,
            })
        }
    }

    async permission(){
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions"))  : [] ;
        
        if(permissions.length == 0)
        {
            var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
            var permissionDate = await axios.get("permissions/user/" + user.id);
            
            if(permissionDate.status == 200)
            {
                this.setState({
                    permissions : permissionDate.data.permissions
                });
                localStorage.setItem("permissions", JSON.stringify(permissionDate.data.permissions));
            }
        }
    }

    showModal = (val = false) => {
        this.setState({
            isModal : !this.state.isModal,
            search : val
        })
    }

     handleCheck(val) {
        this.permission();
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : this.state.permissions;
        return permissions.some(item => val === item.name)
    }


    render(){ 
        const { t } = this.props
        const data = [];
        
        this.state.categories.map( (item) => {
            data.push({ image : item.image, caption : item.name })
            if(this.state.categories.length === 1 )
                data.push({ image : item.image, caption : item.name })
        })
        
        var items = 
        this.state.categories.map((category) => {
            return(
                <Item category={category} />
            )
        })

        let firsts = [
            { id : 1, image : "/first/1.jpg"},
            { id : 2, image : "/first/2.jpg"},
            { id : 3, image : "/first/3.jpg"},
            { id : 4, image : "/first/4.jpg"},
            { id : 5, image : "/first/5.jpg"},
            { id : 6, image : "/first/6.jpg"},
            { id : 7, image : "/first/7.jpg"},
            { id : 8, image : "/first/8.jpg"},
            { id : 9, image : "/first/9.jpg"},
            { id : 10, image : "/first/10.jpg"},
            { id : 11, image : "/first/11.jpg"},
            { id : 12, image : "/first/12.jpg"},
            { id : 13, image : "/first/13.jpg"},
            { id : 14, image : "/first/14.jpg"},
            { id : 15, image : "/first/15.jpg"},
            { id : 16, image : "/first/16.jpg"},
            { id : 17, image : "/first/17.jpg"},
            { id : 18, image : "/first/18.jpg"},
            { id : 19, image : "/first/19.jpg"},
            { id : 20, image : "/first/20.jpg"},
            { id : 21, image : "/first/21.jpg"},
            { id : 22, image : "/first/22.jpg"},
            { id : 23, image : "/first/23.jpg"},
            { id : 24, image : "/first/24.jpg"},
            { id : 25, image : "/first/25.jpg"},
            { id : 26, image : "/first/26.jpg"},
            { id : 27, image : "/first/27.jpg"},
            { id : 28, image : "/first/28.jpg"},
            { id : 29, image : "/first/29.jpg"},
            { id : 30, image : "/first/30.jpg"},
        ]

        return(
            <div>
            <div>
                <h1 className="h5 fw-bold text-center mt-4 text-orangered font-monospace">{ t("dashboard.trouver_rapidement_un_logement")}</h1>
                <div className="font-monospace text-center">{ t("dashboard.trouver_rapidement_un_logement_description")}</div>
            </div>
            <div className="row d-flex justify-content-center overflow-hidden mt-4 border border-1 bg-white ">
            {/* <div className="row  mt-4 border border-2 bg-white "> */}

                <div className="col-md-3 overflow-hidden hide-phone">
                    <div className="font-16 fw-bolder font-monospace text-center mt-4">{ t("dashboard.speciale_marque")}</div>
                    <div className="">
                        {
                            this.state.categories.map((category, index) => {
                                return(
                                    <div className={ index < 5 ? "my-2" : "d-none"}><Link to={"/items/category/" + category.id } className="text-decoration-none"><img src={category.image} className="rounded img-fluid rounded-10" width={"60"}  alt={"CH DUTY TRUCK | " + category.name} /> <span className="border border-1 p-1 rounded rounded-2 text-dark text-orangered-hover">{ category.name}</span></Link></div>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{ padding : "5px 5px" }} className="col-md-6 overflow-hidden">
                    
                    <MDBCarousel showControls showIndicators>
                        <MDBCarouselInner  className="">
                        {
                            this.state.categories.map( (item, count) => {
                                count += 1;
                                return(
                                    <MDBCarouselItem className={ count === 1 ? 'active text-white rounded rounded-5 overflow-hidden' : "text-white rounded rounded-5 overflow-hidden"}>
                                        <Link to={"/items/category/" + item.id } className="text-white "><MDBCarouselElement src={ item.image } alt={ item.name} className="dashSlide" /></Link>
                                        <MDBCarouselCaption className="display-sm d-block d-sm-block">
                                            <h4 className="text-white"><Link to={"/items/category/" + item.id } className="text-white"  style={{  backgroundColor : "#ff2000", paddingLef : 4, paddingRight : 4 }}>{ item.name}</Link></h4>
                                        </MDBCarouselCaption>
                                    </MDBCarouselItem>
                                )
                            })
 

                        }
                        </MDBCarouselInner>
                    </MDBCarousel>
                </div>
                <div className="col-md-3 overflow-hidden text-center hide-phone">
                    <div className="font-16 fw-bolder font-monospace text-center mt-4">{ t("dashboard.notre_suggestion")}</div>
                    <div className="">
                        {
                            this.state.cities.length > 0 && (
                                (this.state.cities ?? []).map((city, index) => {
                                    return(
                                        <div className={ index < 5 ? "my-2" : "d-none"} key={city.city}><Link to={"/cities/" + city.city}><span className="border border-1 p-1 rounded rounded-2 text-dark text-orangered-hover">{ city.city}</span></Link></div>
                                    )
                                })
                            )
                        }
                    </div>
                </div>
            </div>{/*end col*/}

                <div className="row">
                
                    <div className="page-title-box mt-5">
                        <div className="mt-5 font-16 fw-bolder font-monospace text-center">{ t("dashboard.la_vraie_direction_du_logement_locatif_pour_vous")}</div>
                        {/* <div className="btn-toolbar mb-2 mb-md-0 d-end float-end text-end">
                            <button type="button" className="btn btn-sm btn-outline-primary  me-3 mt-1" onClick={() => this.setState({ btn : !this.state.btn})}><i className="ti ti-search font-18"></i> { t("formulaire.trouvez_facilement")}</button>
                            <button type="button" className="btn btn-sm btn-outline-primary mt-1" onClick={() => this.showModal()}><i className="ti ti-plus font-18"></i>  { t("formulaire.faire_un_devis")}</button>
                        </div> */}
                    </div> 
                
                    {/* <div className={ this.state.btn ? "my-2 card shadow mb-5" : "my-2 card shadow mb-5 d-none"}>
                        <Search />
                    </div> */}
                        
                    <div>
                        <InfoStart dashboard={true} />
                    </div>

                    <div className="mt-4 pt-4   d-flex justify-content-center">
                        <div className="col-md-10">
                            <Pub dashboard={true} />
                        </div>
                    </div>

                    <div className="mt-5">
                        <h1 className="h5 text-center mb-4">{ t("dashboard.explorez_les_plus_populaires")}</h1>
                        { items }
                    </div>

                    <div>
                        <div style={{ padding : "5px 5px", marginTop : 50, marginBottom : 50 }} className="col-12 overflow-hidden">
                            
                            <MDBCarousel showControls showIndicators>
                                <MDBCarouselInner  className="">
                                {
                                    firsts?.map( (item, count) => {
                                        count += 1;
                                        return(
                                            <MDBCarouselItem className={ count === 1 ? 'active text-white rounded rounded-5 overflow-hidden' : "text-white rounded rounded-5 overflow-hidden"}>
                                                <Link to={"/items/category/" + item.id } className="text-white "><MDBCarouselElement src={ item.image } alt={ item.image } className="" /></Link>
                                                <MDBCarouselCaption className="display-sm d-block d-sm-block">
                                                    {/* <h4 className="text-white"><Link to={"/items/category/" + item.id } className="text-white"  style={{  backgroundColor : "#ff2000", paddingLef : 4, paddingRight : 4 }}>{ item.image }</Link></h4> */}
                                                </MDBCarouselCaption>
                                            </MDBCarouselItem>
                                        )
                                    })
                                }
                                </MDBCarouselInner>
                            </MDBCarousel>
                        </div>
                    </div>
                    
       
                </div>
                <DevisForm
                    isModal={this.state.isModal}
                    search={ this.state.search}
                    showModal={this.showModal}
                />

            <div >
                <FloatingWhatsApp 
                    phoneNumber={ process.env.REACT_APP_WHATSAPP }
                    accountName="CH DUTY TRUCK"
                    avatar="/logo192.png"
                    chatMessage ={ t("dashboard.whatsapp") }
                    placeholder = "type text..."
                    statusMessage = { t("dashboard.votre_service") }
                    buttonStyle={{ height:40+"px", width:40+"px", padding : 0 +"px", margin: 0 +"px"}}
                />
            </div>

            </div>
        )
    }
}
export default  withTranslation()(Dashboard);