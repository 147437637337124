import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";

class   Repport extends Component{

    state = {
        quantity : 1,
        periode : 1,
        repports :[],
        loading : false
    }

    componentDidMount(){
        this.getQuotation();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getQuotation(){
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
      var society = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
      
        var quotation = await axios.get('repports/' + society);
        if(quotation.status === 200)
        {
            this.setState({
                loading : true,
                repports : quotation.data,
            })
        }
    }


    render(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};

        return(
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="float-start">
                                <h5 className="page-title h5 fw-bold font-monospace m-2">Rapport des réservations effectuées</h5>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !this.state.loading && ( <Loading />)
                }
                
                        
                        <div className="card shadow mb-3 rounded rounded-3">
                            <div className="card-body">


                                <div className="table-responsive">
                                    <table className="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>Article</th>
                                                <th>Montant</th>
                                                <th>Payé</th>
                                                <th>Période</th>
                                                <td>Date</td>
                                                <th>Client</th>
                                                {/* <th></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.repports.map((repport) => {
                                                
                                                var now = new Date(repport.datestart);
                                                var datestart = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()+ ":" + now.getSeconds()

                                                if(!repport.datestart)
                                                    datestart = "--";
                                                    
                                                now = new Date(repport.dateend);
                                                var dateend = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()+ ":" + now.getSeconds()
                                                if(!repport.dateend)
                                                    dateend = "--";
                                                return(
                                                    <tr key={repport.id}>
                                                        <td className="text-truncate"><Link to={"/quotations/" + repport.status + "/" + repport.id} className="text-primary">{ repport.detailquotation.article.category.name} <small>{ repport.detailquotation.article.libelle } ({repport.detailquotation.quantity})</small></Link></td>
                                                        <td className="text-truncate">{ repport.detailquotation.price} { repport.detailquotation.devise }</td>
                                                        <td><span className={ repport.paid > 0 ? repport.status == "paid" ? "badge badge-soft-primary ms-2 font-13 fw-semibold mb-2" : "badge badge-soft-danger ms-2 font-13 fw-semibold mb-2" : "badge badge-soft-warning ms-2 font-13 fw-semibold mb-2" }>{ repport.paid > 0 ? repport.status : "Not" }</span></td>
                                                        <td>{ repport.detailquotation.periode} { repport.detailquotation.unite }</td>
                                                        <td className="text-truncate"> { datestart } au { dateend }</td>
                                                        <td className="text-truncate"><img src={ repport.user.avatar } alt={  repport.user.name } className="rounded-circle thumb-xs me-1" />  { repport.user.name}</td>
                                                        {/* <td><Link to={"/quotations/" + repport.status + "/" + repport.id}><span className={ repport.status === "closed" ? "badge badge-soft-success font-12 fw-semibold mb-2" : "badge badge-soft-primary font-12 fw-semibold mb-2"}>{ repport.status}</span></Link></td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        
            </div>
        )
    }
}
export default Repport;
