import axios from "axios";
import React, {Component} from "react";
import CategoryForm from "../components/CategoryForm";
import Loading from "../components/Loading";
import { toast } from "react-toastify";

class   Category extends Component{

    state = {
        isModal : false,
        formData : null,
        categories : [],
        loading : false,
    }

    componentDidMount(){
        this.getCategories();
    }

    async getCategories(){
        this.setState({
            loading : false
        })
        var categories = await axios.get('categories');
        if(categories.status === 200)
        {
            this.setState({categories : categories.data, loading : true})
        }
    }

    showModal = (category = {}) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : category,
        })
        this.getCategories()
    }

    delete = (category) =>{
        axios.delete("categories/" + category).then((response) => {
            this.getCategories()
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var count =1, categories =
        this.state.categories.map((category) => {
            return(
                <tr key={category.id}>
                    <td>{count++}</td>
                    <td><img src={ category.image } className="rounded-circle thumb-sm rounded-3" style={{ height:50+"px" }} alt={ category.image} />{ category.name }</td>
                    <td>{ category.category != null ? category.category.name : "" }</td>
                    <td>{ category.title  }</td>
                    <td>{ category.user != null ? <span><img src={category.user.avatar} className="rounded-circle thumb-sm" />{ category.user.name}</span> : "" }</td>
                    <td className="text-end">
                        {
                            (this.handleCheck("modifier categorie") == true ) && (
                        <a href="#" onClick={ () => this.showModal(category)}><i className="ti ti-edit text-primary font-22 me-3" ></i></a>
                        )}
                        {
                        (this.handleCheck("supprimer categorie") == true ) && (
                            <a href="#" onClick={() => this.delete(category.id)}><i className="ti ti-trash text-danger font-22"></i></a>
                        )}
                    </td>
                </tr>
            )
        })
        return(
          <div>
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                          <h4 className="page-title">Catégories</h4>
                          </div>
                              {
                                  this.handleCheck("ajouter categorie") == true && (
                                      <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                          <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> Nouvelle catégorie</button>
                                      </div>
                                  )
                              }
                      </div>
                      {
                          !this.state.loading && (<Loading /> )
                      }
                </div>
                <div className="col-12">
                    <div className="card shadow bg-white mb-2">
                        <div className="card-header">
                            <h4 className="card-titl">Liste des catégories</h4>
                            <p className="text-muted mb-0">
                                <small>Effectuer plus des opérations sur les catégories</small>
                            </p>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Catégorie</th>
                                            <th>Grande catégorie</th>
                                            <th>Titre</th>
                                            <th>Actionnaire</th>
                                            <th className="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { categories }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

              </div>

              <CategoryForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Category;
