import axios from "axios";
import { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class Search extends Component{

    state = {
        country : "",
        region : "",
        city : "",
        adress : "",
        cat : process.env.REACT_APP_CATEGORY_ID,
        countries : [],
        regions : [],
        cities : [],
        categorieList : [],
    }


    componentDidMount(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
      
        this.refreshToken();
        this.datacountry();
        this.categorieList();
        
        if(!country.country)
            this.datacountryIp();
    }

    async datacountryIp(){
        try{
            var ip = await axios("https://httpbin.org/ip");
            if(ip.status === 200)
            {
                var country = await axios.get('country/' + ip.data.origin);
                if(country.status === 200)
                {
                    localStorage.setItem("country", JSON.stringify(country.data) );
                }
            }
        }catch(error){
            console.log(error);
        }
    }

    async datacountry(){
        try{
            var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
            this.setState({
                country : country?.country,
                countryCode : country.countryCode ? country.countryCode : "bi",
                region : country.regionName ? country.regionName : "Bujumbura Mairie Province",
                city : country.city ? country.city : "",
            });
            
            this.dataregions(country.countryCode ? country.countryCode?.toLowerCase() : "bi");
            this.datacities(country.regionName ? country.regionName :"Bujumbura Mairie Province");
            
        }catch(error){
            console.log(error);
        }
    }

    async dataregions(cod = null){
        var code = cod != null ? cod : this.state.countryCode;
        var regions = await axios.get("countries/" +  code);
    
        if(regions.status === 200){
            this.setState({
                regions : regions.data ? regions.data : []
            })
            this.datacities(this.state.region);
        }
    }
    
    async datacities(regio = null){
    
        if(this.state.countryCode){
            
            var region = regio != null ? regio : this.state.region;
            
            var cities = await axios.get("countries/" + this.state.countryCode +"/"+ region);
            if(cities.status === 200){
                this.setState({
                    cities : cities.data
                })
            }
        }
    }

    handleInputRegion = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            city : ""
        });
        if(e.target.value){
            this.datacities(e.target.value);
        }
    }


    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async categorieList(){
        var categories = await axios.get("categories");
        if(categories.status === 200){
            this.setState({
                categorieList : categories.data,
            })
        }
    }

    async refreshToken () {
        try{
            let token = await axios.get('token');
            if(token.status == 200)
            {
                let user = (token.data.data);
                localStorage.setItem("user", JSON.stringify(user));
            }
            else{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                window.localStorage.clear();
            }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
        }
    }

    render(){
        const { t } = this.props
        return(
            <div className="pt-4 pb-4 row">
                <div className="text-center font-13 fw-500"><i className="ti ti-search text-orangered font-20"></i> { this.state.country }</div>
                <div className=" col-md-3 col-6 mt-2">
                        <select className="form-control form-control-sm border m-1" name="cat" onChange={this.handleInput}>
                            <option>{ t("formulaire.trouver")} ...</option>
                            {
                                this.state.categorieList.map((category) => {
                                    return( 
                                        <option value={category.id} key={category.id}>{ category.name }</option>
                                    )
                                })
                            }
                        </select>
                </div>

                {/* <div className=" col-md-3 col-6 mt-2">
                        <select className="form-control form-control-sm border m-1"  name="region" onChange={this.handleInputRegion}>
                            <option>{ t("formulaire.region")} ...</option>
                            {
                                (this.state.regions.length > 0) && (
                                    this.state.regions.map((region) => {
                                        return( 
                                            <option value={region.id} key={region.id}>{ region.region }</option>
                                        )
                                    })
                                )
                            }
                        </select>   
                </div> */}

                {/* {
                    this.state.region != "" && (
                        <div className=" col-md-3 col-6 mt-2">
                            
                                <select className="form-control form-control-sm border m-1" name="city" value={ this.state.city} onChange={this.handleInput}>
                                    <option>{ t("formulaire.ville")} ...</option>
                                    {
                                        this.state.cities.length > 0 && ( 
                                            (this.state.cities ?? []).map((city) => {
                                                return( 
                                                    <option value={city.id} key={city.id}>{ city.city }</option>
                                                )
                                            })
                                        )
                                    }
                                </select>
                        </div>
                    )
                } */}

                <div className="col-md-3 col-6 mt-2 mt-2">
                    <input type="text" className="form-control form-control-sm border m-1" placeholder={ t("formulaire.autre_addresse")}  onChange={this.handleInput} name="adress"  value={this.state.adress} id="nom-id-icon" />
                </div>

                <div className="col-md-3 col-6 mt-2 mt-2">
                    <a className="btn btn-outline-primary text-decoration-none btn-sm" href={"/search/" + this.state.cat + "/" + this.state.region + "/" + this.state.city + "/" + this.state.adress } type="button"><i className="ti ti-search"></i> { t("formulaire.rechercher")} </a>
                </div>
                    
                
            </div>
        )
    }
}
export default   withTranslation()(Search)