import React, {Component} from "react";
import axios from "axios";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import DevisForm from "../components/DevisForm";
import { withTranslation } from 'react-i18next';
import ItemData from "../components/ItemData";
import Head from "../partials/Head";

class Categoryarticle extends Component{

    state = {
        loading : false,
        isModal : false,
        cat : {},
        items : [],
        category : "",
        title : "",
        img : "",
        categories : [],
    }

    componentDidMount(){
        
        this.category();
        this.categories();
        this.items();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async category(){
        var category = await axios.get("categories/" + this.props.match.params.id);
        if(category.status === 200){
            this.setState({
                cat : category.data,
                category : category.data?.category?.name ? category.data.category.name + " " + category.data.name : category.data.name,
                title : category.data?.category?.name ? category.data.category.title + " " + category.data.title : category.data.title,
                img : category.data.image
            })
        }
    }


    async categories(){
        var categories = await axios.get("categories");
        if(categories.status === 200){
            this.setState({
                categories : categories.data,
            })
        }
    }

    async items (){
        var items = await axios.get("items/category/" + this.props.match.params.id);
        if(items.status === 200){
            this.setState({
                items : items.data, 
                loading : true
            })
        }
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

     handleCheck(val) {

        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
 
        return permissions.some(item => val === item.name)
    }


    render(){ 
        const { t } = this.props;
        var items = 
        this.state.items.map((item) => {
            var image =
                <Link to={'/items/'+item.id } >
                    {
                        this.state.cat?.width > 0 ?
                        <img key={item.id+"cat_"} src={item.images.image} className="img-thumbnail" alt={"CH DUTY TRUCK | " + this.state.cat?.name} style={{  maxWidth : this.state.category?.width +"px", maxHeight : this.state.category?.height + "px" }} /> 
                        :<img src={ item.images.image } className="img-thumbnail" alt={ item.libelle} style={{ width : 307 +"px", height : 204 + "px" }} />
                    }
                </Link>
            return(
                <ItemData
                    image={image}
                    classecontent={ this.state.cat ? this.state.cat.classecontent ? this.state.cat.classecontent : " col-md-3 " : " col-md-3 "}
                    color={"rib1-warning"}
                    item={item}
                />
            )
        })

        return(
            <div>
                <Head title ={ "CH DUTY TRUCK  " + this.state.category   } image ={ this.state.img } url ={ "/items/category/" + this.props.match.params.id } />
                <div className="row mt-4 pt-4">
                {
                    !this.state.loading && ( <Loading />)
                }
                <div className="page-title-box">
                    <div className="">
                        <img src={ this.state.img} alt={ this.state.category} className="rounded rounded-3  me-2"  style={{ height : 60 + "px", width : 100 +"px" }} /> 
                        <span className=""> { this.state.category}</span><br />
                        <small className="small"> { this.state.title}</small>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0 d-end float-end text-end d-none">
                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal()}><i className="ti ti-plus font-18"></i> { t("formulaire.faire_un_devis")}</button>
                    </div>
                </div>

                
                {
                    items
                }
                </div>
                <DevisForm
                    isModal={this.state.isModal}
                    showModal={this.showModal}
                />
            </div>
        )
    }
}
export default withTranslation()(Categoryarticle);