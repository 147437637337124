import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   MarkForm extends Component{

    state = {
        id : "",
        ref_user : null,
        ref_user_update : null,
        name : "",
        parentId : null,
        error : "",

        title : "",
        marks : [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    storeMark = async (e) => {
        e.preventDefault();

        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        
        var data = {
            ref_user : this.state.ref_user ? this.state.ref_user :  user.id,
            ref_user_update : user.id,
            name : this.state.name,
        }
        
        var request = this.state.id ? axios.put('marks/'+this.state.id, data) : axios.post('marks', data);
        request.then((response) =>{
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(response.data?.id);
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg,
            })
            toast(error.response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
        
    }
    
    edit=()=>  {
        this.form()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            ref_user: this.props.formData ? this.props.formData.ref_user : "",
            name: this.props.formData ? this.props.formData.name : "",
            title : this.props.formData ? "Modifier la marque" : "Ajouter la marque",
            error : "",
        })
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="small"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.storeMark} className="row">
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        
                        <div className="mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Marque</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="col mt-2 mt-2">
                            <div className=" pt-2 text-end">
                                <button onClick={ () => this.props.showModal()}   type="button" className="btn btn-outline-secondary btn-sm me-3"><i className="ti ti-corner-down-left"></i> Fermer</button>
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" type='submit' >Sauvegarder</button>
                            </div>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>


        </div>
        )
    }
}
export default MarkForm;
