import React, {Suspense, useEffect} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RouterPage from "./RouterPage";
import Footer from "./partials/Footer";
import Navbar from './partials/Navbar';
import LoadingD from "./components/LoadingD";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

function App() {
 
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "fr",
  //       autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };

  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  return (
    <Suspense fallback={( <div className="m-4 p-4"><LoadingD /></div>)}>
        <Router>
    <div className="row d-flex justify-content-center" style={{  backgroundColor : "#FAFAFA" }}>
      <div className="col-md-10 col-12">
            <Navbar />
            <div className="page-wrapper">
              <div className="page-content-tab">
                <div className="container-fluid bg">
                  <RouterPage />
                </div>
              </div>
            </div>
        </div>
    </div>
    <ToastContainer />
    {/* <div id="google_translate_element"></div> */}
    <Footer />
    </Router>
    </Suspense>
  );
}

export default App;
