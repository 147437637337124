import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { shareTextToWhatsApp } from 'share-text-to-whatsapp';
import { withTranslation } from 'react-i18next';
import Head from "../partials/Head";

class   ArticleDetail extends Component{

    state = {
        quantity : 1,
        articles : [],
        references : [],
        societies : [],
        article :{},
        loading : false,
        btn : false,
    }

    componentDidMount(){
        this.getArticle();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getArticle(){
        var article = await axios.get('items/' + this.props.match.params.id);
        if(article.status === 200)
        {
            this.setState({article : article.data})
            this.getArticles();
            if(process.env.REACT_APP_REFERENCE_HOTEL == this.state.article.category.id)
            this.getReferences();
            this.getArticleSociety();
        }
    }

    async getReferences(){
        var reference = this.state.article.reference ?? this.state.article.id
        var references = await axios.get('items/reference/' + this.state.article.category.id + "/" + reference);
        if(references.status === 200)
        {
            this.setState({references : references.data})
        }
    }


    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    storeQuotation = () =>{
        this.setState({ btn : true})
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var data = {
                quotation : {
                    customerId :  user.id,
                    status : "loading"
                },
                detailquotation : {
                    quotationId : null,
                    articleId : this.state.article.id,
                    quantity : this.state.quantity > 0 ? this.state.quantity : 1,
                    periode :  1,
                    price : this.state.article.price,
                    devise : this.state.article.devise,
                    categoryId : this.state.article.category.id,
                    unite : this.state.article.unite,
                    country : this.state.article.country,
                    markId : this.state.article.mark ? this.state.article.mark.id : this.state.article.markId,
                    countryCode : this.state.article.countryCode,
                    region : this.state.article.region,
                    city : this.state.article.city,
                    color1 : this.state.article.color1,
                    color2 : this.state.article.color2,
                     vitesse : this.state.article. vitesse,
                    carburant : this.state.article.carburant,
                    description : null,
                }
            }

        var request = axios.post('quotations', data);
        request.then((response) =>{
            window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/myquotations/loading`);
        }).catch((error) => {
            this.setState({
                btn : false,
                error : error.response.data.msg
            })
        })
    }

    async getArticles(){
        this.setState({
            articles : [],
            loading : false
        })
        var articles = await axios.get('items/category/' + this.state.article.category.id);

        if(articles.status === 200)
        {
            this.setState({articles : articles.data, loading : true})
        }
    }


    async getArticleSociety(){
        var societies = await axios.get('items/society/' + this.state.article.society.id);
        if(societies.status === 200)
        {
            this.setState({societies : societies.data})
        }
    }

    sendWhatsapp = () => {
        var category = this.state.article.category ? this.state.article.category.name  : ""
        var region = this.state.article.region ? this.state.article.region : ""
        var city = this.state.article.city ? this.state.article.city : ""
        var text = category + " " + region + " " + city;
        text += this.state.article.modele ? this.state.article.modele.name  : ""
        var image = this.state.article.images ? this.state.article.images[0]?.image : "";
        var description = this.state.article.description ? this.state.article.description : "";
        const message = "\n \n"+ process.env.REACT_APP_CHDUTYTRUCK +'/items/' + this.props.match.params.id  + "\n \n *# "  + text + " °* \n \n" + description +" \n \n " + image;
        shareTextToWhatsApp(message );
    }
    
    render(){
        const { t } = this.props

        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var articles = 
        this.state.articles.map((item) => {
            var arrondi = "200", currency  = ""
                arrondi = item.price*100;          
                arrondi = Math.round(arrondi); 
                arrondi = arrondi/100;
            
            return(
                <div className="col-md-3 overflow-hidden" key={item.id}>
                    <div className="card shadow bg-white mb-2">
                        <div className="card-body p-0">
                            <div className="ribbon1 rib1-warning">
                                <span className="text-white text-center rib1-warning">{ item.kilometre ? <span style={{ fontSize : 7 }}>{ item.kilometre}</span> : <i className="ti ti-activity-heartbeat"></i>}</span>                                        
                            </div>                                    
                            <a href={'/items/'+item.id }><img src={ item.images.image} className="img-thumbnail" alt={ item.images.image} style={{ width : 307 +"px", height : 204 + "px" }} /></a>
                            <div className="p-2 pb-0">
                                <div className="d-lg-flex justify-content-between align-items-center my-2">
                                    <div className="m-0 text-truncate">
                                        <p className="header-title text-muted text-truncate mb-0"><h4 className="h6"><a href={'/items/' + item.id } className="font-13 text-truncate text-orangered-hover">{ item.category ? item.category.name : ""} </a></h4></p>
                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+item.id } className="font-13 text-truncat text-orangered-hover"><i className="ti ti-server text-orangered"></i>{ item.boite_vitesse }, <i className="ti ti-battery text-orangered"></i>{ item.taille_moteur }</Link></p>
                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+item.id } className="font-13 text-truncate font-monospace text-orangered-hover"><i className="ti ti-flag text-orangered"></i>{ item.city}</Link></p>
                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+item.id } className="font-13 text-truncate font-monospace text-orangered-hover">{ item.mark ? item?.modele ?  item?.mark?.name + " " + item.modele?.name : item.modele?.name : <br /> }</Link></p>
                                    </div>
                                    <div className=" m-0 text-truncate pe-4">
                                        <h4 className="text-dark mb-1"><a href={'/items/'+item.id } className="text-truncate text-orangered">{ arrondi }{  currency || "$" }</a></h4>  
                                        <ul className="list-inline mb-0 product-review align-self-center">
                                            <a href={'/items/'+item.id }>
                                                <li className="list-inline-item"><i className="ti ti-star text-warning font-16"></i></li><li className="list-inline-item"><i className="ti ti-star text-warning font-16 ms-n2"></i></li><li className="list-inline-item"><i className="ti ti-star text-warning font-16 ms-n2"></i></li>
                                            </a>
                                        </ul> 
                                    </div>      
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
            )
        })

        var now = new Date(this.state.article.createdAt);
        
        const data = [ ];
        this.state.article.id && (
        this.state.article.images.map( (img, count) => {
            data.push({
                original : img.image,
                thumbnail : img.image,
            })
        })
        )

        var category = this.state.article.category ? this.state.article.category.name : ""
        var region = this.state.article.region ? this.state.article.region : ""
        var city = this.state.article.city ? this.state.article.city : "" 
        var image = this.state.article.images ? this.state.article.images[0]?.image : false
        var text = "CH DUTY TRUCK " + category + " "+ region + " " + city;
     
        return(
            <div>
            <Head title ={ text } image ={ image } url ={'/items/' + this.props.match.params.id} />

                <div className="row">
                    <div className="col-sm-12 mt-5 mb-5">
                        <div className="page-title-box">
                            <div className="float-start">
                                <h5 className="page-title h5 m-2 font-monospace">{ t("articleDetail.detail")}  { this.state.article ? this.state.article.category ? this.state.article.category.name : "" : ""}  { this.state.article ? this.state.article.mark ? this.state.article.mark.name : "" : ""}  { this.state.article ? this.state.article.modele ? this.state.article.modele.name : "" : ""}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-9 ">
                        <div className="card shadow bg-white mb-2">
                            <div className="card-body">
                                <div className="row">
                                    
                                    <div className="col-12 align-self-center overflow-hidden p-0">
                                        { data.length > 0 ? <ImageGallery items={data} autoPlay={true} /> : <img src={ null} className="img-thumbnail" alt={ null} style={{ width : 307 +"px", height : 204 + "px" }} />}
                                    </div>{/*end col*/}
                                    <div className="col-12 row align-self-center overflow-hidden">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-10">
                                            <span className={ this.state.article.isValid == "1" ? "badge badge-soft-primary font-13 fw-semibold mb-2" : "badge badge-soft-danger font-13 fw-semibold mb-2"}>{ this.state.article.isValid == "1" ? t("formulaire.disponible") :  t("formulaire.non_disponible")}</span>
                                            <h5 className="font-18 mb-0">{ this.state.article ? this.state.article.category ? this.state.article.category.name : "" : ""}</h5>
                                             <p className="mb-2">{ this.state.article ? this.state.article.country  : ""} { this.state.article ? this.state.article.region   : ""} { this.state.article ? this.state.article.city : ""} { this.state.article ? this.state.article.adress  : ""}</p> 
                                            <ul className="list-inline mb-2">
                                                <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-16"></i></li>
                                                <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-16"></i></li>
                                                <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-16"></i></li>
                                                <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-16"></i></li>
                                                <li className="list-inline-item"><i className="ti ti-star-half text-warning font-16"></i></li>
                                                
                                                <li className="font-16 "><i className="ti ti-server text-danger  fw-bolder"></i> Kilometre <span className="text-primary">{ this.state.article.kilometre}</span></li>
                                                { this.state.article.boite_vitessse ? (<li className="font-13 "><i className="ti ti-cpu text-danger  fw-bolder"></i> { t("articleDetail.marque")}<span className="text-primary">  { this.state.article.boite_vitessse } Boite vitesse</span></li>) : null }
                                                { this.state.article.taille_moteur ? (<li className="font-13 "><i className="ti ti-battery text-danger  fw-bolder"></i> { t("articleDetail.marque")}<span className="text-primary">  { this.state.article.taille_moteur } Taille moteur</span></li>) : null }
                                                {/* { this.state.article.autonomy ? (<li className="font-13 "><i className="ti ti-cactus text-danger  fw-bolder"></i> { t("articleDetail.marque")}<span className="text-primary">  { this.state.article.autonomy } heures</span></li>) : null }
                                                { this.state.article.garanty ? (<li className="font-13 "><i className="ti ti-chair-director text-danger  fw-bolder"></i> { t("articleDetail.marque")}<span className="text-primary">  { this.state.article.garanty } jours</span></li>) : null }
                                                { this.state.article.timeUse ? (<li className="font-13 "><i className="ti ti-caledary text-danger  fw-bolder"></i> { t("articleDetail.marque")}<span className="text-primary">  { this.state.article.timeUse } mois</span></li>) : null } */}
                                                { this.state.article.mark ? (<li className="font-13 "><i className="ti ti-viewfinder text-danger  fw-bolder"></i> { t("articleDetail.marque")}<span className="text-primary">  { this.state.article.mark.name }</span></li>) : null }
                                                { this.state.article.modele ? (<li className="font-13 "><i className="ti ti-tower text-danger  fw-bolder"></i> { t("articleDetail.modele")}<span className="text-primary">  { this.state.article.modele.name }</span></li>) : null }
                                                { this.state.article.couleur_exterieur ? (<li className="font-13 "><i className="ti ti-train text-danger  fw-bolder"></i> { t("articleDetail.couleur_exterieur")}<span className="text-primary">  { this.state.article.couleur_exterieur }</span></li>) : null}
                                                { this.state.article.couleur_interieur ? (<li className="font-13 "><i className="ti ti-train text-danger  fw-bolder"></i> { t("articleDetail.couleur_interieur")}<span className="text-primary">  { this.state.article.couleur_interieur }</span></li>) : null}
                                                <li className="font-13 ">  <a href={"tel:" + this.state.article.user?.tel } className="text-primary border border-info px-1 rounded rounded-3 "><i className="ti ti-phone text-primary  fw-bolder"></i>  { t("articleDetail.contactphone") }  </a></li>
                                            </ul>
                                            <h6 className="font-18 fw-bold">{ this.state.article.price }{ this.state.article.devise  }<span className="font-14 text-muted fw-semibold"><del>{ this.state.article.price }{  this.state.article.devise || "$"  }</del></span><span className="text-danger font-14 fw-semibold ms-2">0.1% Off</span></h6>
                                                            
                                            <div className="mt-3 d-flex">
                                                <input className="form-control form-control-sm d-inline-block d-none" style={{width:100+"px"}} type="number" name="quantity" id="quantity" value={ this.state.quantity } onChange={ this.handleInput } />
                                                {
                                                        <div className="d-none">
                                                        {
                                                            this.state.article.isValid == 1 ?
                                                                <div>
                                                                {
                                                                    this.state.btn ? 
                                                                    <Loading /> :
                                                                    <>
                                                                        <button type="button" disabled={this.state.btn} className="btn btn-outline-primary ms-1 btn-sm py-0 d-inline-block" ><i className="ti ti-phone-call me-0 font-20"></i> </button>
                                                                        <button type="button" disabled={this.state.btn} className="btn btn-outline-primary ms-1 btn-sm py-0 d-inline-block" onClick={ () => this.storeQuotation()}><i className="ti ti-brand-whatsapp me-0 font-20"></i> { t("formulaire.reserver")}</button>
                                                                    </>
                                                                }
                                                                </div>
                                                            :
                                                            <button type="button" className="btn btn-outline-danger ms-1 btn-sm py-0 d-inline-block"><i className="ti ti-shopping-cart me-0 font-20"></i> { t("formulaire.non_disponible")} ...</button>
                                                        }
                                                        </div>
                                                        
                                                }
                                                { !this.state.btn && (<a type="button" target="_blank" href={"tel:" + process.env.REACT_APP_WHATSAPP} className="btn btn-outline-primary ms-1 btn-sm py-0 d-inline-block"><i className="ti ti-phone-calling me-0 font-20"></i> { t("formulaire.reserver")}</a> )}
                                                { !this.state.btn && (<a type="button" target="_blank" href="#" className="btn btn-outline-success ms-1 btn-sm py-0 d-inline-block" onClick={ () => this.sendWhatsapp()}><i className="ti ti-brand-whatsapp me-0 font-20"></i> { t("formulaire.partager")}</a> )}
                                            </div>                                             
                                        </div>
                                    </div>{/*end col*/}                                            
                                </div>{/*end row*/}
                            </div>{/*end card-body*/}
                        </div>{/*end card*/}

                        {
                            (this.state.article.description || this.state.article.specification) && (
                                <div className="card shadow mb-3">
                                    <div className="card-body">
                                        <h5 className="page-title h5 m-2 font-monospace fw-bold">{ t("articleDetail.description")} </h5> 
                                        <p className="text-muted font-monospace">
                                            { this.state.article.description}
                                            <span className="text-danger">{ this.state.article.specification}</span>
                                        </p>
                                        
                                    </div>
                                </div>
                            )
                        }

                        
                    </div>{/*end col*/}

                    <div className="col-md-3 overflow-hidden"> 
                        <div className="card shadow bg-white mb-2">
                            <div className="card-body">
                                <div className="text-center bg-light p-1 rounded rounded-3 align-item-center">
                                    <h4 className="header-title mb-0"><span className="font-11"> pour <span className="text-muted">{ this.state.article.mark?.name}</span></span></h4>  
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-18"></i></li>
                                        <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-18"></i></li>
                                        <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-18"></i></li>
                                        <li className="list-inline-item me-0"><i className="ti ti-star text-warning font-18"></i></li>
                                        <li className="list-inline-item"><i className="ti ti-star-half text-warning font-18"></i></li>
                                    </ul> 

                                    <h6 className="font-13  mt-1 mb-1">{ t("articleDetail.addresse")} { this.state.article ? this.state.article.category ? this.state.article.category.name : "" : ""}</h6> 
                                    <ul className="list-unstyled border-0">
                                        <li className="mb-2"><i className="ti ti-flag text-success me-1"></i>{ this.state.article.country}</li>
                                        <li className="mb-2"><i className="ti ti-check text-primary me-1"></i>{ this.state.article.region}</li>
                                        <li className="mb-2"><i className="ti ti-check text-warning me-1"></i>{ this.state.article.city}</li>
                                    </ul>                                   
                                </div> 
                                
                                <div className="bg-light p-1 text-center mt-3 rounded rounded-3 align-item-center">
                                    <h6 className="font-13  mt-1 mb-1">{ t("articleDetail.responsable")}</h6>                                                                                                       
                                    <ul className="list-unstyled border-0">
                                        <li className="mb-2 text-muted font-14"><i className="text-success me-1"></i> <img src={ this.state.article.society ? this.state.article.society.logo ? this.state.article.society.logo : process.env.REACT_APP_CHDUTYTRUCK +"/logo192.png" : process.env.REACT_APP_CHDUTYTRUCK +"/logo192.png" } className="thumb-md" alt="logo" style={{ width : "200px"}}/>{ this.state.article.society ? this.state.article.society.name : "" }</li>
                                        <li className="mb-2 text-muted"><i className="ti ti-flag text-warning me-1"></i>{ this.state.article.society ? this.state.article.society.city : ""}</li>
                                        <li className="mb-2 text-muted"><i className="ti ti-mail text-primary me-1"></i><a href={ this.state.article.society ? "mailto:" +this.state.article.society.email : "#" } className="text-decoration-none text-orangered-hover">{ this.state.article.society ? this.state.article.society.email : "" } </a></li>
                                        <li className={ this.state.article?.society?.tel ? "mb-2 text-muted" : "d-none"}><i className="ti ti-phone text-primary me-1"></i><a href={ this.state.article.society ? "tel:" + this.state.article.society.tel : "#" } className="text-decoration-none text-orangered-hover">{ this.state.article.society ? this.state.article.society.tel : "" } </a></li>
                                        <li className={ this.state.article?.society?.tel1 ? "mb-2 text-muted" : "d-none"}><i className="ti ti-phone text-primary me-1"></i><a href={ this.state.article.society ? "tel:" + this.state.article.society.tel1 : "#" } className="text-decoration-none text-orangered-hover">{ this.state.article.society ? this.state.article.society.tel1 : "" } </a></li>
                                        <li className={ this.state.article?.society?.tel2 ? "mb-2 text-muted" : "d-none"}><i className="ti ti-phone text-primary me-1"></i><a href={ this.state.article.society ? "tel:" + this.state.article.society.tel2 : "#" } className="text-decoration-none text-orangered-hover">{ this.state.article.society ? this.state.article.society.tel2 : "" } </a></li>
                                    </ul>                                                      
                                </div> 

                                <div className="bg-light p-1 mt-3 rounded rounded-3 text-center align-item-center">                                                                                   
                                    <p className="text-muted mb-0"><i className="ti ti-calendar text-primary me-1"></i>{ now.toLocaleDateString("en-GB") }</p>                                                                                                 
                                </div>  
                            </div>
                        </div>                                                                   
                    </div>
                </div>

                <div className={ this.state.references && this.state.references.length > 0 ? "my-2 mt-4 pt-4" : "d-none"}>
                    <h5 className="page-title h5 my-4 mt-5 font-monospace fw-bold text-uppercase">{ this.state.article ? this.state.article.category ? this.state.article.category.name : "" : ""}</h5>
                    <div className="row">
                        { 
                            this.state.references.map((item) => {
                            var arrondi = "200", currency  = ""
                                arrondi = item.price*100;          
                                arrondi = Math.round(arrondi); 
                                arrondi = arrondi/100;
                           
                            return(
                                <div className={ this.state.article ? this.state.article.category ? this.state.article.category.classecontent +"  overflow-hidden" : "" : "" } key={item.id}>
                                    <div className="card shadow bg-white mb-2">
                                        <div className="card-body p-0">
                                            <div className="ribbon1 rib1-warning">
                                                <span className="text-white text-center rib1-warning">{ item.kilometre ? <span style={{ fontSize : 7 }}>{ item.kilometre}</span> : <i className="ti ti-activity-heartbeat"></i>}</span>                                        
                                            </div>                                    
                                            <a href={'/items/'+item.id }><img src={ item.images.image} className="img-thumbnail" alt={ "CH DUTY TRUCK | " + this.state.article ? this.state.article.category ? this.state.article.category.name : "" : "" } style={{ width : 307 +"px", height : 204 + "px" }} /></a>
                                            <div className="p-2 pb-0">
                                                <div className="d-lg-flex justify-content-between align-items-center my-2">
                                                    <div className="m-0 text-truncate">   
                                                        <p className="header-title text-muted text-truncate mb-0"><h4 className="h6"><a href={'/items/' + item.id } className="font-13 text-truncate text-orangered-hover">{ item.category ? item.category.name : ""}</a></h4></p>
                                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+this.props.item.id } className="font-13 text-truncat text-orangered-hover"><i className="ti ti-server text-orangered"></i>{ this.props.item.boite_vitesse }, <i className="ti ti-battery text-orangered"></i>{ this.props.item.taille_moteur }</Link></p>
                                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+this.props.item.id } className="font-13 text-truncate font-monospace text-orangered-hover"><i className="ti ti-flag text-orangered"></i>{ this.props.item.city}</Link></p>
                                                        <p className="text-muted text-truncate mb-0"><Link to={'/items/'+this.props.item.id } className="font-13 text-truncate font-monospace text-orangered-hover">{ this.props.item.mark ? this.props.item?.modele ?  this.props.item.mark.name + " " + this.props.item.modele?.name : this.props.modele?.name : <br /> }</Link></p>
                                                    </div>
                                                    <div className=" m-0 text-truncate pe-4">
                                                        <h4 className="text-dark mb-1"><a href={'/items/'+item.id } className="text-truncate text-orangered">{ arrondi }{  currency || "$" }</a></h4>  
                                                        <ul className="list-inline mb-0 product-review align-self-center">
                                                            <a href={'/items/'+item.id }>
                                                                <li className="list-inline-item"><i className="ti ti-star text-warning font-16"></i></li><li className="list-inline-item"><i className="ti ti-star text-warning font-16 ms-n2"></i></li><li className="list-inline-item"><i className="ti ti-star text-warning font-16 ms-n2"></i></li>
                                                            </a>
                                                        </ul> 
                                                    </div>      
                                                </div> 
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                         }
                    </div>
                </div>

                <div className="my-2 mt-4 pt-4">
                    <h5 className="page-title h5 my-4 mt-5 font-monospace fw-bold text-primary"><span className={ this.state.references && this.state.references.length > 0 ? "d-none" : ""}>{ this.state.article ? this.state.article.category ? this.state.article.category.name : "" : ""},</span> { t("articleDetail.vous_pourriez_aussi_aimer_notre_selection") }</h5>
                </div>
                <div className="row">
                    { articles }
                </div>

                <div className="my-2 mt-4 pt-4">
                    <h5 className="page-title h5 my-4 mt-5 font-monospace fw-bold text-orangered"> { t("articleDetail.meilleur_selection") } { this.state.article.society ? this.state.article.society.name : "" }</h5>
                </div>
                <div className="row">
                    { 
                        this.state.societies.map((item) => {
                            var nombre = item.price ;
                            var arrondi = nombre*100;          
                            arrondi = Math.round(arrondi); 
                            arrondi = arrondi/100;
                            return(
                                <div className="col-md-3 overflow-hidden" key={item.id}>
                                    <div className="card shadow bg-white mb-2">
                                        <div className="card-body p-0">
                                            <div className="ribbon1 rib1-warning">
                                                <span className="text-white text-center rib1-warning">{ item.kilometre ? <span style={{ fontSize : 7 }}>{ item.kilometre}</span> : <i className="ti ti-activity-heartbeat"></i>}</span>                                        
                                            </div>                                    
                                            <a href={'/items/'+item.id }><img src={ item.images.image} className="img-thumbnail" alt={ "CH DUTY TRUCK | " + this.state.article ? this.state.article.category ? this.state.article.category.name : "" : "" } style={{ width : 307 +"px", height : 204 + "px" }} /></a>
                                            <div className="p-2 pb-0">
                                                <div className="d-lg-flex justify-content-between align-items-center my-2">
                                                    <div className="m-0 text-truncate">
                                                        <p className="header-title text-muted text-truncate mb-0"><h4 className="h6"><a href={'/items/' + item.id } className="font-13 text-truncate text-orangered-hover">{ item.category ? item.category.name : ""} </a></h4></p>
                                                        <p className="text-muted text-truncate mb-0"><a href={'/items/'+item.id } className="font-13 text-truncate font-monospace  text-orangered-hover">{ item.region} { item.city} { item.adress}</a></p>
                                                        <p className="text-muted text-truncate mb-0"><a href={'/items/'+item.id } className="font-13 text-truncate font-monospace  text-orangered-hover">{ item.description ? item.description : <br /> }</a></p>
                                                    </div>
                                                    <div className=" m-0 text-truncate pe-4">
                                                        <h4 className="text-dark mb-1"><a href={'/items/'+item.id } className="text-truncate text-orangered">{ arrondi }{  item.devise || "$"}</a></h4>  
                                                        <ul className="list-inline mb-0 product-review align-self-center">
                                                            <a href={'/items/'+item.id }>
                                                                <li className="list-inline-item"><i className="ti ti-star text-warning font-16"></i></li><li className="list-inline-item"><i className="ti ti-star text-warning font-16 ms-n2"></i></li><li className="list-inline-item"><i className="ti ti-star text-warning font-16 ms-n2"></i></li>
                                                            </a>
                                                        </ul> 
                                                    </div>      
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                     }
                </div>

            </div>
        )
    }
}
export default withTranslation()(ArticleDetail);
