import axios from "axios";
import React, {Component} from "react";
import ProfileForm from "../components/ProfileForm";

class Profile extends Component{

    state = {
        isModal : false,
        formData : null,
        profiles : [],
        loading : false
    }

    componentDidMount(){
        this.getProfiles();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getProfiles(){
        this.setState({
            loading : false
        })
        var user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
        var society = localStorage.getItem("society") ?  JSON.parse(localStorage.getItem("society")) : {};
        var societ = society.userprofile ?  society.userprofile.profile ? society.userprofile.profile.society ? society.userprofile.profile.society.id : null : null : null;
        var mysociety = await axios.get('societies/' + societ);
        
        if(mysociety.status === 200)
        {
            this.setState({profiles : mysociety.data.profiles, loading : true})
        }
    }

    showModal = (profile = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : profile,
        })
        this.getProfiles()
    }


    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        var count = 1, profiles =
        this.state.profiles.map((profile) => {
            return(
                <span className="  me-3">
                    { profile.name }
                    <span className="badge badge-boxed  badge-outline-primary">{ profile.userprofiles.length }<i className="ti ti-users font-15"></i> </span>
                     {
                            profile.societyId && (
                                <>
                                {
                                    this.handleCheck("modifier profil") == true && (
                                    <a href="#" onClick={ () => this.showModal(profile)} className=""><i className="ti ti-edit text-primary font-16 mx-1" ></i></a>
                                )}
                        </>
                            )
                        }
                </span>
            )
        })
        return(
          <div>
          
                     { profiles}
                {
                    this.handleCheck("ajouter profil") == true && (
                        <button type="button" className="btn btn-sm btn-outline-primary  font-15 btn-sm" onClick={() => this.showModal(null)}><i className="ti ti-plus font-16"></i></button>
                    )
                }

              <ProfileForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Profile;
