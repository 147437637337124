import axios from "axios";
import React, {Component} from "react";
import Loading from "../components/Loading";
import ReactLoading from "react-loading";
import DetailArticle from "../components/DetailArticle";

class   Payment extends Component{

    state = {
        quantity : 1,
        periode : 1,
        datestart : "",
        typepayement : "cash",
        card_number : "",
        quotation :{},
        loading : false,
        btn  : false,
    }

    componentDidMount(){
        this.getQuotation();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getQuotation(){
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var quotation = await axios.get('quotations/' + this.props.match.params.id);
        if(quotation.status === 200)
        {
            quotation.data.detailquotations = quotation.data.detailquotation;
            this.setState({
                quotation : quotation.data,
                quantity : quotation.data.detailquotations ? quotation.data.detailquotations.quantity : 1,
                periode : quotation.data.detailquotations ? quotation.data.detailquotations.periode : 1,
                description : quotation.data.detailquotations ? quotation.data.detailquotations.description : "",
                card_name : user.name, 
                loading : true
            })
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    storeQuotation = () =>{
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        this.setState({
            btn : !this.state.btn
        })
        var data = {
                quotation : {
                    customerId :  user.id,
                    datestart : this.state.datestart,
                },
                detailquotation : {
                    id : this.state.quotation.detailquotation.id,
                    articleId : this.state.quotation.detailquotation.articleId,
                    quantity : this.state.quantity > 0 ? this.state.quantity : 1,
                    periode :  this.state.periode > 0 ? this.state.periode : 1,
                    unite :  this.state.quotation.detailquotation.article.unite,
                    devise : this.state.quotation.detailquotation.article.devise,
                    price : this.state.quotation.detailquotation.article.price *  this.state.quantity * this.state.periode,
                    description : this.state.description,
                },
                payment : {
                    typepayement : this.state.typepayement,
                    customer_name : user.name,
                    customer_email : user.email,
                    card_name : this.state.typepayement == "cash" ? "enEspèce" : this.state.card_name,
                    card_number : this.state.typepayement == "cash" ? "000001" : this.state.card_number,
                    card_exp_month : this.state.typepayement == "cash" ? new Date().getMonth() + 1 : this.state.card_exp_month,
                    card_exp_year : this.state.typepayement == "cash" ? new Date().getFullYear() : this.state.card_exp_year,
                    card_cvc : this.state.typepayement == "cash" ? "cash en espèce" : this.state.card_cvc,
                }
        }

        var request = axios.post('payments/' + this.state.quotation.id, data);
        request.then((response) =>{
            window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/myquotations/paid`);
        }).catch((error) => {
            console.log(error);
            this.setState({
                error : error.response.data.msg,
                btn : !this.state.btn
            })
        })
    }

    

    render(){

        var now = new Date(this.state.quotation.createdAt);
        var today = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()

        now = new Date(this.state.quotation.updatedAt);
        var paiddate = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()

        now = new Date(this.state.quotation.datestart);
        var datestart = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()

        if(!this.state.quotation.datestart)
            datestart = "--";
            
        now = new Date(this.state.quotation.dateend);
        var dateend = now.getDate()  + "-" + (now.getMonth()+1) + "-" + now.getFullYear()+ " " + now.getHours()+ ":" + now.getMinutes()
        if(!this.state.quotation.dateend)
            dateend = "--";

            const data = [ ];
            this.state.quotation.detailquotations && (
            this.state.quotation.detailquotation.article.images.map( (img, count) => {
                data.push({
                    original : img.image,
                    thumbnail : img.image,
                })
            })
            )

        return(
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="float-start">
                                <h5 className="page-title h5 font-monospace fw-bold m-2">Detail { this.state.quotation ? this.state.quotation.detailquotations ? this.state.quotation.detailquotation.article.category ? this.state.quotation.detailquotation.article.category.name : "" : "" : ""}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !this.state.loading && ( <Loading />)
                }

                {
                    this.state.quotation.detailquotations && (
                    <div>
                        { this.state.quotation.detailquotations && ( <DetailArticle detailArticle={this.state.quotation.detailquotations.article} /> ) }
                        
                        
                        <div className="my-2">
                            <h5 className="page-title h5 font-monospace fw-bold my-4">Reservation  { this.state.quotation ? this.state.quotation.detailquotations ? this.state.quotation.detailquotation.article.category ? this.state.quotation.detailquotation.article.category.name : "" : "" : ""}</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-4 ">
                                <div className="card shadow bg-white mb-2">
                                    <div className="card-body">
                                        <div className={ this.state.quotation.status === "request" ? "ribbon1 rib1-warning" : this.state.quotation.status === "closed" ? "ribbon1 rib1-danger" : "ribbon1 rib1-primary"}>
                                            <span className={ this.state.quotation.status === "request" ? "text-white text-center rib1-warning" : this.state.quotation.status === "closed" ?  "text-white text-center rib1-danger"  : "text-white text-center rib1-primary" }>{ this.state.quotation > 0  ? this.state.quotation : this.state.quotation.detailquotation.article.type } </span>
                                        </div>
                                        <div  className="text-center p-1 rounded rounded-3 align-item-center pb-0 mb-0">
                                            <img src={this.state.quotation.user.avatar} className="rounded-circle me-2 thumb-sm" /><span className="font-12">{ this.state.quotation.user.name }</span> <a href={"tel:" + this.state.quotation.user.tel}  className="font-12 text-decoration-none">{ this.state.quotation.user.tel }</a><br />
                                            <span>Paid</span> <span className={ this.state.quotation.paid > 0 ? "badge badge-soft-primary ms-2 font-13 fw-semibold  " : "badge badge-soft-warning ms-2 font-13 fw-semibold " }>{ this.state.quotation.paid > 0 ? "Yes" : "Not" }</span>
                                            <div className={ this.state.quotation.paid == 1 ? "d-none" : "col-12 mb-0 pb-0"}>
                                                <div className="form-group has-success">
                                                    <label for="datestart" className="control-label text-dark">Date début</label>
                                                    <input id="datestart" name="datestart" type="datetime-local"  value={ this.state.datestart } onChange={this.handleInput} className="form-control form-control-sm "  />
                                                </div>
                                            </div>
                                        </div><hr />
                                        <div className="text-center p-1 rounded rounded-3 align-item-center">
                                            <ul className="list-unstyled border-0">
                                                <li className="mb-2"><i className="ti ti-flag text-success me-1"></i>{ this.state.quotation.detailquotation.country}</li>
                                                <li className="mb-2"><i className="ti ti-check text-primary me-1"></i>{ this.state.quotation.detailquotation.region}</li>
                                                <li className="mb-2"><i className="ti ti-check text-warning me-1"></i>{ this.state.quotation.detailquotation.city}</li>
                                            </ul>  
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 ">
                                <div className="card shadow bg-white mb-2">
                                    <div className="card-body">
                                        <div className={ this.state.quotation.status === "request" ? "ribbon1 rib1-warning" : this.state.quotation.status === "closed" ? "ribbon1 rib1-danger" : "ribbon1 rib1-primary"}>
                                            <span className={ this.state.quotation.status === "request" ? "text-white text-center rib1-warning" : this.state.quotation.status === "closed" ?  "text-white text-center rib1-danger"  : "text-white text-center rib1-primary" }>{ this.state.quotation > 0  ? this.state.quotation : this.state.quotation.detailquotation.article.type } </span>
                                        </div> 
                                        <div  className="text-center p-1 rounded rounded-3 align-item-center">
                                            <span className="header-title d-none">{ this.state.quantity }</span>
                                            <span className="header-title ms-2">{ this.state.quotation ? this.state.quotation.detailquotation.article.libelle : ""}</span><br />
                                            <span className="header-title me-2 text-primary mt-2 mx-1">{  this.state.quotation.paid === 0 ?  this.state.quotation.detailquotation.article.price * this.state.quantity * this.state.periode  : this.state.quotation.detailquotation.price  } {  this.state.quotation.status === "request" ?  this.state.quotation.detailquotation.devise : this.state.quotation.detailquotation.article.devise }</span>
                                            <br /> <span className="badge badge-outline-secondary mt-2 mx-1">{ this.state.periode } { this.state.quotation.detailquotation.unite }</span> 
                                        </div><hr />
                                        <div className="text-center p-1 rounded rounded-3 align-item-center">
                                            <div className="text-mutued">
                                                <div className="row">
                                                    <div className="col-6">
                                                    Quantité
                                                        <div className="form-group">
                                                            <input id="quantity" type="number" className="form-control border border-1 form-control-sm" value={this.state.quantity} name="quantity" onChange={this.handleInput}  placeholder="Qté" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        Période
                                                        <div className="form-group">
                                                            <input id="periode" name="periode" type="number" className="form-control border border-1 form-control-sm" onChange={this.handleInput} value={ this.state.periode }  placeholder="Periode" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <textarea className="form-control mt-2 form-control-sm border border-1" name="description" value={this.state.description ? this.state.description : this.state.quotation.detailquotation.description} onChange={this.handleInput}  placeholder="Votre commentaire ..."></textarea>
                                                {/* { this.state.quotation.detailquotation.description} */}
                                            </div> 
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 ">
                                <div className="card shadow bg-white mb-2">
                                    <div className="card-body"> 
                                    {
                                        this.state.quotation.paid === 1 ? 
                                            <div className="mx-auto">
                                                <div className={ this.state.quotation.status === "request" ? "ribbon1 rib1-primary" : this.state.quotation.status === "closed" ? "ribbon1 rib1-danger" : "ribbon1 rib1-warning"}>
                                                    <span className={ this.state.quotation.status === "request" ? "text-white text-center rib1-primary" : this.state.quotation.status === "closed" ?  "text-white text-center rib1-danger"  : "text-white text-center rib1-warning" }>{ this.state.quotation.detailquotation.article.type } </span>
                                                </div>
                                                <div className="text-center"> Date paiement <span className="text-muted ti ti-calendar font-16"></span> { paiddate } </div>
                                                <div className="text-center text-primary"><a target={"_blank"} href={ this.state.quotation.status == "paid" ?  this.state.quotation.invoicelink : "#"}> <spam className="text-orangered">facture</spam> <span className=" text-primary ti ti-file font-18 d-none"></span> </a></div><hr />
                                                
                                                <div className="text-center">
                                                    du { datestart } au  { dateend }
                                                </div><hr />

                                                <div className="text-center">
                                                    Paid with <span className="border border-1 border-warning rounded rounded-3 px-2">{ this.state.quotation.payment_method }</span><br />
                                                    <div className="font-monospace">{ this.state.quotation.card_number } { this.state.quotation.exp_month > 12 ? " / " + this.state.quotation.exp_month : ""  }</div>
                                                    <span className={ this.state.quotation.status === "request" ? "badge badge-soft-warning ms-2 font-13 fw-semibold my-1" : this.state.quotation.status === "closed" ? "badge badge-soft-danger ms-2 font-13 fw-semibold my-1" : "badge badge-soft-primary ms-2 font-13 fw-semibold my-1"}>{ this.state.quotation.status}</span>
                                                </div>
                                            </div>
                                        :
                                        <div>
                                            <div className="font-monospace">
                                                <div>
                                                    <label className="ps-2" htmlFor="cash">Paiement cash</label>
                                                    <input type={"radio"} checked={this.state.typepayement == "cash"  || this.state.typepayement == "EnEspèce" ? true : false } name="typepayement" id="cash" className="text-primary" value={"cash"} onChange={() =>{ this.setState({ typepayement : "cash" }) }} />
                                                 <br />
                                                    <label className="ps-2" htmlFor="mobile">Paiement mobile</label>
                                                    <input type={"radio"} name="typepayement" id="mobile" className="text-primary" value={"mobile"} onChange={() =>{ this.setState({ typepayement : "mobile" }) }} />
                                                 <br />
                                                    <label className="ps-2" htmlFor="card">Paiement par carte</label>
                                                    <input type={"radio"} id="card" name="typepayement" className="text-primary" value={"card"} onChange={() =>{ this.setState({ typepayement : "card" }) }} />
                                                </div>
                                            </div> <hr />
                                        {
                                            this.state.typepayement == "mobile" ?
                                                <div className="mx-auto">
                                                    <div className={ this.state.quotation.status === "request" ? "ribbon1 rib1-warning" : this.state.quotation.status === "closed" ? "ribbon1 rib1-danger" : "ribbon1 rib1-primary"}>
                                                        <span className={ this.state.quotation.status === "request" ? "text-white text-center rib1-warning" : this.state.quotation.status === "closed" ?  "text-white text-center rib1-danger"  : "text-white text-center rib1-primary" }>{ this.state.quotation > 0  ? this.state.quotation : this.state.quotation.detailquotation.article.type } </span>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <small className="font-9 text-primary fw-100"><a href={`mailto:${process.env.REACT_APP_EMAIL_ADMIN}`}  className="text-orangered">Contactez nous avant d'effectuer le paiement</a> </small>
                                                        <div className="col-6">
                                                            <div className="form-group has-success">
                                                                <label for="card_name" className="control-label text-muted">Identité</label>
                                                                <input id="card_name" name="card_name" type="text"  value={ this.state.card_name } onChange={this.handleInput} className="form-control form-control-sm "  />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group has-success">
                                                                <label for="card_cvc" className="control-label text-muted">Operateur</label>
                                                                <select name="card_cvc" className="form-control form-control-sm" onChange={(val) => { 
                                                                    this.setState({ card_cvc : val.target.value,  card_exp_month : val.target.value== "lumicash" ? "00000000" : "0000000000"  })
                                                                }}>
                                                                    <option value={""}>....</option>
                                                                    <option value={"lumicash"}>lumicash</option>
                                                                    <option value={"airtel money"}>airtel money</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="form-group col-12 d-none">
                                                        <label for="card_exp_month"  className="control-label text-muted">Envoyer sur</label>
                                                        <input id="card_exp_month" name="card_exp_month" type="number" readOnly={true} className="form-control form-control-sm" value={ this.state.card_exp_month }  />
                                                    </div>
                                                    
                                                    
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label for="card_number" className="control-label text-muted">Votre numéro</label>
                                                                <input id="card_number" name="card_number" type="number" className="form-control form-control-sm" onChange={this.handleInput} value={ this.state.card_number }  />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label for="card_exp_year" className="control-label text-muted"> Confirmation</label>
                                                                <input id="card_exp_year" name="card_exp_year" type="number" className="form-control form-control-sm" onChange={this.handleInput} value={ this.state.card_exp_year }  />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                        
                                                </div>
                                            :
                                                this.state.typepayement == "card" ?
                                                    <div className="mx-auto">
                                                        <small className="font-9 fw-100"><a href={`mailto:${process.env.REACT_APP_EMAIL_ADMIN}`} className="text-orangered">Contactez nous avant d'effectuer le paiement</a></small>
                                                        <div className={ this.state.quotation.status === "request" ? "ribbon1 rib1-warning" : this.state.quotation.status === "closed" ? "ribbon1 rib1-danger" : "ribbon1 rib1-primary"}>
                                                            <span className={ this.state.quotation.status === "request" ? "text-white text-center rib1-warning" : this.state.quotation.status === "closed" ?  "text-white text-center rib1-danger"  : "text-white text-center rib1-primary" }>{ this.state.quotation > 0  ? this.state.quotation : this.state.quotation.detailquotation.article.type } </span>
                                                        </div>
                                                        <div className="row">
                                                            
                                                            <div className="col-6">
                                                                <div className="form-group has-success">
                                                                    <label for="card_name" className="control-label text-muted">Nom du titulaire</label>
                                                                    <input id="card_name" name="card_name" type="text"  value={ this.state.card_name } onChange={this.handleInput} className="form-control form-control-sm "  />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group has-success">
                                                                    <label for="card_cvc" className="control-label text-muted">CVC</label>
                                                                    <input id="card_cvc" name="card_cvc" type="number"  value={ this.state.card_cvc } onChange={this.handleInput} className="form-control form-control-sm "  />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label for="card_number" className="control-label text-muted">Numéro de carte</label>
                                                            <input id="card_number" name="card_number" type="number" className="form-control form-control-sm" onChange={this.handleInput} value={ this.state.card_number }  />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label for="card_exp_month" className="control-label text-muted">Mois</label>
                                                                    <input id="card_exp_month" name="card_exp_month" type="number" className="form-control form-control-sm" onChange={this.handleInput} value={ this.state.card_exp_month }  placeholder="MM" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label for="card_exp_year" className="control-label text-muted">Année</label>
                                                                    <input id="card_exp_year" name="card_exp_year" type="number" className="form-control form-control-sm" onChange={this.handleInput} value={ this.state.card_exp_year }  placeholder="YY" />
                                                                </div>
                                                            </div>
                                                            
                                                        </div> 
                                                    </div>
                                                :
                                                <div>
                                                    <div className="text-primary p-3 text-center border border-1 border-primary rounded-3">Paiement cash</div>
                                                </div>
                                        }

                                                    <div className="text-danger">{ this.state.error } </div>
                                                    <div className="align-center text-center mt-2">
                                                    {
                                                        this.state.quotation.detailquotation.article.isValid === 1  ?
                                                        <button id="payment-button" type="button" disabled={this.state.btn}  className="btn btn-sm btn-outline-primary btn-block"  onClick={() => this.storeQuotation()}>
                                                            <span id="payment-button-amount">Payer { this.state.quotation.detailquotation.article.price * this.state.quantity * this.state.periode   }{  this.state.quotation.status === "request" ?  this.state.quotation.detailquotation.devise : this.state.quotation.detailquotation.article.devise }</span>
                                                            { !this.state.btn ? <i className="ti ti-lock fa-lg"></i> : <ReactLoading type="spokes" color="blue" className="float-end" height={15} width={15} />}
                                                        </button>
                                                        :
                                                        <button  type="button" className="btn btn-sm btn-outline-danger btn-block">
                                                            <i className="ti ti-lock fa-lg"></i>&nbsp;
                                                            <span id="payment-button-amount">N'est plus disponible pour le moment</span>
                                                        </button>
                                                    }
                                                    </div>

                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }
            </div>
        )
    }
}
export default Payment;
