import axios from "axios";
import React, {Component} from "react";
import { withTranslation } from 'react-i18next';
import ItemInfoStart from "../components/ItemInfoStart";
import { Link } from "react-router-dom";
import DevisForm from "../components/DevisForm";

class   InfoStart extends Component{

    state = {
        loarding : true,
        proprietaires : [],
        proprietairesplus : [],
        locataires : [],
        locatairesplus : [],
        isproprietaire : true,
        isModal : false
    }

    componentDidMount(){
        this.getProprietaires();
        this.getProprietairesplus();
        this.getlocataires();
        this.getlocatairesplus();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getProprietaires(){
        var proprietaires = await axios.get('pubs/category/proprietaire');
        if(proprietaires.status === 200)
            this.setState({proprietaires : proprietaires.data, loarding : false })
    }
    async getProprietairesplus(){
        var proprietaires = await axios.get('pubs/category/proprietaire_plus');
        if(proprietaires.status === 200)
            this.setState({proprietairesplus : proprietaires.data })
    }
    async getlocataires(){
        var locataires = await axios.get('pubs/category/locataire');
        if(locataires.status === 200)
            this.setState({locataires : locataires.data })
    }
    async getlocatairesplus(){
        var locataires = await axios.get('pubs/category/locataire_plus');
        if(locataires.status === 200)
            this.setState({locatairesplus : locataires.data })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal
        })
    }
    
    render(){
        const { t } = this.props

        if(this.state.loarding)
            return(<div className="text-center text-orangered m-4" >...</div>)
        var   proprietaires =
        this.state.proprietaires.map((pub, count) => {
            return <div className="col-md-4"><ItemInfoStart pub={pub} count={count} col_5={""} col_7={""} /></div>
        })

        var   proprietairesplus =
        this.state.proprietairesplus.map((pub, count) => {
            return <div className="col-md-12"><ItemInfoStart pub={pub} count={count} col_5={"col-md-5"} col_7={"col-md-7"} /></div>
        })


        var locataires =
        this.state.locataires.map((pub, count) => {
            return <div className="col-md-4"><ItemInfoStart pub={pub} count={count} col_5={""} col_7={""} /></div>
        })

        var locatairesplus =
        this.state.locatairesplus.map((pub, count) => {
            return <div className="col-md-12"><ItemInfoStart pub={pub} count={count} col_5={"col-md-5"} col_7={"col-md-7"} /></div>
        })

        let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        return(
          <div>
              <div className="">
                <div className="col-12 mt-4 mb-4">
                    <h1 className="page-title h5 font-monospace fw-bold m-2 text-center mt-4 text-orangered">{ t("dashboard.reservez_votre_futur_logement")}</h1>
                    <p className=" mb-0 text-center mb-4 pb-4">
                        { t("dashboard.recevez_votre_futur_logement_description")}
                    </p>
                    <div className="btn-toolbar mb-2 mb-md-0 d-flex justify-content-center">
                        <button type="button" className={ this.state.isproprietaire ? "btn  btn-sm bg-orangered text-white  me-3 mt-1" : "btn btn-sm btn-outline-primary  me-3 mt-1"} onClick={() => this.setState({ isproprietaire : !this.state.isproprietaire})}><i className="ti ti-key font-18"></i><i className="ti ti-lock font-18"></i> { t("formulaire.proprietaire")}</button>
                    </div>
                </div>
                    {/* { */}
                        {/* this.state.isproprietaire ? */}
                        <div className="row">
                            { proprietaires}
                            <div className="mt-5">
                                {
                                    !this.props.dashboard && ( proprietairesplus )
                                }
                            </div>
                            {
                                !this.props.dashboard && (
                                    <div className="mt-5">
                                        <div className={ "text-center"}>
                                            <Link className="btn btn-primary text-decoration-none btn-sm m-2" to={  user.id ? "/start" : "/login"}><i className="ti ti-home"></i> { t("dashboard.commencer_maintenant")}</Link>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {/* : */}
                        <div className="row mt-5">
                        <div className="btn-toolbar mb-2 mb-md-0 d-flex justify-content-center">
                            <button type="button" className={ !this.state.isproprietaire ? "btn  btn-sm bg-orangered text-white  me-3 mt-1" : "btn btn-sm btn-outline-primary  me-3 mt-1"} onClick={() => this.setState({ isproprietaire : !this.state.isproprietaire})}><i className="ti ti-users font-18"></i>  { t("formulaire.locataire")}</button>
                        </div>
                        
                            { locataires }
                            <div className="mt-5">
                                {
                                    !this.props.dashboard && ( locatairesplus )
                                }
                            </div>
                            {
                                !this.props.dashboard && (
                                    <div className="mt-5">
                                        <div className={ "text-center"}>
                                            <Link className="btn btn-primary text-decoration-none btn-sm m-2" to={"#"}  onClick={() => this.showModal()}><i className="ti ti-home"></i> { t("dashboard.commencer_maintenant")}</Link>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    {/* } */}
                    {
                        this.props.dashboard &&(
                            <div className={ "text-center"}>
                                <Link className="btn btn-primary text-decoration-none btn-sm m-2" to={"/infostart"}><i className="ti ti-search"></i> { t("footer.a_la_recherche_locateur")}</Link>
                            </div>
                        )
                    }


              </div>

              <DevisForm
                    isModal={this.state.isModal}
                    showModal={this.showModal}
                />

      </div>
        )
    }
}
export default withTranslation()(InfoStart);
