import axios from "axios";
import { useTranslation } from "react-i18next";
function LogoutGoogle(){

    const { t } = useTranslation();
    
    const onSuccess = () => {
        axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
        window.localStorage.clear();
        window.location.replace(process.env.REACT_APP_CHDUTYTRUCK + `/`)
    }

    return(
        <div id="signInButton">
            <span onClick={() => { onSuccess()}} type="button" className="ms-4"><i className="ti ti-power"></i> { t("navbar.deconnexion")}</span>
        </div>
    )
}
export default LogoutGoogle