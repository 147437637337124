import React, {Component} from "react";

class Notfound extends Component{

    render(){
        return(
            <div id="body" className="auth-page" style={{backgroundImage: "url('img/background.png')", backgroundSize: "cover", backgroundPosition: "center center"}}>
    
                <div className="container-md">
                    <div className="row vh-100 d-flex justify-content-center">
                        <div className="col-12 align-self-center">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="card shadow mb-4 rounded rounded-3">
                                            <div className="card-body p-0 rounded rounded-3">
                                                <div className="text-center p-3">
                                                    <div className="mt-3 d-flex justify-content-center">
                                                                    
                                                        <img src="/logo192.png" height="50" alt="logo" className="auth-logo" />
                                                        <h4 className="ms-0 ps-0 fw-semibold text-orangered font-22">CH DUTY TRUCK</h4>  
                                                        {/* <p className="text-muted  mb-0">Connectez vous pour continuer.</p>   */}
                                                    </div>
                                                    <h4 className="mt-3 mb-1 fw-semibold text-black font-18">Oops! page non trouvée</h4>   
                                                    <p className="text-muted  mb-0 text-black">Revenir à la la page d'accueil.</p>  
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="ex-page-content text-center">
                                                    <img src="/img/error.svg" alt="0" className="" height="120" />
                                                    <h1 className="mt-5 mb-4 text-orangered">404</h1>  
                                                    <h5 className="font-16 text-muted mb-5 text-dark">Attention!</h5>                                    
                                                </div>          
                                                <a className="btn btn-primary w-100" href="/">Revenir à l'accueil <i className="ti ti-redo ml-1"></i></a>                         
                                            </div>
                                            <div className="d-none card-body bg-light-alt text-center">
                                                &copy; {new Date().getFullYear()}<b>CH DUTY TRUCK</b>                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Notfound;