import axios from "axios";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactLoading from "react-loading";
import CountriesList from "./CountriesList";
import { withTranslation } from "react-i18next";

class DevisForm extends Component {
  state = {
    id: "",
    customerId: null,
    parentId: null,
    categoryId: null,
    category: "",
    quantity: 1,
    periode: 1,
    unite: "",
    countryCode: "",
    countryData: "",
    region: "",
    city: "",
    description: "",
    detailquotation_id: "",
    price: "",
    devise: "",
    bathroom: "",
    leaving_room: "",
    room: 1,
    adress: "",
    markId: "",
    color1: "",
    color2: "",
    vitesse: "",
    carburant: "",

    country: {},
    countries: [],
    regions: [],
    cities: [],
    title: "",
    categories: [],
    marks: [],
    btn: false,
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name == "description")
      localStorage.setItem("description", e.target.value);
  };

  async datacountry() {
    try {
      var country = localStorage.getItem("country")
        ? JSON.parse(localStorage.getItem("country"))
        : {};
      this.setState({
        country: country.country,
        countryData: country.country,
        countryCode: country.countryCode,
        region: country.regionName,
        city: country.city,
        devise: country.currency,
      });

      this.datacountries();
      this.dataregions(
        country.countryCode ? country.countryCode?.toLowerCase() : null
      );
      this.datacities(country.regionName);
    } catch (error) {}
  }

  async datacountries() {
    var countries = CountriesList;
    if (countries.length > 0) {
      this.setState({
        countries: countries,
      });
    }
  }

  async dataregions(cod = null) {
    var code = cod !== null ? cod : this.state.countryCode;
    var regions = await axios.get("countries/" + code);

    if (regions.status === 200) {
      this.setState({
        regions: regions.data,
      });
      this.datacities(this.state.region);
    }
  }

  async datacities(regio = null) {
    if (this.state.countryCode) {
      var region = regio !== null ? regio : this.state.region;

      var cities = await axios.get(
        "countries/" + this.state.countryCode + "/" + region
      );
      if (cities.status === 200) {
        this.setState({
          cities: cities.data,
        });
      }
    }
  }

  storeQuotation = () => {
    this.setState({
      btn: true,
    });

    var user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {};
    var data = {
      quotation: {
        customerId: user.id,
        status: "request",
      },
      detailquotation: {
        quotationId: null,
        categoryId: this.state.categoryId
          ? this.state.categoryId.id
            ? this.state.categoryId.id
            : this.state.categoryId
          : null,
        quantity: this.state.quantity > 0 ? this.state.quantity : 1,
        periode: this.state.periode > 0 ? this.state.periode : 1,
        bathroom: this.state.bathroom > 0 ? this.state.bathroom : 0,
        leaving_room: this.state.leaving_room > 0 ? this.state.leaving_room : 0,
        room: this.state.room > 0 ? this.state.room : 1,
        adress: this.state.adress,
        price: this.state.price > 0 ? this.state.price : null,
        devise: "$", //this.state.devise,
        unite: this.state.unite,
        country: this.state.countryData,
        countryCode: this.state.countryCode,
        region: this.state.region,
        markId: this.state.markId ? this.state.markId : null,
        color1: this.state.color1,
        color2: this.state.color2,
        vitesse: this.state.vitesse,
        carburant: this.state.carburant,
        city: this.state.city,
        description: this.state.description,
      },
    };

    if (this.props.formData && this.props.formData.detailquotation) {
      data.detailquotation.id = this.props.formData.detailquotation.id;
    }

    var request =
      this.props.formData && this.props.formData.id
        ? axios.put("quotations/" + this.props.formData.id, data)
        : axios.post("quotations", data);
    request
      .then((response) => {
        this.setState({
          btn: false,
        });
        localStorage.setItem("description", "");
        window.location.replace(
          process.env.REACT_APP_CHDUTYTRUCK + "/myquotations/request"
        );
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.msg,
          btn: false,
        });
      });
  };

  redirect = (id, article) => {
    axios.put("artdetquots/" + id, { status: "check" });
    window.location.replace("/items/" + article);
  };

  async categories() {
    var categories = await axios.get("categories");
    if (categories.status == 200) {
      this.setState({
        categories: categories.data,
      });
    }
  }

  async marks() {
    var marks = await axios.get("marks");
    if (marks.status == 200) {
      this.setState({
        marks: marks.data,
      });
    }
  }

  edit = () => {
    var country = localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country"))
      : {};
    if (!country.country) this.datacountryIp();

    this.categories();
    this.marks();
    if (!this.props.formData) this.datacountry();
    this.form();
    this.datacountries();
  };

  async datacountryIp() {
    try {
      var ip = await axios("https://httpbin.org/ip");
      if (ip.status === 200) {
        var country = await axios.get("country/" + ip.data.origin);
        if (country.status === 200) {
          localStorage.setItem("country", JSON.stringify(country.data));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  form = () => {
    var country = localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country"))
      : {};
    var description =
      localStorage.getItem("description") !== "null"
        ? localStorage.getItem("description")
        : "";

    this.setState({
      detailquotation_id: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.id
          : ""
        : "",
      categoryId: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.category
            ? this.props.formData.detailquotation.category.id
            : ""
          : ""
        : "",
      parentId: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.category
            ? this.props.formData.detailquotation.category.parentId
            : ""
          : ""
        : "",
      quantity: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.quantity
          : 1
        : 1,
      periode: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.periode
          : 1
        : 1,
      countryCode: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.countryCode
          : country.countryCode
        : country.countryCode,
      country: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.countryCode
          : country.countryCode
        : country.countryCode,
      region: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.region
          : country.regionName
        : country.regionName,
      city: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.city
          : country.city
        : country.city,
      room: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.room
          : 1
        : 1,
      bathroom: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.bathroom
          : ""
        : "",
      price: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.price
          : ""
        : "",
      devise: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.devise
          : country.currency
        : country.currency,
      leaving_room: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.leaving_room
          : ""
        : "",
      adress: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.adress
          : ""
        : "",
      markId: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.markId
          : ""
        : "",
      color1: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.color1
          : ""
        : "",
      color2: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.color2
          : ""
        : "",
      vitesse: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.vitesse
          : ""
        : "",
      carburant: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.carburant
          : ""
        : "",
      city: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.city
          : country.city
        : country.city,
      unite: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.unite
          : "jour"
        : "jour",
      description: this.props.formData
        ? this.props.formData.detailquotation
          ? this.props.formData.detailquotation.description
          : description
        : description,
      btn: false,
      error: "",
    });
  };

  render() {
    const { t } = this.props;
    var user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {};

    var optionCategories = [],
      optionCountries = [],
      optionRegions = [],
      optionCities = [];

    this.state.categories.map((category) => {
      optionCategories.push({
        value: category.id,
        label: category.name,
        parentId: category.parentId,
      });
    });

    if (this.state.countries.length > 0) {
      this.state.countries
        .filter((data) =>
          process.env.REACT_APP_COUNTRIES.split(",").includes(data.code)
        )
        .map((country) => {
          optionCountries.push({
            value: country.code,
            label: country.name,
          });
        });
    }

    if (this.state.regions.length > 0) {
      this.state.regions.map((region) => {
        optionRegions.push({
          value: region.region,
          label: region.region,
        });
      });
    }

    if (this.state.cities.length > 0) {
      this.state.cities.map((city) => {
        optionCities.push({
          value: city.city,
          label: city.city,
        });
      });
    }

    return (
      <div>
        <Modal
          show={this.props.isModal}
          onHide={this.props.showModal}
          size="lg"
          onEntered={this.edit}
          backdrop={"static"}
        >
          <Modal.Header>
            <Modal.Title>
              {t("formulaire.devis.devis_de_recherche")} (
              <span className="font-monospace">{this.state.countryData})</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row">
            <div className="text-center text-danger text-small small">
              {this.state.error}
            </div>

            <div className="col-md-4 col-6 mt-2">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{t("formulaire.devis.besoin_de")} </label>
                <div className="position-relative mt-1">
                  <Select
                    className="form-control form-control-sm p-0"
                    value={optionCategories.filter(
                      (option) => option.value == this.state.categoryId
                    )}
                    defaultValue={this.state.categoryId}
                    onChange={(select) => {
                      this.setState({
                        categoryId: select.value,
                        category: select.name,
                        parentId: select.parentId,
                      });
                    }}
                    options={optionCategories}
                  />
                </div>
              </div>
            </div>

            {this.state.parentId == 1 && (
              <>
                <div className="col-md-4 col-6 mt-2">
                  <div className="form-group has-icon-left">
                    <label htmlFor="nom-id-icon">{t("articleDetail.chambre")}</label>
                    <div className="position-relative mt-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        onChange={this.handleInput}
                        name="room"
                        value={this.state.room}
                        id="nom-id-icon"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-2">
                  <div className="form-group has-icon-left">
                    <label htmlFor="nom-id-icon">{t("articleDetail.salon")}</label>
                    <div className="position-relative mt-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        onChange={this.handleInput}
                        name="leaving_room"
                        value={this.state.leaving_room}
                        id="nom-id-icon"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-2">
                  <div className="form-group has-icon-left">
                    <label htmlFor="nom-id-icon">{ t("articleDetail.salle_de_bain") }</label>
                    <div className="position-relative mt-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        onChange={this.handleInput}
                        name="bathroom"
                        value={this.state.bathroom}
                        id="nom-id-icon"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-2">
                  <div className="form-group has-icon-left">
                    <label htmlFor="nom-id-icon">{ t("articleDetail.parking") }</label>
                    <div className="position-relative mt-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        onChange={this.handleInput}
                        name="parking"
                        value={this.state.parking}
                        id="nom-id-icon"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* {this.state.parentId == 2 && (
              <> */}
                <div className="col-md-4 col-6 mt-2">
                  <div className="form-group has-icon-left">
                    <label htmlFor="nom-id-icon">{ t("articleDetail.marque") }</label>
                    <div className="position-relative mt-1">
                      <select
                        id="mark"
                        name="markId"
                        value={this.state.markId}
                        onChange={this.handleInput}
                        className="form-select"
                      >
                        <option value="">...</option>
                        {this.state.marks.map((mark) => {
                          return (
                            <option key={mark.id} value={mark.id}>
                              {mark.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-2">
                  <div className="form-group has-icon-left">
                    <label htmlFor="nom-id-icon">{ t("articleDetail.boite_vitesse") }</label>
                    <div className="position-relative mt-1">
                      <select
                        id=" vitesse"
                        onChange={this.handleInput}
                        name=" vitesse"
                        value={this.state.vitesse}
                        className="form-select"
                      >
                        <option value={""} key={""}>...</option>
                        <option value={"main-gauche"} key={"main-gauche"}>{"main-gauche"}</option>
                        <option value={"main-droite"} key={"main-droite"}>{"main-droite"}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-2">
                  <div className="form-group has-icon-left">
                    <label htmlFor="nom-id-icon">{ t("articleDetail.carburant") }</label>
                    <div className="position-relative mt-1">
                      <select
                        id="carburant"
                        onChange={this.handleInput}
                        name="carburant"
                        value={this.state.carburant}
                        className="form-select"
                      >
                        <option value={""} key={""}>
                          {" "}
                          {"..."}{" "}
                        </option>
                        <option value={"essance"} key={"essance"}>
                          {" "}
                          {"essance"}{" "}
                        </option>
                        <option value={"mazout"} key={"mazout"}>
                          {" "}
                          {"mazout"}{" "}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              {/* </>
            )} */}

            <div className="col-md-4 col-6 mt-2">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.couleur_interieure") }</label>
                <div className="position-relative mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={this.handleInput}
                    name="color1"
                    value={this.state.color1}
                    id="nom-id-icon"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6 mt-2">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.couleur_exterieure") } </label>
                <div className="position-relative mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={this.handleInput}
                    name="color2"
                    value={this.state.color2}
                    id="nom-id-icon"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6 mt-2 d-none">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.periode") }</label>
                <div className="position-relative mt-1">
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    onChange={this.handleInput}
                    name="periode"
                    value={this.state.periode}
                    id="nom-id-icon"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6 mt-2 d-none">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.periode_en") } </label>
                <div className="position-relative mt-1">
                  <select
                    id="unite"
                    onChange={this.handleInput}
                    name="unite"
                    value={this.state.unite}
                    className="form-select"
                  >
                    <option value="">....</option>
                    {process.env.REACT_APP_UNITES.split(",").map((unite) => {
                      return (
                        <option value={unite} key={"..." + unite}>
                          {unite}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6 mt-2">
              <div className="form-group has-icon-left">
                {/* <label htmlFor="nom-id-icon">Prix ({this.state.devise})</label> */}
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.prix") } ($)</label>
                <div className="position-relative mt-1">
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    onChange={this.handleInput}
                    name="price"
                    value={this.state.price}
                    id="nom-id-icon"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6 mt-2">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">
                { t("formulaire.devis.dans_quel_pays") } {this.state.countryData}{" "}
                </label>
                <div className="position-relative mt-1">
                  <Select
                    className="form-control form-control-sm p-0"
                    value={optionCountries.filter(
                      (option) =>
                        this.state.country &&
                        option.value?.toLowerCase() ==
                          this.state.country?.toLowerCase()
                    )}
                    defaultValue={this.state.country}
                    placeholder={this.state.countryData}
                    onChange={(select) => {
                      this.setState({
                        country: select.value,
                        countryCode: select.value,
                        countryData: select.label,
                        regions: [],
                        cities: [],
                        region: "",
                        city: "",
                      });

                      if (select.value) {
                        this.dataregions(select.value);
                      }
                    }}
                    options={optionCountries}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6 mt-2">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.region") } </label>
                <div className="position-relative mt-1">
                  <Select
                    className="form-control form-control-sm p-0"
                    value={optionRegions.filter(
                      (option) => option.value == this.state.region
                    )}
                    defaultValue={this.state.region}
                    placeholder={this.state.region}
                    onChange={(select) => {
                      this.setState({
                        region: select.value,
                        cities: [],
                        city: "",
                      });

                      if (select.value) {
                        this.datacities(select.value);
                      }
                    }}
                    options={optionRegions}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6 mt-2">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.ville") }</label>
                <div className="position-relative mt-1">
                  <Select
                    className="form-control form-control-sm p-0"
                    value={optionCities.filter(
                      (option) => option.value == this.state.city
                    )}
                    defaultValue={this.state.city}
                    placeholder={this.state.city}
                    onChange={(select) => {
                      this.setState({
                        city: select.value,
                      });
                    }}
                    options={optionCities}
                  />
                </div>
              </div>
            </div>

            <div
              className={
                this.state.parentId == 1 ? "col-md-8 mt-2" : "col-md-12 mt-2"
              }
            >
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.autre_adresse") }</label>
                <div className="position-relative mt-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={this.handleInput}
                    name="adress"
                    value={this.state.adress}
                    id="nom-id-icon"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-2">
              <div className="form-group has-icon-left">
                <label htmlFor="nom-id-icon">{ t("formulaire.devis.description") }</label>
                <div className="position-relative mt-1">
                  <textarea
                    className="form-control form-control-sm"
                    name={"description"}
                    onChange={this.handleInput}
                    value={this.state.description}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <h6
                className={
                  this.props.formData && this.props.formData.detailquotation
                    ? "h6 font-monospace"
                    : "d-none"
                }
              >
                Recentes suggéstions
              </h6>
              {this.props.formData &&
                this.props.formData.detailquotation &&
                this.props.formData.detailquotation.articledetailquotations.map(
                  (articledetailquotation) => {
                    var nombre = articledetailquotation.article.price;
                    var arrondi = nombre * 100;
                    arrondi = Math.round(arrondi);
                    arrondi = arrondi / 100;
                    return (
                      <div className="col-md-4 col-6 col-6 overflow-hidden">
                        <div className="card rounded rounded-3 shadow mt-1">
                          <div className="card-body pb-0 mb-0">
                            <div
                              className={
                                articledetailquotation.status === "request"
                                  ? "ribbon1 rib1-warning"
                                  : "ribbon1 rib1-primary"
                              }
                            >
                              <span
                                className={
                                  articledetailquotation.status === "request"
                                    ? "text-white text-center rib1-warning"
                                    : "text-white text-center rib1-primary"
                                }
                              >
                                {" "}
                                <small className="font-10">
                                  {articledetailquotation.status}
                                </small>{" "}
                              </span>
                            </div>
                            <Link
                              to={"#"}
                              onClick={() =>
                                this.redirect(
                                  articledetailquotation.id,
                                  articledetailquotation.article.id
                                )
                              }
                            >
                              <img
                                src={
                                  articledetailquotation.article.images[0]
                                    ?.image
                                }
                                className="img-thumbnail"
                                alt={
                                  articledetailquotation.article.images[0]
                                    ?.image
                                }
                                style={{ width: 112 + "px", height: 90 + "px" }}
                              />
                            </Link>
                            <div className=" m-1">
                              <div className="m-0 text-truncate">
                                <p className="header-title text-muted text-truncate mb-0">
                                  <Link
                                    to={
                                      "/items/" +
                                      articledetailquotation.article.id
                                    }
                                    className="font-13 text-truncate"
                                  >
                                    {articledetailquotation.article.category
                                      ? articledetailquotation.article.category
                                          .name
                                      : ""}{" "}
                                    <small>
                                      {articledetailquotation.article.libelle} (
                                      <span className="small">
                                        {
                                          articledetailquotation.article
                                            .quantity
                                        }
                                      </span>
                                      )
                                    </small>{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                            <div className=" ">
                              <p className="text-muted">
                                <span className="header-title me-2  mx-1">
                                  {arrondi > 0 ? arrondi : ""}
                                  {arrondi > 0
                                    ? articledetailquotation.article.devise
                                    : ""}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center text-danger text-small small">
              {this.state.error}
            </div>

            <button
              onClick={() => this.props.showModal() }  type="button"
              className="btn my-1 btn-outline-secondary  me-4"
            >
              <i className="ti ti-corner-down-left"></i> { t("formulaire.fermer")}
            </button>
            {((user.id &&
              this.props.formData &&
              this.props.formData.customerId === user.id) ||
              (user.id && !this.props.formData)) && (
              <button
                onClick={() => this.storeQuotation()}
                disabled={this.state.btn}
                className="btn  my-1 btn-primary"
              >
                {" "}
                {this.state.btn ? (
                  <ReactLoading
                    type="spokes"
                    color="white"
                    className="float-start"
                    height={15}
                    width={15}
                  />
                ) : (
                  <i className="ti ti-check"></i>
                )}{" "}
                { t("formulaire.sauvegarder")}
              </button>
            )}
            {!user.id && (
              <Link to="/login" className="btn  my-1 btn-outline-primary">
                <i className="ti ti-check"></i> { t("formulaire.sauvegarder")}
              </Link>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(DevisForm);
