import axios from "axios";
import React, {Component} from "react";
import PubForm from "../components/PubForm";
import Loading from "../components/Loading";
import ImageGallery from "react-image-gallery";
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";

class   Pub extends Component{

    state = {
        isModal : false,
        formData : null,
        pubs : [],
        loading : false,
    }

    componentDidMount(){
        this.getPubs();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getPubs(){
        this.setState({
            loading : false
        })
        var pubs = (this.handleCheck("afficher pub") == true && !this.props.dashboard) ? await axios.get('pubs') : await axios.get('pubs/category/pub');

        if(pubs.status === 200)
        {
            this.setState({pubs : pubs.data, loading : true})
        }
    }

    showModal = (pub = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : pub,
        })
        this.getPubs()
    }

    delete = (pub) =>{
        axios.delete("pubs/" + pub).then((response) => {
            this.getPubs()
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        const { t } = this.props

        var list = "", data = [ ];
        
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        var count =1, pubs =
        this.state.pubs.map((pub, count) => {
            
            data = [];
            list = 
            pub.images.map( (img, count) => {
                data.push({
                    original : img.image,
                })
                return <li className="list-inline-item me-0"><i className="ti ti-star text-danger font-16"></i></li>
            })
            return(
                <div>
                    <div className="card border-0">
                        <div className="card-body">
                            <div className="row border border-end-0 border-bottom-0">
                            
                                <div className={ (count % 2 == 0 || count == 0) ? "col-lg-5 align-self-center overflow-hidden" : "col-lg-5 align-self-center overflow-hidden order-md-2"}>
                                    <div style={{ width : 307 +"px", height : 250 + "px" }}>
                                        { data.length > 0 ? <ImageGallery items={data} autoPlay={(count % 2 == 0 || count == 0) ? true : false} showNav={ false} /> : null}
                                    </div>    
                                </div>{/*end col*/}
                                <div className="col-lg-7 align-self-center overflow-hidden text-muted">
                                    <div className="">
                                        <h1 className="page-title h5 font-monospace fw-bold m-2 text-center text-dark">{ pub.title }</h1>
                                        <ul className="list-inline m-4">
                                            { list}
                                            <li className="list-inline-item"><i className="ti ti-star-half text-danger font-16"></i></li>
                                        </ul>
                                        <p className="font-monospace font-14">{ pub.description}</p>
                                        {
                                            (this.handleCheck("modifier pub") == true && !this.props.dashboard) && (
                                                <div className="mt-1 text-orangered">
                                                    {pub.category}
                                                </div>
                                            )
                                        }
                                        {
                                            (this.handleCheck("supprimer pub") == true && !this.props.dashboard) && (
                                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                                    <button type="button" className="btn btn-sm btn-outline-danger mx-2" onClick={() => this.delete(pub.id)}><i className="ti ti-trash font-18"></i> </button>
                                                </div>
                                            )
                                        }
                                        {
                                            (this.handleCheck("modifier pub") == true && !this.props.dashboard) && (
                                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                                    <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(pub)}><i className="ti ti-edit font-18"></i> </button>
                                                </div>
                                            )
                                        }
                                        
                                    </div>
                                </div>{/*end col*/}                                            
                            </div>{/*end row*/}
                        </div>{/*end card-body*/}
                    </div>
                    <br />
                </div>
            )
        })
        return(
          <div>
              <div className="row">
                  <div className="col-sm-12">
                      <div className="page-title-box">
                          <div className="float-start">
                          </div>
                              {
                                  (this.handleCheck("ajouter pub") == true && !this.props.dashboard) && (
                                      <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                          <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> { t("formulaire.nouvelle_publication")}</button>
                                      </div>
                                  )
                              }
                      </div>
                      {
                          !this.state.loading && (<Loading /> )
                      }
                </div>
                <div className="col-12 mt-4">
                    <h1 className="page-title h5 font-monospace fw-bold m-2 text-center  text-orangered">{ t("dashboard.trouvez_une_location_des_vehicules")}</h1>
                    <p className=" mb-0 text-center mb-4 pb-4">
                        { t("dashboard.trouvez_une_location_des_vehicules_description")}
                    </p>
                    { pubs}
                </div>

              </div>

              <PubForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                showModal={this.showModal}
            />

      </div>
        )
    }
}
export default withTranslation()(Pub);
