import React, {Component} from "react";
import { Link } from "react-router-dom";
import 'react-slideshow-image/dist/styles.css'

class   ItemData extends Component{
 
    render(){
        
            var arrondi = "", currency = "";
            arrondi = this.props.item?.price*100;          
            arrondi = Math.round(arrondi); 
            arrondi = arrondi/100;
            
        return(
            <div className={ this.props.classecontent + " overflow-hidden  m-0  p-1"} key={this.props.item.id}>
                <div className="card shadow bg-white mb-2">
                    <div className="card-body p-0">
                        <div className={ this.props.color + " ribbon1"} style={{ zIndex:5 }}>
                            <span className={ this.props.color + " text-white text-center"}>{ this.props.item.kilometre ? <span style={{ fontSize : 7 }}>{ this.props.item.kilometre}</span> : <i className="ti ti-activity-heartbeat"></i> }</span>                                        
                        </div>   

                        { this.props.image }
                        <div className="p-2 pb-0">
                            <div className="h-4 text-dark m-0 p-0 header-title text-muted text-truncate "><Link to={'/items/'+this.props.item.id } className="text-truncate font-14 fw-bolder text-orangered">{ arrondi }$</Link></div>  
                            <p className="header-title text-muted text-truncate m-0 P-0"><h4 className="h6"><Link to={'/items/' + this.props.item.id } className="font-13 text-truncate fw-semibold text-orangered-hover">{ this.props.item.category ? this.props.item.category.name : ""} </Link></h4></p>
                                    
                            <div className="d-flex justify-content-between align-items-center my-2 overflow-hidden border border-1 p-1 rounded-9 mb-4 py-2 mt-0 pt-0" style={{  borderRadius : 16 + "px", backgroundColor : "rgba(200, 200, 200, .09)" }}>
                                <div className="m-0 text-truncate">
                                    <p className="text-muted text-truncate mb-0"><Link to={'/items/'+this.props.item.id } className="font-13 text-truncat text-orangered-hover"><i className="ti ti-server text-orangered"></i>{ this.props.item.boite_vitesse }, <i className="ti ti-battery text-orangered"></i>{ this.props.item.taille_moteur }</Link></p>
                                    <p className="text-muted text-truncate mb-0"><Link to={'/items/'+this.props.item.id } className="font-13 text-truncate font-monospace text-orangered-hover">{ this.props.item.mark ? this.props.item?.modele ?  this.props.item.mark.name + " " + this.props.item.modele?.name : this.props.modele?.name : <br /> }</Link></p>
                                    <p className="text-muted text-truncate mb-0"><Link to={'/items/'+this.props.item.id } className="font-13 text-truncate font-monospace text-orangered-hover"><i className="ti ti-flag text-orangered"></i>{ this.props.item.city}</Link></p>
                                </div>
                                <div className=" m-0 text-truncate pe-4">
                                    <ul className="list-inline mb-0 product-review align-self-center">
                                        <Link to={'/items/'+this.props.item.id }>
                                            <li className="list-inline-item"><i className="ti ti-star text-warning font-16"></i></li><li className="list-inline-item"><i className="ti ti-star text-warning font-16 ms-n2"></i></li><li className="list-inline-item"><i className="ti ti-star text-warning font-16 ms-n2"></i></li>
                                        </Link>
                                    </ul> 
                                </div>      
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        )
    }
}
export default ItemData;
