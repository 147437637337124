import axios from "axios";
import React, {Component} from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class Footer extends Component{

    state = {
        cat : process.env.REACT_APP_CATEGORY_ID,
        search : "",
        categories : [],
        cities : [],
    }

    async categories(){
        var categories = await axios.get("categoriesdashboards");
        if(categories.status === 200){
            this.setState({
                categories : categories.data,
            })
        }
    }

    componentDidMount(){

        const script = document.createElement("script");
        script.src = "/assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);
        
        this.categories();
        this.datacities();
        
    }
    
    async datacities(){
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
        var countryCode = country.countryCode ? country.countryCode?.toLowerCase() : null;
        var cities = await axios.get("countries/" + countryCode +"/"+ country.regionName);
        if(cities.status === 200){
            this.setState({
                cities : cities.data
            })
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
    const { t } = this.props
    return(
            <div>
                 
                 
                {/*Start Footer*/}
                {/* Footer Start */}
                <footer className="footer text-center text-sm-start pb-5" style={{  backgroundColor : "#ff6900" }}>
                    <div className="row mb-5">
                        
                        <div className="col-md-3 overflow-hidden">
                            <div className="text-center">
                                <div className="font-16 fw-bolder font-monospace text-center mt-4 text-dark">
                                    <a className="navbar-brand btn btn-sm fw-bolder font-20 text-white" href="/">
                                        {/* <img src="/logo192.png" alt="logo CH DUTY TRUCK" className="bg-white" style={{ border : 1 +"px #ff6900" }} height={40+"px"}  /> */}
                                        CH DUTY TRUCK & EQUIPEMENT
                                     </a>
                                </div>
                               <div className="text-white">{ new Date().getFullYear() } &copy;Ch duty truck</div>
                               <div className="d-flex justify-content-center mb-1">
                                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100091111008704&mibextid=LQQJ4d"  className="d-flex justify-content-center align-items-center thumb-sm text-orangered-white border  rounded-circle me-2">
                                        <i className="ti ti-brand-facebook font-24 fw-normal"></i>
                                    </a>
                                    <a href="https://youtube.com/@CHDUTYTRUCKS?si=KN0zqzZuJBbr4whg" className="d-flex justify-content-center align-items-center thumb-sm border text-orangered-white rounded-circle me-2">
                                        <i className="ti ti-brand-youtube font-24 fw-normal"></i>
                                    </a>
                                    <a href="https://www.instagram.com/ch.duty.truck.and.equipment?igsh=NnhhZ3k4dnZoMG41&utm_source=qr" className="d-flex justify-content-center align-items-center thumb-sm text-orangered-white border rounded-circle">
                                        <i className="ti ti-brand-instagram font-24 fw-normal"></i>
                                    </a>
                                </div>
                                <div className="mt-2 text-white text-center font-monospace">{ t("footer.trouvez_facillement_un_logement_et_un_vehicule_a_louer")}</div>
                                <span className="px-2 rounded rounded-5 text-white font-14 uppy-Dashboard-inner bg-orangered"><a href={ "/infostart"  } className="text-white">{ t("footer.a_la_recherche_locateur")}</a> </span>
                                <div className="mt-5"><a href="tel:008615587926040" className="mt-2 text-white text-center font-monospace fw-800">Tel : 0086 155 8792 6040</a></div>
                                <div><a href="mailto:chdutytrucksupplies@gmail.com" className="mt-2 text-white text-center font-monospace fw-6">chdutytrucksupplies@gmail.com</a></div>
                            </div>
                        </div>

                        <div className="col-md-3 overflow-hidden">
                            <div className="font-16 fw-bolder font-monospace text-sm-start mt-4 text-white">{ t("dashboard.speciale_marque")}</div>
                            <div className="">
                                {
                                    this.state.categories.map((category, index) => {
                                        return(
                                            <div className={ index < 8 ? "my-2 font-12" : "d-none"} key={index}><a href={"/items/category/" + category.id } className=""><img src={category.image} className="rounded img-fluid hide-phone" width={"40"} alt={"CH DUTY TRUCK | " + category.name} /> <span className="p-1  text-dark text-orangered-white font-12 text-decoration-none">{ category.name}</span></a></div>
                                        )
                                    })
                                }
                                <a className="my-2 font-12 text-orangered-white" href={"/appartements/appartments" } ><i className="ti ti-activity"></i>{ t("footer.plus_appartement")}</a>
                            </div>
                        </div>

                        <div className="col-md-3 overflow-hidden">
                            <div className="font-16 fw-bolder font-monospace text-sm-start mt-4 text-white">{ t("dashboard.notre_suggestion")}</div>
                            <div className="">
                                {
                                    this.state.cities.length > 0 && (
                                        (this.state.cities ?? []).map((city, index) => {
                                            return(
                                                <div className="my-1" key={city.city}><a href={"/cities/" + city.city} type="button"> <span className="p-1 font-12  text-dark text-orangered-white">{ city.city}</span></a></div>
                                            )
                                        })
                                    )
 
                                }
                                <a className="my-1 text-orangered-white" href={"/items/recommandations" }><i className="ti ti-activity"></i>{ t("footer.plus_de_recommandation")}</a>
                            </div>
                        </div>

                        <div className="col-md-3 overflow-hidden mt-4">
                            <div className="input-group mt-4 border border-2 btn-outline-primary w-100 m-0  rounded-5">
                                <button className="btn btn-outline-secondary bg-white p-0 border border " type="button">
                                    <select className="form-control border-0 text-mutued" name="cat" onChange={this.handleInput}>
                                        <option>{ t("formulaire.trouver")}...</option>
                                        {
                                            this.state.categories.map((category) => {
                                                return( 
                                                    <option value={category.id} key={category.id}>{ category.name }</option>
                                                )
                                            })
                                        }
                                    </select>    
                                </button>
                                <input type="text" name="search" className="form-control form-control-dark border " onChange={this.handleInput} placeholder="Type text..." />
                                <a className="btn bg-white" href={"/searchs/" + this.state.cat + "/" + this.state.search }><i className="ti ti-search text-orangered-hover"></i></a>
                            </div>
                            <div className=" my-4 pt-4 mb-lg-0 text-center">
                                <p className="fs-4 text-white">{ t("footer.accessible")}</p>
                                <ul className="list-unstyled mb-0">
                                    <li className="mb-1"><Link to="/" className="link-900 text-secondary text-decoration-none font-12  text-orangered-white"><i className="ti ti-home"></i> { t("footer.accueil")}</Link></li>
                                    {/* <li className="mb-1"><Link to="/myquotations/loading" className="link-900 text-secondary text-decoration-none font-12  text-orangered-white"><i className="ti ti-shopping-cart"></i> { t("footer.devis")}</Link></li> */}
                                    <li className="mb-1"><Link to="/about" className="link-900 text-secondary text-decoration-none font-12  text-orangered-white"><i className="ti ti-file"></i> { t("footer.apropos_de_nous")}</Link></li>
                                    {/* <li className="mb-1"><Link to="/#contact" className="link-900 text-secondary text-decoration-none font-12  text-orangered-white"><i className="ti ti-mail"></i>  { t("footer.contactez_nous")}</Link></li> */}
                                </ul>
                            </div>
                        </div>

                    </div>
                    <a target="_blank" href="https://evivedigital.net"><span className="text-muted d-sm-inline-block float-end text-orangered-white">Made <i className="ti ti-heart text-orangered-white"></i> by Evive Digital</span></a>
                </footer>

            </div>
        )
    }
}
export default withTranslation()(Footer);