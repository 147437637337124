import { useTranslation } from 'react-i18next';
const Lang = () =>
{
    const [t, i18n] = useTranslation('common');

    if(i18n.language?.toLowerCase() == "en-us")
        i18n?.changeLanguage("fr")

    return (
    <li className="dropdown">
        <a className="nav-link dropdown-toggle arrow-none nav-icon  m-0" data-bs-toggle="dropdown" href="#" role="button"
        aria-haspopup="false" aria-expanded="false">
        { i18n.language && i18n.language == "en" || i18n.language && i18n.language == "fr" ? <img src={"/img/" + i18n.language + ".jpg"} alt="" className="thumb-xxs rounded-circle" /> : <img src={"/img/fr.jpg"} alt="" className="thumb-xxs rounded-circle" /> }
        </a>
        <div className="dropdown-menu w-75">
            <a className="dropdown-item" href="#" onClick={ () => i18n.changeLanguage('fr')}><img src="/img/fr.jpg" alt="" height="15" className="me-2" />Fr</a>
            <a className="dropdown-item" href="#" onClick={ () => i18n.changeLanguage('en')}><img src="/img/en.jpg" alt="" height="15" className="me-2" />En</a>
        </div>
    </li>
    )
}

export default Lang