import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Resizer from "react-image-file-resizer";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

class   PubForm extends Component{

    state = {
        id : "",
        ref_user : null,
        ref_user_update : null,
        title : "",
        description : "",
        category : "",
        error : "",

        btn : false,
        image : [],
        images : [],
        dataImage : [],
    }

    handleInputFile = (e) => {

        var imgTab = [];

        this.setState({
            btn : true,
            image : [],
            dataImage : e.target.files[0],
            btn : false
        })
        for(var i = 0 ; i < e.target.files.length; i++)
        {
            imgTab.push(e.target.files[i]);

             
            this.setState({
                loading : true,
                dataImage : imgTab
            })
            var img = "";
            try {
            img =  Resizer.imageFileResizer(
                  e.target.files[i],
                  1500,
                  1500,
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                        this.state.image.push(uri);
                    this.setState({ image: this.state.image });
                  },
                  "base64",
                  1000,
                  1000
                );
            } catch (err) {
                console.log(err);
            }
        }
        this.setState({
            loading : false,
            loadingForm : false
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    async images(id = null){
        if(id){
            var images = await axios.get("images/" + id);
            if(images.status === 200){
                this.setState({
                    images : images.data
                })
            }
        }
    }

    deleteImage = (id) =>{
        axios.delete("images/" + id).then((response) => {
            this.images(this.state.id);
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }).catch((error)=>{})
    }

    storePub = async (e) => {
        e.preventDefault();

        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        this.setState({
            btn : true
        })
        let formData = new FormData()

        for(var i = 0 ; i < this.state.dataImage.length; i++)
        {
            formData.append("files", this.state.dataImage[i]);
        }
        formData.append("userId", user.id)
        formData.append("title", this.state.title)
        formData.append("description", this.state.description)
        formData.append("category", this.state.category)

        var request = this.state.id ? ('pubs/'+this.state.id) : ('pubs');

        const response = await fetch(process.env.REACT_APP_APICHDUTYTRUCK + '/' + request, {
            method: this.state.id ? "PUT" : "POST",
            headers : { "x-access-token" : user.refresh_token ? user.refresh_token : "" },
            body: formData 
        })
        
        if (response.status === 200)
        {
            this.setState({
                error : "saved success",
                btn : false,
                dataImage : [],
            })
            let val = await response.json()
            toast(val?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal();
        }
        else{
            var error = await response.json();
            this.setState({
                error : error.msg ? error.msg :  "error when loading",
                btn : false
            })
            toast(error.msg ? error.msg :  "error when loading", { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }
        
    }
    
    edit=()=>  {
        this.form()
        this.images(this.props.formData ? this.props.formData.id : "")
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            title: this.props.formData ? this.props.formData.title : "",
            description: this.props.formData ? this.props.formData.description : "",
            category: this.props.formData ? this.props.formData.category : "pub",
            btn : false,
            image : [],
            images : [],
            error : "",
            error : "",
        })
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="small"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title>{ "Publication"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.storePub} className="row">
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        
                        <div className="mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Catégorie</label>
                                <div className="position-relative mt-1">
                                    <select name={"category"} className="form-control form-control-sm" onChange={this.handleInput}>
                                        <option value={"pub"}>Pub</option>
                                        <option value={"proprietaire"}>proprietaire</option>
                                        <option value={"locataire"}>locataire</option>
                                        <option value={"proprietaire_plus"}>proprietaire_plus</option>
                                        <option value={"locataire_plus"}>locataire_plus</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Titre</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="title"  value={this.state.title} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>

                        <div className="mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Description</label>
                                <div className="position-relative mt-1">
                                    <textarea name="description" value={this.state.description} onChange={this.handleInput} className="form-control form-control-sm"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Image par default</label>
                                <div className="position-relative mt-1">
                                <input type="file" accept="image/*" className="form-control form-control-sm" multiple onChange={this.handleInputFile} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                        {
                            this.state.images.map((img, index) => {
                                return(<>
                                    <img src={img.image} alt={ img.image } key={index} className="rounded-3 img-fluid me-1" width={"70"} />
                                    <span type="button" onClick={ () => this.deleteImage(img.id)} className="ti ti-trash-x font-16 text-danger border border-1 border-danger p-1 rounded rounded-circle"></span>
                                </>
                                )
                            })
                        }
                            {
                                this.state.image.map((img, index) => {
                                    return(
                                        <img src={img} alt={ img } key={index} className="rounded-3 img-fluid me-1" width={"70"} />
                                    )
                                })
                            }
                        </div>

                        <div className="col mt-2 mt-2">
                            <div className=" pt-2 text-end">
                                <button onClick={() => this.props.showModal() } type="button" className="btn btn-outline-secondary btn-sm me-3"><i className="ti ti-corner-down-left"></i> Fermer</button>
                                <button   disabled={ this.state.btn } type="submit" className="btn btn-sm btn-outline-primary">Sauvegarder{ !this.state.btn ? <i className="ti ti-check"></i> : <ReactLoading type="spokes" color="blue" className="float-end" height={15} width={15} />} </button>
                            </div>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>


        </div>
        )
    }
}
export default PubForm;
